import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Code } from 'spa/components/Code';
import PrimaryLink from 'spa/components/PrimaryLink';
import {
  Section,
  SectionTitle,
  SectionDesc,
  SectionSubTitle,
  SectionList,
  SectionListItem,
  SectionImage,
} from 'spa/components/Section';
import AccentedText from 'spa/components/AccentedText';
import HintBanner from 'spa/components/HintBanner';
import { urlFor } from '../../../routeConfig';

const EscrowPayDocs = () => (
  <div className="integrationsPortal-content-landing">
    <div className="grid">
      <div className="grid-col grid-col--desktopSmall-9">
        <h1 className="integrationsPortal-title">Escrow Pay Documentation</h1>
        <Section>
          <SectionTitle>What is Escrow Pay?</SectionTitle>
          <SectionDesc>
            With no minimum fee and prices as low as 0.89%, Escrow Pay is the ideal solution for any
            website, mobile app, online store, classified site or marketplace that needs to take
            payments for any product or service of value. It allows buyers shopping online to make
            purchases safely and with confidence, whether they are spending $100 or $10,000,000 or
            more. Here is how it works:
          </SectionDesc>
          <SectionList>
            <SectionListItem>
              An online store or marketplace integrates the Escrow Pay API call on their listing or
              checkout pages. Since the store or marketplace only needs to implement a single API
              call, this can be done very easily.
            </SectionListItem>
            <SectionListItem>
              When the buyer checks out with Escrow Pay on the store or marketplace, a transaction
              is created on Escrow.com and the buyer is redirected to the Escrow Pay wizard on
              Escrow.com.
            </SectionListItem>
            <SectionListItem>
              The buyer secures their order by registering, verifying, and paying via the Escrow Pay
              wizard.
            </SectionListItem>
            <SectionListItem>
              All related actions and functionality for the transaction occur as usual.
            </SectionListItem>
          </SectionList>
          <SectionDesc>
            Here is a screenshot of the Escrow Pay wizard that the buyer uses to make their payment:
          </SectionDesc>
          <SectionImage
            src="../build/images/escrowPay/escrow-pay-payment-step.png"
            alt="escrow pay payment step"
          />
          <SectionDesc>
            <a href="https://www.escrow.com/pay" target="_blank" rel="noopener noreferrer">
              Learn more about Escrow Pay and its benefits here.
            </a>
          </SectionDesc>
          <br />
        </Section>
        <Section>
          <SectionTitle id="transaction-simple">Calling the Escrow Pay API</SectionTitle>
          <SectionDesc>
            The call to the Escrow Pay API specifies how the transaction should be configured. This
            includes details about the item(s) being transacted, the buyer, the seller, the terms of
            the transaction, and optionally a broker for marketplace or commission scenarios.
          </SectionDesc>
          <HintBanner
            className="integrationsPortal-hintBanner"
            title="Note"
            desc="The Escrow Pay API supports all the transaction configurations that are available
            in the create transaction endpoint in the Escrow API with the exception of
            Domain Name Holding transactions. However, there are two important changes: the customer
            object has been greatly expanded and the return_url attribute has been added. The
            customer object now allows the store or marketplace to pass relevant details such as the
            names, address, and date of birth of the buyer to Escrow.com in the Escrow Pay API
            request. When a return_url is specified in the request, the buyer will be redirected to
            that url when they have completed the Escrow Pay wizard."
            icon="ui-alert-circle"
          />
          <Tabs
            className="simpleTabs integrationsPortal-codeSample"
            selectedTabClassName="is-activeTab"
          >
            <TabList className="simpleTabs-nav">
              <Tab className="simpleTabs-nav-item">cURL</Tab>
              <Tab className="simpleTabs-nav-item">Python</Tab>
              <Tab className="simpleTabs-nav-item">Ruby</Tab>
              <Tab className="simpleTabs-nav-item">PHP</Tab>
              <Tab className="simpleTabs-nav-item">C#</Tab>
            </TabList>
            <TabPanel className="simpleTabs-content">
              <Code language="shell">
                {`curl "https://api.escrow.com/integration/pay/2018-03-31" \\
    -X POST \\
    -u "email-address:your-api-key" \\
    -H "Content-Type: application/json" \\
    -d '
    {
      "currency": "usd",
      "description": "Perfect sedan for the snow",
      "reference": "test-transact",
      "return_url": "https://www.escrow.com",
      "redirect_type": "manual",
      "items": [
        {
          "extra_attributes": {
            "make":"BMW",
            "model":"328xi",
            "year":2008
          },
          "fees": [
            {
              "payer_customer": "me",
              "split": "1",
              "type": "escrow"
            }
          ],
          "inspection_period": 259200,
          "quantity": 1,
          "schedule": [
            {
              "amount": 8000,
              "payer_customer": "john.wick@test.escrow.com",
              "beneficiary_customer": "me"
            }
          ],
          "title": "BMW 328xi",
          "type": "motor_vehicle"
        }
      ],
      "parties": [
        {
          "address": {
            "line1": "180 Montgomery St",
            "line2": "Suite 650",
            "city": "San Francisco",
            "state": "CA",
            "country": "US",
            "post_code": "94104"
          },
          "agreed": true,
          "customer": "john.wick@test.escrow.com",
          "date_of_birth": "1980-07-18",
          "first_name": "John",
          "initiator": false,
          "last_name": "Wick",
          "phone_number": "4155555555",
          "lock_email": true,
          "role": "buyer"
        },
        {
          "agreed": true,
          "customer": "me",
          "initiator": true,
          "role": "seller"
        }
      ]
    }'
`}
              </Code>
            </TabPanel>
            <TabPanel>
              <Code language="python">
                {`import requests

requests.post(
    'https://api.escrow.com/integration/pay/2018-03-31',
    auth=('email address', 'api-key'),
    json={
        "currency": "usd",
        "description": "Perfect sedan for the snow",
        "reference": "test-transact",
        "return_url": "https://www.escrow.com",
        "redirect_type": "manual",
        "items": [
            {
                "extra_attributes": {
                    "make": "BMW",
                    "model": "328xi",
                    "year": "2008",
                },
                "fees": [
                    {
                        "payer_customer": "me",
                        "split": "1",
                        "type": "escrow",
                    },
                ],
                "inspection_period": 259200,
                "quantity": 1,
                "schedule": [
                    {
                        "amount": 8000,
                        "payer_customer": "john.wick@test.escrow.com",
                        "beneficiary_customer": "me",
                    },
                ],
                "title": "BMW 328xi",
                "type": "motor_vehicle",
            },
        ],
        "parties": [
            {
                "address": {
                    "line1": "180 Montgomery St",
                    "line2": "Suite 650",
                    "city": "San Francisco",
                    "state": "CA",
                    "country": "US",
                    "post_code": "94104",
                },
                "agreed": True,
                "customer": "john.wick@test.escrow.com",
                "date_of_birth": "1980-07-18",
                "first_name": "John",
                "initiator": False,
                "last_name": "Wick",
                "phone_number": "4155555555",
                "lock_email", True,
                "role": "buyer",
            },
            {
                "agreed": True,
                "customer": "me",
                "initiator": True,
                "role": "seller",
            },
        ],
    },
)
`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="ruby">
                {`require 'net/http'
require 'json'

url = URI("https://api.escrow.com/integration/pay/2018-03-31")

http = Net::HTTP.new(url.host, url.port).tap {|http| http.use_ssl = true}

request = Net::HTTP::Post.new(url.request_uri).tap {|request|
  request["content-type"] = 'application/json'
  request.basic_auth("email-address", "api-key")
  request.body = {
       "currency": "usd",
    "description": "Perfect sedan for the snow",
    "reference": "test-transact",
    "return_url": "https://www.escrow.com",
    "redirect_type": "manual",
    "items":[
        {
            "extra_attributes":
                {
                    "make": "BMW",
                    "model": "328xi",
                    "year": 2008
                }
            ,
            "fees":[
                {
                "payer_customer": "me",
                "split": "1"
                "type": "escrow",
                }
            ]
            "inspection_period": 259200,
            "quantity": 1,
            "schedule":[
                {
                "amount": 8000,
                "payer_customer": "john.wick@test.escrow.com",
                "beneficiary_customer": "me"
                }
            ],
            "title": "BMW 328xi",
            "type": "motor_vehicle",
        }
    ],
    "parties":[
        {
            "address": {
                "line1": "180 Montgomery St",
                "line2": "Suite 650",
                "city": "San Francisco",
                "state": "CA",
                "country": "US",
                "post_code": "94104"
            },
            "agreed": true,
            "customer": "john.wick@test.escrow.com",
            "date_of_birth": "1980-07-18",
            "first_name": "John",
            "initiator": false,
            "last_name": "Wick",
            "phone_number": "4155555555",
            "role": "buyer",
            "lock_email": true
        },
        {
            "agreed": true,
            "customer": "me",
            "initiator": true,
            "role": "seller",
        }
    ]
  }.to_json
}

response = http.request(request)
puts response.body`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="php">
                {`<?php
$curl = curl_init();
curl_setopt_array($curl, array(
    CURLOPT_URL => 'https://api.escrow.com/integration/pay/2018-03-31',
    CURLOPT_RETURNTRANSFER => 1,
    CURLOPT_USERPWD => 'email-address:your-password',
    CURLOPT_HTTPHEADER => array(
        'Content-Type: application/json'
    ),
    CURLOPT_POSTFIELDS => json_encode(
        array(
            'currency' => 'usd',
            'description' => 'Perfect sedan for the snow',
            'reference' => 'test-transact',
            'return_url' => "https://www.escrow.com",
            'redirect_type' => "manual",
            'items' => array(
                array(
                    'extra_attributes' => array(
                        'make' => 'BMW',
                        'model' => '328xi',
                        'year' => '2008',
                    ),
                    'fees' => array(
                        array(
                            'payer_customer' => 'me',
                            'split' => '1',
                            'type' => 'escrow',
                        ),
                    ),
                    'inspection_period' => '259200',
                    'quantity' => '1',
                    'schedule' => array(
                        array(
                            'amount' => '8000',
                            'beneficiary_customer' => 'me',
                            'payer_customer' => 'john.wick@test.escrow.com',
                        ),
                    ),
                    'title' => 'BMW 328xi',
                    'type' => 'motor_vehicle',
                ),
            ),
            'parties' => array(
                array(
                    'address' => array(
                        'city' => 'San Francisco',
                        'country' => 'US',
                        'line1' => '180 Montgomery St',
                        'line2' => 'Suite 650',
                        'post_code' => '94104',
                        'state' => 'CA',
                    ),
                    'agree' => 'true',
                    'customer' => 'john.wick@test.escrow.com',
                    'date_of_birth' => '1980-07-18',
                    'first_name' => 'John',
                    'initiator' => 'false',
                    'last_name' => 'Wick',
                    'phone_number' => '4155555555',
                    'lock_email', 'true',
                    'role' => 'buyer',
                ),
                array(
                    'agree' => 'true',
                    'customer' => 'me',
                    'initiator' => 'true',
                    'role' => 'seller',
                ),
            ),
        )
    )
));

$output = curl_exec($curl);
echo $output;
curl_close($curl);
?>`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="cs">
                {`using System;
using System.Text;
using System.Net.Http;
using System.Net.Http.Headers;
using Newtonsoft.Json;

namespace EscrowApiTest
{
    class Program
    {
        static void Main(string[] args)
        {
            HttpClient client = new HttpClient();
            var auth = Encoding.ASCII.GetBytes("email-address:api-key");
            client.DefaultRequestHeaders.Authorization = new AuthenticationHeaderValue(
                "Basic", Convert.ToBase64String(auth));
            client.DefaultRequestHeaders.Accept.Add(
                new MediaTypeWithQualityHeaderValue("application/json"));
            var body = new
            {
                currency = "usd",
                description = "Perfect sedan for the snow",
                reference = "test-transact",
                return_url = "https://www.escrow.com",
                redirect_type = "manual",
                items = new[]
                {
                    new
                    {
                        extra_attributes = new
                        {
                            make = "BMW",
                            model = "328xi",
                            year = "2008",
                        },
                        fees = new[]
                        {
                            new
                            {
                                payer_customer = "me",
                                split = "1",
                                type = "escrow",
                            }
                        },
                        inspection_period = "259200",
                        quantity = "1",
                        schedule = new[]
                        {
                            new
                            {
                                amount = "8000",
                                beneficiary_customer = "me",
                                payer_customer = "john.wick@test.escrow.com",
                            },
                        },
                        title = "BMW 328xi",
                        type = "motor_vehicle",
                    },
                },
                parties = new[]
                {
                    new
                    {
                        address = new
                        {
                            city = "San Francisco",
                            country = "US",
                            line1 = "180 Montgomery St",
                            line2 = "Suite 650",
                            post_code = "94104",
                            state = "CA",
                        },
                        agree = true,
                        customer = "john.wick@test.escrow.com",
                        date_of_birth = new DateTime(1980, 7, 18),
                        first_name = "John",
                        initiator = false,
                        last_name = "Wick",
                        phone_number = "4155555555",
                        lock_email = true,
                        role = "buyer",
                    },
                    new
                    {
                        agree = true,
                        customer = "me",
                        initiator = true,
                        role = "seller",
                    },
                },
            };
            var request = new HttpRequestMessage()
            {
                RequestUri = new Uri("https://api.escrow.com/integration/pay/2018-03-31"),
                Method = HttpMethod.Post,
                Content = new StringContent(
                    JsonConvert.SerializeObject(body), Encoding.UTF8, "application/json")
            };
            HttpResponseMessage response = client.SendAsync(request).Result;
            Console.WriteLine(response.Content.ReadAsStringAsync().Result);
        }
    }
}`}
              </Code>
            </TabPanel>
          </Tabs>
          <SectionSubTitle>Example Response</SectionSubTitle>
          <Code language="json">
            {`{
    "landing_page": "https://www.escrow.com/pay?token=2d2afb9f-364f-4f9f-82a5-803344d60432",
    "token": "2d2afb9f-364f-4f9f-82a5-803344d60432",
    "transaction_id": 3300003
}`}
          </Code>
          <br />
          <br />
          <SectionSubTitle>Locking the buyers email</SectionSubTitle>
          <SectionDesc>
            Setting the <AccentedText>lock_email</AccentedText> value to{' '}
            <AccentedText>true</AccentedText>
            removes the ability for the buyer to edit their email address on the Escrow Pay sign up
            and login pages.
          </SectionDesc>
          <SectionSubTitle>landing_page attribute</SectionSubTitle>
          <SectionDesc>
            The <AccentedText>landing_page</AccentedText> attribute contains the link to which the
            buyer should be redirected.
          </SectionDesc>
          <SectionSubTitle>token attribute</SectionSubTitle>
          <SectionDesc>
            The <AccentedText>token</AccentedText> attribute contains the token which is used to
            identify the buyer{"'"}s transaction on the the Escrow Pay wizard. While it is embedded
            in the <AccentedText>landing_page</AccentedText>, it is split out here for convenience.
          </SectionDesc>
          <SectionSubTitle>transaction_id attribute</SectionSubTitle>
          <SectionDesc>
            The <AccentedText>transaction_id</AccentedText> attribute contains the Escrow.com
            transaction identifier for the transaction that was created by the call to the Escrow
            Pay API. This is provided so that subsequent calls may be made to the standard Escrow
            API.
          </SectionDesc>
          <SectionDesc>
            For help on making API calls and configuring transactions, please review the links
            below.
          </SectionDesc>
        </Section>
        <Section>
          <SectionTitle>
            Redirecting to your site after completing a transaction with Escrow Pay
          </SectionTitle>
          <SectionDesc>
            {' '}
            Set the <AccentedText>redirect_url</AccentedText> to define the URL to return to after
            completion of Escrow pay.
          </SectionDesc>
          <SectionSubTitle>Modifying redirect behaviour</SectionSubTitle>
          <SectionDesc>
            Set the <AccentedText>redirect_type</AccentedText> transaction property to control
            automatic redirects. A value of <AccentedText>automatic</AccentedText> will redircet
            automatically in 3-5 seconds, wheras a value of <AccentedText>manual</AccentedText> will
            only redirect the buyer if they click the call to action.
          </SectionDesc>
        </Section>
        <Section>
          <SectionTitle id="transaction-simple">
            Retrieving a Pending Escrow Pay Transaction
          </SectionTitle>
          <SectionDesc>
            A Pending Escrow Pay Transaction can be retrieved using the{' '}
            <AccentedText>reference</AccentedText> provided when creating the Escrow Pay
            transaction. This applies to all Escrow Pay Transactions that has a specific buyer.
          </SectionDesc>
          <Tabs
            className="simpleTabs integrationsPortal-codeSample"
            selectedTabClassName="is-activeTab"
          >
            <TabList className="simpleTabs-nav">
              <Tab className="simpleTabs-nav-item">cURL</Tab>
              <Tab className="simpleTabs-nav-item">Python</Tab>
              <Tab className="simpleTabs-nav-item">Ruby</Tab>
              <Tab className="simpleTabs-nav-item">PHP</Tab>
              <Tab className="simpleTabs-nav-item">C#</Tab>
            </TabList>
            <TabPanel className="simpleTabs-content">
              <Code language="shell">
                {`curl "https://api.escrow.com/integration/pay/2018-03-31?reference=test-transact" \\
    -X GET \\
    -u "email-address:your-api-key" \\
    -H "Content-Type: application/json" \\
`}
              </Code>
            </TabPanel>
            <TabPanel>
              <Code language="python">
                {`import requests

requests.get(
    'https://api.escrow.com/integration/pay/2018-03-31?reference=test-transact',
    auth=('email address', 'api-key'),
)
`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="ruby">
                {`require 'net/http'
require 'json'

url = URI("https://api.escrow.com/integration/pay/2018-03-31?reference=test-transact")

http = Net::HTTP.new(url.host, url.port).tap {|http| http.use_ssl = true}

request = Net::HTTP::Get.new(url.request_uri).tap {|request|
  request["content-type"] = 'application/json'
  request.basic_auth("email-address", "api-key")
}

response = http.request(request)
puts response.body`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="php">
                {`<?php
$curl = curl_init();
curl_setopt_array($curl, array(
    CURLOPT_URL => 'https://api.escrow.com/integration/pay/2018-03-31?reference=test-transact',
    CURLOPT_RETURNTRANSFER => 1,
    CURLOPT_USERPWD => 'email-address:your-password',
    CURLOPT_HTTPHEADER => array(
        'Content-Type: application/json'
    ),
));

$output = curl_exec($curl);
echo $output;
curl_close($curl);
?>`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="cs">
                {`using System;
using System.Text;
using System.Net.Http;
using System.Net.Http.Headers;
using Newtonsoft.Json;

namespace EscrowApiTest
{
    class Program
    {
        static void Main(string[] args)
        {
            HttpClient client = new HttpClient();
            var auth = Encoding.ASCII.GetBytes("email-address:api-key");
            client.DefaultRequestHeaders.Authorization = new AuthenticationHeaderValue(
                "Basic", Convert.ToBase64String(auth));
            client.DefaultRequestHeaders.Accept.Add(
                new MediaTypeWithQualityHeaderValue("application/json"));
            var request = new HttpRequestMessage()
            {
                RequestUri = new Uri("https://api.escrow.com/integration/pay/2018-03-31?reference=test-transact"),
                Method = HttpMethod.Get,
            };
            HttpResponseMessage response = client.SendAsync(request).Result;
            Console.WriteLine(response.Content.ReadAsStringAsync().Result);
        }
    }
}`}
              </Code>
            </TabPanel>
          </Tabs>
          <SectionSubTitle>Example Response</SectionSubTitle>
          <Code language="json">
            {`{
    "landing_page": "https://www.escrow.com/pay?token=2d2afb9f-364f-4f9f-82a5-803344d60432",
    "token": "2d2afb9f-364f-4f9f-82a5-803344d60432",
    "transaction_id": 3300003
}`}
          </Code>
          <br />
          <br />
          <SectionSubTitle>landing_page attribute</SectionSubTitle>
          <SectionDesc>
            The <AccentedText>landing_page</AccentedText> attribute contains the link to which the
            buyer should be redirected.
          </SectionDesc>
          <SectionSubTitle>token attribute</SectionSubTitle>
          <SectionDesc>
            The <AccentedText>token</AccentedText> attribute contains the token which is used to
            identify the buyer{"'"}s transaction on the the Escrow Pay wizard. While it is embedded
            in the <AccentedText>landing_page</AccentedText>, it is split out here for convenience.
          </SectionDesc>
          <SectionSubTitle>transaction_id attribute</SectionSubTitle>
          <SectionDesc>
            The <AccentedText>transaction_id</AccentedText> attribute contains the Escrow.com
            transaction identifier for the transaction that was created by the call to the Escrow
            Pay API. This is provided so that subsequent calls may be made to the standard Escrow
            API.
          </SectionDesc>
        </Section>
        <footer className="integrationsPortal-footer">
          <h3 className="integrationsPortal-footer-title">{'Relevant Resources'}</h3>
          <nav className="integrationsPortal-footer-steps">
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="API Basics"
                link={{
                  type: 'app',
                  route: urlFor('api_guide_basics'),
                }}
              />
            </div>
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Creating transactions"
                link={{
                  type: 'app',
                  route: urlFor('api_guide_create_transaction'),
                }}
              />
            </div>
          </nav>
        </footer>
      </div>
    </div>
  </div>
);

export default EscrowPayDocs;
