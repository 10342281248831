import React, { Fragment, useEffect, useState } from 'react';
import qs from 'query-string';
import classnames from 'classnames';
import { connect } from 'react-redux';
import ReactSVG from 'react-svg';
import { apiItemTypeToDisplayName } from 'escrow-common-js/dist/constants';

import A from 'spa/components/A';
import { DefaultSkeleton, TwoRowSkeleton, InlineSkeleton } from 'spa/components/Skeleton';

import Currency from 'spa/components/Currency';

import { getTransactions as getTransactionsRoutine } from 'spa/actions/TransactionActions';
import { nextStep, finishTour } from 'spa/actions/TourActions';
import {
  transactionsTotalCountSelector,
  transactionsViewSelector,
  transactionErrorSelector,
  transactionFiltersSelector,
  transactionLoadingSelector,
} from 'spa/selectors/TransactionSelectors';
import { tourStepSelector } from 'spa/selectors/TourStepSelector';
import TransactionConstants from 'spa/constants/TransactionConstants';
import Icon from 'spa/components/Icon';
import TourStep from 'spa/components/TransactionsTour/TransactionsTourStep';
import TransactionSearch from './TransactionSearch';
import TransactionFilters from './TransactionFilters';
import TransactionTags from './TransactionTags';
import { gettext } from '../../../utils/filters';
import { LoadingError } from './TransactionError';

const TableHeaders = ({ onSort, sortBy, sortDirection, allowSort, close, tourStep }) => {
  const fields = [
    {
      name: 'ID',
      key: 'id',
    },
    {
      name: 'Transaction Title',
      key: 'description',
    },
    {
      name: 'Created',
      key: 'initiation_date',
    },
    {
      name: 'Amount',
      key: 'total',
    },
    {
      name: 'Role',
      key: 'role',
    },
    {
      name: 'Status',
      key: 'status_code',
    },
  ];

  return (
    <thead>
      <tr className="transactions-table-row transactions-table-row--header">
        {fields.map((field) => (
          <th className="transactions-table-cell transactions-table-cell--header" key={field.key}>
            <div
              role="button"
              tabIndex={0}
              className={classnames('transactions-table-cell--header-wrapper', {
                'is-disabled': !allowSort,
              })}
              onClick={() => allowSort && onSort(field.key)}
            >
              <span>{field.name}</span>
              {allowSort && (
                <Icon
                  className={classnames({
                    'transactions-icon': true,
                    'transactions-icon--sort': true,
                    'is-active--asc': field.key === sortBy && sortDirection === 'asc',
                    'is-active--desc': field.key === sortBy && sortDirection === 'desc',
                  })}
                  name="sort"
                  wrapper="span"
                />
              )}
              {field.key === 'id' && (
                <TourStep
                  visible={tourStep === 5}
                  horizontalPosition="start"
                  onNext={close}
                  onClose={close}
                  title="sort"
                  step={5}
                  buttonText="Done"
                >
                  And one more thing! You may click on any of the column headers to{' '}
                  <span>sort</span> your transactions by the information in that column.
                </TourStep>
              )}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
};

const NoResultsError = () => (
  <div className="transactions-error">
    <ReactSVG src={`../../../../build/images/myTransactions/noTransactionsFound.svg`} />
    {gettext(
      'Sorry, no transactions were found. You may try adjusting your search or filters for better results.'
    )}
  </div>
);

const NoTransactionsError = () => (
  <div className="transactions-error">
    <ReactSVG src={`../../../../build/images/myTransactions/noExistingTransactions.svg`} />
    {gettext('There’s nothing here yet. Click below to start a new transaction.')}
    <A
      className="transactions-error-btn btn btn--secondary"
      link={{
        type: 'internal',
        route: window.config.transaction_choice,
      }}
      data-e2e-target="my-transactions-create"
      data-tracking-name="start-transaction"
    >
      {gettext('Start a New Transaction')}
    </A>
  </div>
);

const TransactionTable = ({
  shadow,
  error,
  loading,
  currentFilters,
  transactionsView,
  totalTransactionCount,
  getTransactions,
  searchTransactions,
  sortTransactions,
  currentStep,
  nextTour,
  closeTour,
}) => {
  const [refreshLoad, setRefreshLoad] = useState(true);
  const {
    search,
    role,
    type,
    amount: { min, max },
    status,
    sortBy,
    sortDirection,
  } = currentFilters;
  const hasMoreTransactions = !loading && totalTransactionCount !== transactionsView.length;
  const hasSearchFilters = search || role.length || type.length || min || max;
  const emptySearchResults = hasSearchFilters && !error && !loading && !transactionsView.length;
  const noTransactionYet = !hasSearchFilters && !error && !loading && !transactionsView.length;
  const allowSort = !error && transactionsView.length > 0 && !search;
  const loadingMore = !refreshLoad && loading && transactionsView.length > 0;
  const LoadingTransactions = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }];
  const roleString = JSON.stringify(role); // This needs to be converted to string since useEffect
  const typeString = JSON.stringify(type); // unusually fires when array types are passed inside dependency array

  useEffect(
    () => setRefreshLoad(true),
    [search, roleString, typeString, min, max, status, sortBy, sortDirection]
  );
  useEffect(() => {
    if (!loading) setRefreshLoad(false);
  }, [loading]);

  function handleSort(sort) {
    if (sort === currentFilters.sortBy) {
      sortTransactions(sort, currentFilters.sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      sortTransactions(sort, 'asc');
    }
  }

  const handleRedirect = (transaction) => {
    if (refreshLoad) return;

    let redirectUrl;
    if (transaction.isOffer && transaction.token) {
      redirectUrl = `${window.config.www_base_url}/offer-management?source=my_transaction&token=${transaction.token}`;
    } else {
      redirectUrl = `${window.config.www_base_url}/transaction/${transaction.id}`;
    }
    window.open(redirectUrl).focus();
  };

  return (
    <Fragment>
      <div className="transactions-card">
        <div className="transactions-summary">
          <div className="transactions-summary-options">
            <TransactionSearch
              onSearch={searchTransactions}
              currentFilters={currentFilters}
              loading={loading}
            />
            <TransactionFilters
              onApplyFilter={getTransactions}
              currentFilters={currentFilters}
              modalProps={{ currentStep, nextStep: nextTour, close: closeTour }}
            />
          </div>
          <span className="transactions-summary-total">
            {!refreshLoad && !error && totalTransactionCount > 0 && (
              <Fragment>
                You are viewing{' '}
                {totalTransactionCount > 1 ? (
                  <span>
                    <strong>{transactionsView.length}</strong> of{' '}
                    <strong>{totalTransactionCount}</strong> transactions
                  </span>
                ) : (
                  <span>
                    <strong>1</strong> transaction
                  </span>
                )}
              </Fragment>
            )}
          </span>
        </div>
        <table className="transactions-table">
          <TableHeaders
            onSort={handleSort}
            sortBy={currentFilters.sortBy}
            sortDirection={currentFilters.sortDirection}
            allowSort={allowSort}
            tourStep={currentStep}
            close={closeTour}
          />
          <tbody>
            {!(error || emptySearchResults || noTransactionYet) &&
              (refreshLoad ? LoadingTransactions : transactionsView).map((transaction) => (
                <tr
                  key={transaction.id}
                  data-loading={refreshLoad}
                  className="transactions-table-row"
                  onClick={() => handleRedirect(transaction)}
                  data-tracking-section="transactions-item"
                  data-tracking-action="click"
                  data-tracking-label={transaction.id}
                >
                  <td
                    data-loading={refreshLoad}
                    className="transactions-table-cell transactions-table-cell@mobile--header transactions-table-cell@mobile--left"
                  >
                    {refreshLoad ? <DefaultSkeleton /> : transaction.id}
                  </td>
                  <td
                    className={classnames([
                      'transactions-table-cell',
                      'transactions-table-cell--xlarge',
                      'transactions-table-cell@mobile--main',
                      'transactions-table-cell@mobile--body',
                    ])}
                  >
                    {refreshLoad ? (
                      <TwoRowSkeleton className="skeleton--wide skeleton@mobile--fixed" />
                    ) : (
                      <Fragment>
                        <div>{transaction.description}</div>
                        <div
                          className={classnames([
                            'transactions-table-cell--subtitle',
                            'transactions-table-cell--detail',
                          ])}
                        >
                          {apiItemTypeToDisplayName[transaction.type]}
                        </div>
                      </Fragment>
                    )}
                  </td>
                  <td
                    className={classnames([
                      'transactions-table-cell@mobile--hr',
                      'transactions-table-cell',
                      'transactions-table-cell@mobile--footer',
                    ])}
                  />
                  <td
                    data-loading={refreshLoad}
                    className={classnames([
                      'transactions-table-cell',
                      'transactions-table-cell--small',
                      'transactions-table-cell@mobile--left',
                      'transactions-table-cell--detail',
                      'transactions-table-cell@mobile--footer',
                      'transactions-table-cell@mobile--middle',
                      'transactions-table-cell@mobile--subtitle',
                    ])}
                  >
                    {refreshLoad ? (
                      <DefaultSkeleton className="skeleton@mobile--fixed" />
                    ) : (
                      <Fragment>
                        <Icon
                          name="clock"
                          className="icon transactions-icon media--available@tablet"
                          wrapper="span"
                        />
                        {transaction.initiationDate}
                      </Fragment>
                    )}
                  </td>
                  <td
                    data-loading={refreshLoad}
                    className={classnames([
                      'transactions-table-cell',
                      'transactions-table-cell@mobile--right',
                      'transactions-table-cell@mobile--body',
                    ])}
                  >
                    {refreshLoad ? (
                      <TwoRowSkeleton className="skeleton--half transactions-table-cell@mobile--right" />
                    ) : (
                      <Fragment>
                        <Currency code={transaction.currency} amount={transaction.total} />
                        <div className="transactions-table-cell--subtitle">
                          {transaction.currency}
                        </div>
                      </Fragment>
                    )}
                  </td>
                  <td
                    data-loading={refreshLoad}
                    className={classnames([
                      'transactions-table-cell',
                      'transactions-table-cell--title',
                      'transactions-table-cell@mobile--header',
                      'transactions-table-cell@mobile--right',
                      'transactions-table-cell@mobile--subtitle',
                      'transactions-table-cell--detail',
                    ])}
                  >
                    {refreshLoad ? <DefaultSkeleton width="70%" /> : transaction.role}
                  </td>
                  <td
                    className={classnames([
                      'transactions-table-cell',
                      'transactions-table-cell--large',
                      'transactions-table-cell--last',
                      'transactions-table-cell@mobile--fullWidth',
                    ])}
                  >
                    {refreshLoad ? (
                      <InlineSkeleton />
                    ) : (
                      <TransactionTags transaction={transaction} />
                    )}
                  </td>
                  <td
                    className={classnames([
                      'transactions-table-cell',
                      'transactions-table-cell@mobile--hr',
                      'transactions-table-cell@mobile--body',
                    ])}
                  />
                  <td
                    className={classnames([
                      'transactions-table-cell',
                      'transactions-table-cell@mobile--hr',
                      'transactions-table-cell@mobile--header',
                    ])}
                  />
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {error && (
        <LoadingError
          text={gettext(
            'Something went wrong when loading your transactions. You may reload the page.'
          )}
          colorClass="secondary"
        />
      )}
      {emptySearchResults && <NoResultsError />}
      {noTransactionYet && <NoTransactionsError />}
      <div className="transactions-load-wrapper">
        {!error &&
          !noTransactionYet &&
          !emptySearchResults &&
          (hasMoreTransactions || loadingMore) && (
            <button
              disabled={loadingMore}
              className={classnames('transactions-load-button', {
                'is-animated': loadingMore,
              })}
              onClick={(event) => {
                event.preventDefault();
                getTransactions(false, false);
              }}
            >
              <div
                className={classnames('transactions-load-text', {
                  'is-animated': loadingMore,
                })}
              >
                Load More
              </div>
              <div
                className={classnames('transactions-load-spinner', {
                  'is-animated': loadingMore,
                })}
              />
            </button>
          )}
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  shadow: qs.parse(state.router.location.search).shadow,
  totalTransactionCount: transactionsTotalCountSelector(state),
  transactionsView: transactionsViewSelector(state),
  error: transactionErrorSelector(state),
  loading: transactionLoadingSelector(state),
  currentFilters: transactionFiltersSelector(state),
  currentStep: tourStepSelector(state, TransactionConstants.TOUR_NAME),
});

const mapDispatchToProps = (dispatch) => ({
  searchTransactions: (search) =>
    dispatch(
      getTransactionsRoutine.trigger({
        filters: {
          search,
          role: [],
          type: [],
          amount: { min: null, max: null },
        },
        refresh: true,
      })
    ),
  getTransactions: (filters, refresh) =>
    dispatch(getTransactionsRoutine.trigger({ filters, refresh })),
  sortTransactions: (sortBy, sortDirection) =>
    dispatch(getTransactionsRoutine.trigger({ filters: { sortBy, sortDirection }, refresh: true })),
  nextTour: () => dispatch(nextStep(TransactionConstants.TOUR_NAME)),
  closeTour: () => dispatch(finishTour(TransactionConstants.TOUR_NAME)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionTable);
