import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Code } from 'spa/components/Code';
import PrimaryLink from 'spa/components/PrimaryLink';
import { Section, SectionTitle, SectionSubTitle, SectionDesc } from 'spa/components/Section';
import AccentedText from 'spa/components/AccentedText';

import { urlFor } from '../../../routeConfig';

const CancelTransaction = () => (
  <div className="integrationsPortal-content-landing">
    <div className="grid">
      <div className="grid-col grid-col--desktopSmall-9">
        <h1 className="integrationsPortal-title">API Guide :: Cancelling a transaction</h1>
        <Section>
          <SectionTitle>Cancelling a transaction</SectionTitle>
          <SectionDesc>Use this API to cancel an ongoing escrow transaction.</SectionDesc>
        </Section>
        <Section>
          <SectionSubTitle>Cancel a transaction</SectionSubTitle>
          <SectionDesc>
            Cancelling a request is as simple as making a patch request to{' '}
            <AccentedText>
              https://api.escrow.com/2017-09-01/transaction/<b>id</b>
            </AccentedText>{' '}
            and setting the <AccentedText>action</AccentedText> field to{' '}
            <AccentedText>cancel</AccentedText>. You can optionally specify a cancellation reason by
            populating the <AccentedText>cancel_information</AccentedText> with a{' '}
            <AccentedText>cancellation_reason</AccentedText>.
          </SectionDesc>
          <SectionDesc>
            {`
            If both the buyer and seller have agreed to the transaction then only the
            listed partner can cancel. If the transaction has already been completed
            or cancelled, then the cancel operation will fail.
            `}
          </SectionDesc>
          <SectionDesc>
            If the API call is successful, it will return the updated transaction object.
          </SectionDesc>
          <Tabs
            className="simpleTabs integrationsPortal-codeSample"
            selectedTabClassName="is-activeTab"
          >
            <TabList className="simpleTabs-nav">
              <Tab className="simpleTabs-nav-item">cURL</Tab>
              <Tab className="simpleTabs-nav-item">Python</Tab>
              <Tab className="simpleTabs-nav-item">Ruby</Tab>
              <Tab className="simpleTabs-nav-item">PHP</Tab>
              <Tab className="simpleTabs-nav-item">C#</Tab>
            </TabList>
            <TabPanel className="simpleTabs-content">
              <Code language="shell">
                {`curl "https://api.escrow.com/2017-09-01/transaction/2020" \\
    -X PATCH \\
    -u "email-address:your-api-key" \\
    -H "Content-Type: application/json" \\
    -d '
{
  "action": "cancel",
  "cancel_information": {
      "cancellation_reason": "Listing was not authorized"
  }
}'
`}
              </Code>
            </TabPanel>
            <TabPanel>
              <Code language="python">
                {`import requests
requests.patch(
    'https://api.escrow.com/2017-09-01/transaction/2020'
    auth=('email address', 'api-key'),
    json={
        "action": "cancel",
        "cancel_information": {
          "cancellation_reason": "Listing was not authorized"
        }
    },
)
`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="ruby">
                {`require 'net/http'
require 'json'

url = URI("https://api.escrow.com/2017-09-01/transaction/2020")

http = Net::HTTP.new(url.host, url.port).tap {|http| http.use_ssl = true}

request = Net::HTTP::Patch.new(url.request_uri).tap {|request|
  request["content-type"] = 'application/json'
  request.basic_auth("email-address", "api-key")
  request.body = {
    'action': 'cancel',
    'cancel_information':{
      'cancellation_reason': 'Listing was no authorized'
    }
  }.to_json
}

response = http.request(request)
puts response.body`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="php">
                {`<?php
$curl = curl_init();
curl_setopt_array($curl, array(
    CURLOPT_URL => 'https://api.escrow.com/2017-09-01/transaction/2020',
    CURLOPT_RETURNTRANSFER => 1,
    CURLOPT_USERPWD => 'email-address:your-password',
    CURLOPT_HTTPHEADER => array(
        'Content-Type: application/json'
    ),
    CURLOPT_CUSTOMREQUEST => 'PATCH',
    CURLOPT_POSTFIELDS => json_encode(
      array(
        'action' => 'cancel',
        'cancel_information' => array(
          'cancellation_reason' => 'Listing was not authorized'
      )
    )
  )
));

$output = curl_exec($curl);
echo $output;
curl_close($curl);
?>`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="cs">
                {`using System;
using System.Text;
using System.Net.Http;
using System.Net.Http.Headers;
using Newtonsoft.Json;

namespace EscrowApiTest
{
    class Program
    {
        static void Main(string[] args)
        {
            HttpClient client = new HttpClient();
            var auth = Encoding.ASCII.GetBytes("email-address:api-key");
            client.DefaultRequestHeaders.Authorization = new AuthenticationHeaderValue(
                "Basic", Convert.ToBase64String(auth));
            client.DefaultRequestHeaders.Accept.Add(
                new MediaTypeWithQualityHeaderValue("application/json"));
            var body = new
            {
                action = "cancel",
                cancel_information = new
                {
                  cancellation_reason = "Listing was not authorized"
                }
            };
            var request = new HttpRequestMessage()
            {
                RequestUri = new Uri("https://api.escrow.com/2017-09-01/transaction/2020"),
                Method = new HttpMethod("PATCH"),
                Content = new StringContent(
                    JsonConvert.SerializeObject(body), Encoding.UTF8, "application/json")
            };
            HttpResponseMessage response = client.SendAsync(request).Result;
            Console.WriteLine(response.Content.ReadAsStringAsync().Result);
        }
    }
}`}
              </Code>
            </TabPanel>
          </Tabs>
          <SectionSubTitle>Example Response</SectionSubTitle>
          <Code language="json">
            {`{
  "close_date": null,
  "creation_date": "2020-12-08T03:15:25.700000+00:00",
  "currency": "usd",
  "description": "The sale of johnwick.com",
  "id": 3621322,
  "is_cancelled": true,
  "items": [
      {
          "category": "domain_name",
          "description": "johnwick.com",
          "extra_attributes": {
              "concierge": false,
              "image_url": "https://i.ebayimg.com/images/g/RicAAOSwzO5e3DZs/s-l1600.jpg",
              "merchant_url": "https://www.ebay.com",
              "with_content": false
          },
          "fees": [
              {
                  "amount": "35.75",
                  "amount_without_taxes": "32.50",
                  "payer_customer": "dshephard+Sandbox1@freelancer.com",
                  "split": "1.0",
                  "taxes": [
                      {
                          "amount": "3.25",
                          "type": "gst"
                      }
                  ],
                  "type": "escrow"
              }
          ],
          "id": 4204903,
          "inspection_period": 259200,
          "quantity": 1,
          "schedule": [
              {
                  "amount": "1000.00",
                  "beneficiary_customer": "keanu.reaves@test.escrow.com",
                  "payer_customer": "dshephard+Sandbox1@freelancer.com",
                  "status": {
                      "disbursed_to_beneficiary": false,
                      "secured": false
                  }
              }
          ],
          "status": {
              "accepted": false,
              "accepted_returned": false,
              "canceled": true,
              "in_dispute": false,
              "received": false,
              "received_returned": false,
              "rejected": false,
              "rejected_returned": false,
              "shipped": false,
              "shipped_returned": false
          },
          "title": "johnwick.com",
          "type": "domain_name"
      }
  ],
  "parties": [
      {
          "agreed": false,
          "customer": "dshephard+Sandbox1@freelancer.com",
          "id": 10921653,
          "initiator": true,
          "next_step": "https://www.escrow-sandbox.com/agree?tid=3621322&token=ad5597f9-8e7e-4dd3-9eba-80857113790f",
          "role": "buyer",
          "verification_required": true
      },
      {
          "agreed": false,
          "customer": "keanu.reaves@test.escrow.com",
          "disbursement_method_selected": false,
          "id": 10921654,
          "initiator": false,
          "next_step": "https://www.escrow-sandbox.com/agree?tid=3621322&token=3ad92150-6b07-457a-9220-e92e4f5a532f",
          "role": "seller"
      },
      {
          "agreed": true,
          "customer": "dshephard+Sandbox1@freelancer.com",
          "id": 10921655,
          "role": "partner"
      }
  ]
}
`}
          </Code>
        </Section>
        <footer className="integrationsPortal-footer">
          <h3 className="integrationsPortal-footer-title">{'Next steps'}</h3>
          <nav className="integrationsPortal-footer-steps">
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Disbursing a transaction"
                link={{
                  type: 'app',
                  route: urlFor('api_guide_disburse_transaction'),
                }}
              />
            </div>
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Receive returned items in a transaction"
                link={{
                  type: 'app',
                  route: urlFor('api_guide_receive_returned_items'),
                }}
              />
            </div>
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Full API reference Document"
                link={{
                  type: 'external',
                  route: window.config.api_docs_page,
                }}
              />
            </div>
          </nav>
        </footer>
      </div>
    </div>
  </div>
);

export default CancelTransaction;
