import React, { useEffect, useState } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { Button } from '@mui/material';
import TransactionConstants from 'spa/constants/TransactionConstants';
import Item from 'spa/components/StartTransaction/TransactionItem/Item';
import Input from 'spa/components/StartTransaction/TransactionItem/Input';
import { useWindowDimensions } from 'spa/hooks';

const DEFAULT_NEW_ITEM = { new: true };

const TransactionItem = ({ fieldName, formValues, edit, setEdit, submitting, valid }) => {
  const { width } = useWindowDimensions();
  const [bulkUploadCount, setBulkUploadCount] = useState(0);
  const [disabled, setDisabled] = useState(undefined);
  return (
    <div>
      <div className="createTransaction-subform--header"> Transaction details </div>
      <FieldArray
        name={fieldName}
        subscription={{ value: true }}
        validate={(value, _, meta) => {
          const error = meta.data.error;
          delete meta.data.error;
          return error;
        }}
      >
        {({ fields }) => {
          // There was a lot of work done in the past to get this useEffect to pass lint
          // however the the lint issue was not able to be resolved while preserving functionality
          // Link to a stackoverflow explaining why this is wrong: https://stackoverflow.com/questions/55280754/using-react-hooks-inside-render-prop-function
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            if (!fields.value) {
              fields.push(DEFAULT_NEW_ITEM);
            }
          }, []); // eslint-disable-line  react-hooks/exhaustive-deps
          const onItemRemove = (index) => {
            if (index === 0) {
              const {
                isDNH,
                isMilestone,
                titleCollection,
                concierge,
                lienHolder,
                shippingFeePayer,
                dnsManagedBy,
                shippingMethod,
              } = fields.value[0];
              fields.update(1, {
                ...fields.value[1],
                isDNH,
                isMilestone,
                titleCollection,
                concierge,
                lienHolder,
                shippingFeePayer,
                dnsManagedBy,
                shippingMethod,
              });
            }
            fields.remove(index);
          };
          return (
            <div>
              {fields.map((name, index) => {
                if (index === edit) {
                  return (
                    <Input
                      name={name}
                      fieldName={fieldName}
                      error={!valid}
                      index={index}
                      key={name}
                      itemType={fields.value[0].itemType}
                      category={fields.value[index].category}
                      bulkUploadCount={bulkUploadCount}
                      setBulkUploadCount={setBulkUploadCount}
                      formValues={formValues}
                      values={fields.value[index]}
                      length={fields.length}
                      onSubmit={() => {
                        setEdit(-1);
                      }}
                      onRemove={() => {
                        onItemRemove(index);
                        setEdit(-1);
                      }}
                      onAdd={() => {
                        fields.push(DEFAULT_NEW_ITEM);
                        setEdit(fields.length);
                      }}
                      disabled={disabled}
                      setDisabled={setDisabled}
                    />
                  );
                }
                return (
                  <Item
                    name={name}
                    index={index}
                    disabled={submitting}
                    key={name}
                    values={fields.value[index]}
                    formValues={formValues}
                    itemsInEdit={edit !== -1}
                    onEdit={() => {
                      setEdit(index);
                    }}
                  />
                );
              })}
              {edit === -1 &&
                fields.length !== 0 &&
                fields.value[0].itemType !==
                  TransactionConstants.TRANSACTION_TYPES.MOTOR_VEHICLE && (
                  <div className="createTransaction-button-container-right">
                    <Button
                      onClick={() => {
                        fields.push(DEFAULT_NEW_ITEM);
                        setEdit(fields.length);
                      }}
                      variant="outlined"
                      color="primary"
                      fullWidth={width < TransactionConstants.DESKTOP_VIEW_WIDTH}
                      size="large"
                    >
                      Add item
                    </Button>
                  </div>
                )}
            </div>
          );
        }}
      </FieldArray>
    </div>
  );
};
export default TransactionItem;
