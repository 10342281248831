import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import AccentedText from 'spa/components/AccentedText';
import { Code } from 'spa/components/Code';
import PrimaryLink from 'spa/components/PrimaryLink';
import { Section, SectionTitle, SectionSubTitle, SectionDesc } from 'spa/components/Section';

import { urlFor } from '../../../routeConfig';

const DisburseTransaction = () => (
  <div className="integrationsPortal-content-landing">
    <div className="grid">
      <div className="grid-col grid-col--desktopSmall-9">
        <h1 className="integrationsPortal-title">API Guide :: Disburse a transaction</h1>
        <Section>
          <SectionTitle>Disbursing a transaction</SectionTitle>
          <SectionDesc>
            Once the buyer has accepted the merchandise, the funds are ready to be disbursed to the
            seller. If the disbursement method has already been set by a customer for the given
            transaction, this will be done automatically. There are a number of different ways that
            the seller can request as a disbursement method. Customers residing in the USA can
            select to be disbursed via Electronic Check (ACH), or Wire Transfer. For international
            customers (residing outside of the USA), International Wire Transfer is only available
            available option at this present time.
          </SectionDesc>
        </Section>
        <Section>
          <SectionTitle>Retrieving available disbursement methods</SectionTitle>
          <SectionDesc>
            This endpoint will give a list of available disbursement methods for the transaction,
            and a list of saved disbursement method profiles that can be used. This endpoint can be
            called by the Seller (and Broker when applicable).
          </SectionDesc>
          <SectionDesc>
            The objects in
            <AccentedText>available_disbursement_methods</AccentedText> will give the type of
            disbursement method, and the total disbursement amount for that method. Not all
            disbursement methods are available to all customers for all transactions.
          </SectionDesc>
          <SectionDesc>
            The endpoint will also return a list of saved disbursement method profiles for the
            customer that can be used for the transaction.
          </SectionDesc>
          <Tabs
            className="simpleTabs integrationsPortal-codeSample"
            selectedTabClassName="is-activeTab"
          >
            <TabList className="simpleTabs-nav">
              <Tab className="simpleTabs-nav-item">cURL</Tab>
              <Tab className="simpleTabs-nav-item">Python</Tab>
              <Tab className="simpleTabs-nav-item">Ruby</Tab>
              <Tab className="simpleTabs-nav-item">PHP</Tab>
              <Tab className="simpleTabs-nav-item">C#</Tab>
            </TabList>
            <TabPanel className="simpleTabs-content">
              <Code language="shell">
                {`curl "https://api.escrow.com/2017-09-01/transaction/2020/disbursement_methods" \\
    -X GET \\
    -u "email-address:your-api-key" \\
    -H "Content-Type: application/json"
`}
              </Code>
            </TabPanel>
            <TabPanel>
              <Code language="python">
                {`import requests

requests.get(
    'https://api.escrow.com/2017-09-01/transaction/2020/disbursement_methods'
    auth=('email address', 'api-key'),
)
`}
              </Code>
            </TabPanel>
            <TabPanel>
              <Code language="ruby">
                {`require 'net/http'
require 'json'

uri = URI("https://api.escrow.com/2017-09-01/transaction/2020/disbursement_methods")

http = Net::HTTP.new(uri.host, uri.port).tap {|http| http.use_ssl = true}

request = Net::HTTP::Get.new(uri.request_uri).tap {|request|
  request["content-type"] = 'application/json'
  request.basic_auth("email-address", "api-key")
}

response = http.request(request)
puts response.body`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="php">
                {`<?php
$curl = curl_init();
curl_setopt_array($curl, array(
    CURLOPT_URL => 'https://api.escrow.com/2017-09-01/transaction/2020/disbursement_methods',
    CURLOPT_RETURNTRANSFER => 1,
    CURLOPT_USERPWD => 'email-address:your-password',
    CURLOPT_HTTPHEADER => array(
        'Content-Type: application/json'
    )
));

$output = curl_exec($curl);
echo $output;
curl_close($curl);
?>`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="cs">
                {`using System;
using System.Text;
using System.Net.Http;
using System.Net.Http.Headers;

namespace EscrowApiTest
{
    class Program
    {
        static void Main(string[] args)
        {
            HttpClient client = new HttpClient();
            var auth = Encoding.ASCII.GetBytes("email-address:api-key");
            client.DefaultRequestHeaders.Authorization = new AuthenticationHeaderValue(
                "Basic", Convert.ToBase64String(auth));
            client.DefaultRequestHeaders.Accept.Add(new MediaTypeWithQualityHeaderValue(
                "application/json"));
            var request = new HttpRequestMessage()
            {
                RequestUri = new Uri(
                    "https://api.escrow.com/2017-09-01/transaction/2020/disbursement_methods"),
                Method = HttpMethod.Get,
            };
            HttpResponseMessage response = client.SendAsync(request).Result;
            Console.WriteLine(response.Content.ReadAsStringAsync().Result);
        }
    }
}`}
              </Code>
            </TabPanel>
          </Tabs>
          <SectionSubTitle>Example Response</SectionSubTitle>
          <Code language="json">
            {`{
  "available_disbursement_methods": [
    {
      "type": "wire_transfer",
      "total": 190.0
    },
    {
      "type": "ach",
      "total": 200.0
    }
  ],
  "saved_disbursement_methods": [
    {
      "id": 55523,
      "type": "ach",
      "currency": "usd",
      "account_name": "John Smith",
      "account_type": "savings",
      "bank_aba_routing_number": "12345678",
      "bank_address": {
          "city": "San Francisco",
          "state": "CA"
      },
      "bank_account_number": "1929231",
      "bank_name": "Amazing Savings Bank of California"
    },
    {
      "id": 59912,
      "type": "wire",
      "currency": "usd",
      "account_name": "John Smith",
      "bank_aba_routing_number": "2923031",
      "bank_account_number": "2303120",
      "bank_branch_number": "9292932",
      "bank_iban": "2929292",
      "bank_swift_code": "29292902",
      "international_routing_code": "2901011",
      "bank_name": "Amazing Savings Bank of Canada",
      "bank_address": {
          "line1": "Bay Street",
          "city": "Thunder Bay",
          "state": "Ontario",
          "country": "CA"
          "post_code": "P7E"
      },
      "intermediary_bank": {
        "bank_aba_routing_number": "290303030",
        "bank_name": "Not-so-amazing Bank",
        "bank_address": {
            "line1": "310 Montgomery St",
            "city": "San Francisco",
            "state": "CA",
            "post_code": "292910"
        },
        "bank_swift_code": "199292",
        "bank_account_number": "202001",
      },
      "beneficiary_address": {
          "line1": "1285 West Broadway",
          "line2": "Apartment 301020",
          "city": "Vancouver",
          "state": "British Columbia",
          "country": "CA",
          "post_code": "10203"
      }
    }
  ],
}
`}
          </Code>
        </Section>
        <Section>
          <SectionTitle>Setting a disbursement method on a transaction</SectionTitle>
          <SectionDesc>
            We provide an endpoint to set the disbursement method for a given transaction. You can
            choose to set the disbursement method either by providing the ID of a saved disbursement
            method profile, or including the full details of a new disbursement method profile. Note
            that if the funds have already been disbursed, this will return an error.
          </SectionDesc>
          <SectionDesc>
            When setting the disbursement method using an ID of a saved disbursement method profile,
            the endpoint will return an error if the saved disbursement method profile is not
            available or not applicable for the current transaction.
          </SectionDesc>
          <SectionDesc>
            When setting the disbursement method by providing a new profile, only disbursement
            method types that are specified in the above available_disbursement_methods list will be
            allowed. All disbursement methods added using this endpoint will be saved for future
            use, and will appear in the saved_disbursement_methods list.
          </SectionDesc>
          <SectionSubTitle>Using a saved disbursement ID</SectionSubTitle>
          <Tabs
            className="simpleTabs integrationsPortal-codeSample"
            selectedTabClassName="is-activeTab"
          >
            <TabList className="simpleTabs-nav">
              <Tab className="simpleTabs-nav-item">cURL</Tab>
              <Tab className="simpleTabs-nav-item">Python</Tab>
              <Tab className="simpleTabs-nav-item">Ruby</Tab>
              <Tab className="simpleTabs-nav-item">PHP</Tab>
              <Tab className="simpleTabs-nav-item">C#</Tab>
            </TabList>
            <TabPanel className="simpleTabs-content">
              <Code language="shell">
                {`curl "https://api.escrow.com/2017-09-01/transaction/2020/disbursement_methods" \\
    -X POST \\
    -u "email-address:your-api-key" \\
    -H "Content-Type: application/json" \\
    -d '
{
  "id": "55912",
}'
`}
              </Code>
            </TabPanel>
            <TabPanel>
              <Code language="python">
                {`import requests

requests.post(
    'https://api.escrow.com/2017-09-01/transaction/2020/disbursement_methods'
    auth=('email address', 'api-key'),
    json={
        'id': '55912',
    }
)
`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="ruby">
                {`require 'net/http'
require 'json'

url = URI("https://api.escrow.com/2017-09-01/transaction/2020/disbursement_methods")

http = Net::HTTP.new(url.host, url.port).tap {|http| http.use_ssl = true}

request = Net::HTTP::Post.new(url.request_uri).tap {|request|
  request["content-type"] = 'application/json'
  request.basic_auth("email-address", "api-key")
  request.body = {
      'id': '55912',
  }.to_json
}

response = http.request(request)
puts response.body`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="php">
                {`<?php
$curl = curl_init();
curl_setopt_array($curl, array(
    CURLOPT_URL => 'https://api.escrow.com/2017-09-01/transaction/2020/disbursement_methods',
    CURLOPT_RETURNTRANSFER => 1,
    CURLOPT_USERPWD => 'email-address:your-password',
    CURLOPT_HTTPHEADER => array(
        'Content-Type: application/json'
    ),
    CURLOPT_POSTFIELDS => json_encode(
        array(
            'id' => '55912',
        )
    )
));

$output = curl_exec($curl);
echo $output;
curl_close($curl);
?>`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="cs">
                {`using System;
using System.Text;
using System.Net.Http;
using System.Net.Http.Headers;
using Newtonsoft.Json;

namespace EscrowApiTest
{
    class Program
    {
        static void Main(string[] args)
        {
            HttpClient client = new HttpClient();
            var auth = Encoding.ASCII.GetBytes("email-address:api-key");
            client.DefaultRequestHeaders.Authorization = new AuthenticationHeaderValue(
                "Basic", Convert.ToBase64String(auth));
            client.DefaultRequestHeaders.Accept.Add(
                new MediaTypeWithQualityHeaderValue("application/json"));
            var body = new
            {
                id = "55912",
            };
            var request = new HttpRequestMessage()
            {
                RequestUri = new Uri(
                    "https://api.escrow.com/2017-09-01/transaction/2020/disbursement_methods"),
                Method = HttpMethod.Post,
                Content = new StringContent(
                    JsonConvert.SerializeObject(body), Encoding.UTF8, "application/json")
            };
            HttpResponseMessage response = client.SendAsync(request).Result;
            Console.WriteLine(response.Content.ReadAsStringAsync().Result);
        }
    }
}`}
              </Code>
            </TabPanel>
          </Tabs>
          <SectionSubTitle>Specifying the disbursement details</SectionSubTitle>

          <SectionDesc>Disbursing via ACH</SectionDesc>

          <Tabs
            className="simpleTabs integrationsPortal-codeSample"
            selectedTabClassName="is-activeTab"
          >
            <TabList className="simpleTabs-nav">
              <Tab className="simpleTabs-nav-item">cURL</Tab>
              <Tab className="simpleTabs-nav-item">Python</Tab>
              <Tab className="simpleTabs-nav-item">Ruby</Tab>
              <Tab className="simpleTabs-nav-item">PHP</Tab>
              <Tab className="simpleTabs-nav-item">C#</Tab>
            </TabList>
            <TabPanel className="simpleTabs-content">
              <Code language="shell">
                {`curl "https://api.escrow.com/2017-09-01/transaction/2020/disbursement_methods" \\
    -X POST \\
    -u "email-address:your-api-key" \\
    -H "Content-Type: application/json" \\
    -d '
{
    "type": "ach",
    "currency": "usd",
    "account_name": "John Smith",
    "account_type": "savings",
    "bank_aba_routing_number": "123456789",
    "bank_address": {
        "city": "San Francisco",
        "state": "CA"
    },
    "bank_account_number": "56789123",
    "bank_name": "Bank of Wyong"
}`}
              </Code>
            </TabPanel>
            <TabPanel>
              <Code language="python">
                {`import requests

requests.post(
    'https://api.escrow.com/2017-09-01/transaction/2020/disbursement_methods',
    auth=('email address', 'api-key'),
    json={
        "type": "ach",
        "currency": "usd",
        "account_name": "John Smith",
        "account_type": "savings",
        "bank_aba_routing_number": "123456789",
        "bank_address": {
            "city": "San Francisco",
            "state": "CA"
        },
        "bank_account_number": "56789123",
        "bank_name": "Bank of Wyong"
    }
)
`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="ruby">
                {`require 'net/http'
require 'json'

url = URI("https://api.escrow.com/2017-09-01/transaction/2020/disbursement_methods")

http = Net::HTTP.new(url.host, url.port).tap {|http| http.use_ssl = true}

request = Net::HTTP::Post.new(url.request_uri).tap {|request|
  request["content-type"] = 'application/json'
  request.basic_auth("email-address", "api-key")
  request.body = {
      "type": "ach",
      "currency": "usd",
      "account_name": "John Smith",
      "account_type": "savings",
      "bank_aba_routing_number": "123456789",
      "bank_address": {
          "city": "San Francisco",
          "state": "CA"
      },
      "bank_account_number": "56789123",
      "bank_name": "Bank of Wyong"
  }.to_json
}

response = http.request(request)
puts response.body`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="php">
                {`<?php
$curl = curl_init();
curl_setopt_array($curl, array(
    CURLOPT_URL => 'https://api.escrow.com/2017-09-01/transaction/2020/disbursement_methods',
    CURLOPT_RETURNTRANSFER => 1,
    CURLOPT_USERPWD => 'email-address:your-password',
    CURLOPT_HTTPHEADER => array(
        'Content-Type: application/json'
    ),
    CURLOPT_POSTFIELDS => json_encode(
        array(
            'currency' => 'usd',
            'account_type' => 'savings',
            'bank_aba_routing_number' => '123456789',
            'bank_address' => array(
                'city' => 'San Francisco',
                'state' => 'CA',
            ),
            'type' => 'ach',
            'account_name' => 'John Smith',
            'bank_name' => 'Bank of Wyong',
            'bank_account_number' => '56789123',
        )
    )
));

$output = curl_exec($curl);
echo $output;
curl_close($curl);
?>`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="cs">
                {`using System;
using System.Text;
using System.Net.Http;
using System.Net.Http.Headers;
using Newtonsoft.Json;

namespace EscrowApiTest
{
    class Program
    {
        static void Main(string[] args)
        {
            HttpClient client = new HttpClient();
            var auth = Encoding.ASCII.GetBytes("email-address:api-key");
            client.DefaultRequestHeaders.Authorization = new AuthenticationHeaderValue(
                "Basic", Convert.ToBase64String(auth));
            client.DefaultRequestHeaders.Accept.Add(
                new MediaTypeWithQualityHeaderValue("application/json"));
            var body = new
            {
                currency = "usd",
                account_type = "savings",
                bank_aba_routing_number = "123456789",
                bank_address = new
                {
                    city = "San Francisco",
                    state = "CA",
                },
                type = "ach",
                account_name = "John Smith",
                bank_name = "Bank of Wyong",
                bank_account_number = "56789123",
            }
        }
    }
}`}
              </Code>
            </TabPanel>
          </Tabs>

          <SectionDesc>Disbursing via wire transfer</SectionDesc>

          <Tabs
            className="simpleTabs integrationsPortal-codeSample"
            selectedTabClassName="is-activeTab"
          >
            <TabList className="simpleTabs-nav">
              <Tab className="simpleTabs-nav-item">cURL</Tab>
              <Tab className="simpleTabs-nav-item">Python</Tab>
              <Tab className="simpleTabs-nav-item">Ruby</Tab>
              <Tab className="simpleTabs-nav-item">PHP</Tab>
              <Tab className="simpleTabs-nav-item">C#</Tab>
            </TabList>
            <TabPanel className="simpleTabs-content">
              <Code language="shell">
                {`curl "https://api.escrow.com/2017-09-01/transaction/2020/disbursement_methods" \\
    -X POST \\
    -u "email-address:your-api-key" \\
    -H "Content-Type: application/json" \\
    -d '
{
    "type": "wire_transfer",
    "currency": "usd",
    "account_name": "John Smith",
    "bank_aba_routing_number": "123456789",
    "bank_account_number": "2303120",
    "bank_branch_number": "9292932",
    "bank_iban": "2929292",
    "bank_swift_code": "29292902",
    "international_routing_code": "2901011",
    "bank_name": "Amazing Savings Bank of Canada",
    "bank_address": {
        "line1": "Bay Street",
        "city": "Thunder Bay",
        "state": "Ontario",
        "country": "CA"
        "post_code": "P7E"
    },
    "intermediary_bank": {
        "bank_aba_routing_number": "290303030",
        "bank_name": "Not-so-amazing Bank",
        "bank_address": {
            "line1": "310 Montgomery St",
            "city": "San Francisco",
            "state": "CA",
            "country": "US",
            "post_code": "292910"
        },
        "bank_swift_code": "199292",
        "bank_account_number": "202001"
    },
    "beneficiary_address": {
        "line1": "1285 West Broadway",
        "line2": "Apartment 301020",
        "city": "Vancouver",
        "state": "British Columbia",
        "country": "CA",
        "post_code": "10203"
    }
}'
`}
              </Code>
            </TabPanel>
            <TabPanel>
              <Code language="python">
                {`import requests

requests.post(
    'https://api.escrow.com/2017-09-01/transaction/2020/disbursement_methods',
    auth=('email address', 'api-key'),
    json={
        'type': 'wire_transfer',
        'currency': 'usd',
        'account_name': 'John Smith',
        'bank_aba_routing_number': '123456789',
        'bank_account_number': '2303120',
        'bank_branch_number': '9292932',
        'bank_iban': '2929292',
        'bank_swift_code': '29292902',
        'international_routing_code': '2901011',
        'bank_name': 'Amazing Savings Bank of Canada',
        'bank_address': {
            'line1': 'Bay Street',
            'city': 'Thunder Bay',
            'state': 'Ontario',
            'country': 'CA',
            'post_code': 'P7E'
        },
        'intermediary_bank': {
            'bank_aba_routing_number': '290303030',
            'bank_name': 'Not-so-amazing Bank',
            'bank_address': {
                'line1': '310 Montgomery St',
                'city': 'San Francisco',
                'state': 'CA',
                'country': 'US',
                'post_code': '292910'
            },
            'bank_swift_code': '199292',
            'bank_account_number': '202001',
        },
        'beneficiary_address': {
            'line1': '1285 West Broadway',
            'line2': 'Apartment 301020',
            'city': 'Vancouver',
            'state': 'British Columbia',
            'country': 'CA',
            'post_code': '10203'
        }
    },
)
`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="ruby">
                {`require 'net/http'
require 'json'

url = URI("https://api.escrow.com/2017-09-01/transaction/2020/disbursement_methods")

http = Net::HTTP.new(url.host, url.port).tap {|http| http.use_ssl = true}

request = Net::HTTP::Post.new(url.request_uri).tap {|request|
  request["content-type"] = 'application/json'
  request.basic_auth("email-address", "api-key")
  request.body = {
      'type': 'wire_transfer',
      'currency': 'usd',
      'account_name': 'John Smith',
      'bank_aba_routing_number': '123456789',
      'bank_account_number': '2303120',
      'bank_branch_number': '9292932',
      'bank_iban': '2929292',
      'bank_swift_code': '29292902',
      'international_routing_code': '2901011',
      'bank_name': 'Amazing Savings Bank of Canada',
      'bank_address': {
          'line1': 'Bay Street',
          'city': 'Thunder Bay',
          'state': 'Ontario',
          'country': 'CA',
          'post_code': 'P7E'
      },
      'intermediary_bank': {
          'bank_aba_routing_number': '290303030',
          'bank_name': 'Not-so-amazing Bank',
          'bank_address': {
              'line1': '310 Montgomery St',
              'city': 'San Francisco',
              'state': 'CA',
              'country': 'US',
              'post_code': '292910'
          },
          'bank_swift_code': '199292',
          'bank_account_number': '202001'
      },
      'beneficiary_address': {
          'line1': '1285 West Broadway',
          'line2': 'Apartment 301020',
          'city': 'Vancouver',
          'state': 'British Columbia',
          'country': 'CA',
          'post_code': '10203'
      }
  }.to_json
}

response = http.request(request)
puts response.body`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="php">
                {`<?php
$curl = curl_init();
curl_setopt_array($curl, array(
    CURLOPT_URL => 'https://api.escrow.com/2017-09-01/transaction/2020/disbursement_methods',
    CURLOPT_RETURNTRANSFER => 1,
    CURLOPT_USERPWD => 'email-address:your-password',
    CURLOPT_HTTPHEADER => array(
        'Content-Type: application/json'
    ),
    CURLOPT_POSTFIELDS => json_encode(
        array(
            'bank_name' => 'Amazing Savings Bank of Canada',
            'bank_branch_number' => '9292932',
            'currency' => 'usd',
            'international_routing_code' => '2901011',
            'beneficiary_address' => array(
                'city' => 'Vancouver',
                'post_code' => '10203',
                'country' => 'CA',
                'line2' => 'Apartment 301020',
                'line1' => '1285 West Broadway',
                'state' => 'British Columbia',
            ),
            'bank_iban' => '2929292',
            'account_name' => 'John Smith',
            'intermediary_bank' => array(
                'bank_aba_routing_number' => '290303030',
                'bank_account_number' => '202001',
                'bank_address' => array(
                    'city' => 'San Francisco',
                    'state' => 'CA',
                    'country' => 'US',
                    'post_code' => '292910',
                    'line1' => '310 Montgomery St',
                ),
                'bank_swift_code' => '199292',
                'bank_name' => 'Not-so-amazing Bank',
            ),
            'bank_aba_routing_number' => '123456789',
            'bank_address' => array(
                'city' => 'Thunder Bay',
                'state' => 'Ontario',
                'post_code' => 'P7E',
                'line1' => 'Bay Street',
                'country' => 'CA',
            ),
            'bank_account_number' => '2303120',
            'bank_swift_code' => '29292902',
            'type' => 'wire_transfer',
        )
    )
));

$output = curl_exec($curl);
echo $output;
curl_close($curl);
?>`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="cs">
                {`using System;
using System.Text;
using System.Net.Http;
using System.Net.Http.Headers;
using Newtonsoft.Json;

namespace EscrowApiTest
{
    class Program
    {
        static void Main(string[] args)
        {
            HttpClient client = new HttpClient();
            var auth = Encoding.ASCII.GetBytes("email-address:api-key");
            client.DefaultRequestHeaders.Authorization = new AuthenticationHeaderValue(
                "Basic", Convert.ToBase64String(auth));
            client.DefaultRequestHeaders.Accept.Add(
                new MediaTypeWithQualityHeaderValue("application/json"));
            var body = new
            {
                bank_name = "Amazing Savings Bank of Canada",
                bank_branch_number = "9292932",
                currency = "usd",
                international_routing_code = "2901011",
                beneficiary_address = new
                {
                    city = "Vancouver",
                    post_code = "10203",
                    country = "CA",
                    line2 = "Apartment 301020",
                    line1 = "1285 West Broadway",
                    state = "British Columbia",
                },
                bank_iban = "2929292",
                account_name = "John Smith",
                intermediary_bank = new
                {
                    bank_aba_routing_number = "123456789",
                    bank_account_number = "202001",
                    bank_address = new
                    {
                        city = "San Francisco",
                        state = "CA",
                        country = "US",
                        post_code = "292910",
                        line1 = "310 Montgomery St",
                    },
                    bank_swift_code = "199292",
                    bank_name = "Not-so-amazing Bank",
                },
                bank_aba_routing_number = "2923031",
                bank_address = new
                {
                    city = "Thunder Bay",
                    state = "Ontario",
                    post_code = "P7E",
                    line1 = "Bay Street",
                    country = "CA",
                },
                bank_account_number = "2303120",
                bank_swift_code = "29292902",
                type = "wire_transfer",
            };
            var request = new HttpRequestMessage()
            {
                RequestUri = new Uri(
                    "https://api.escrow.com/2017-09-01/transaction/2020/disbursement_methods"),
                Method = HttpMethod.Post,
                Content = new StringContent(
                    JsonConvert.SerializeObject(body), Encoding.UTF8, "application/json")
            };
            HttpResponseMessage response = client.SendAsync(request).Result;
            Console.WriteLine(response.Content.ReadAsStringAsync().Result);
        }
    }
}`}
              </Code>
            </TabPanel>
          </Tabs>
        </Section>
        <footer className="integrationsPortal-footer">
          <h3 className="integrationsPortal-footer-title">{'Next steps'}</h3>
          <nav className="integrationsPortal-footer-steps">
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Fetch transaction details"
                link={{
                  type: 'app',
                  route: urlFor('api_guide_fetch_transaction'),
                }}
              />
            </div>
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Fund a transaction"
                link={{
                  type: 'app',
                  route: urlFor('api_guide_fund_transaction'),
                }}
              />
            </div>
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Full API reference Document"
                link={{
                  type: 'external',
                  route: window.config.api_docs_page,
                }}
              />
            </div>
          </nav>
        </footer>
      </div>
    </div>
  </div>
);

export default DisburseTransaction;
