import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Code } from 'spa/components/Code';
import PrimaryLink from 'spa/components/PrimaryLink';
import { Section, SectionTitle, SectionDesc, SectionSubTitle } from 'spa/components/Section';
import HintBanner from 'spa/components/HintBanner';
import { urlFor } from '../../../routeConfig';

const FetchAuctionToken = () => (
  <div className="integrationsPortal-content-landing">
    <div className="grid">
      <div className="grid-col grid-col--desktopSmall-9">
        <h1 className="integrationsPortal-title">
          Escrow Offer API :: Retrieving an auction token
        </h1>
        <Section>
          <SectionTitle>Retrieving an auction token using the listing reference</SectionTitle>
          <SectionDesc>
            After an Escrow Offer auction is created, if you have forgotten the associated token or
            urls, you can retreive it with the listing reference.
          </SectionDesc>
          <HintBanner
            className="integrationsPortal-hintBanner"
            title="Note"
            desc="Only auctions created with a listing references (a unique identifier you have provided for the auction) can be retrieved this way"
            icon="ui-alert-circle"
          />
        </Section>
        <Section>
          <Tabs
            className="simpleTabs integrationsPortal-codeSample"
            selectedTabClassName="is-activeTab"
          >
            <TabList className="simpleTabs-nav">
              <Tab className="simpleTabs-nav-item">cURL</Tab>
              <Tab className="simpleTabs-nav-item">Python</Tab>
              <Tab className="simpleTabs-nav-item">Ruby</Tab>
              <Tab className="simpleTabs-nav-item">PHP</Tab>
              <Tab className="simpleTabs-nav-item">C#</Tab>
            </TabList>
            <TabPanel className="simpleTabs-content">
              <Code language="shell">
                {`curl "https://api.escrow.com/integration/2018-08-01/auction/reference/listing-xyz456/pages" \\
    -X GET \\
    -u "email-address:your-api-key" \\
    -H "Content-Type: application/json" \\
`}
              </Code>
            </TabPanel>
            <TabPanel>
              <Code language="python">
                {`import requests

requests.get(
    'https://api.escrow.com/integration/2018-08-01/auction/reference/listing-xyz456/pages',
    auth=('email address', 'api-key'),
)
`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="ruby">
                {`require 'net/http'
require 'json'

url = URI("https://api.escrow.com/integration/2018-08-01/auction/reference/listing-xyz456/pages")

http = Net::HTTP.new(url.host, url.port).tap {|http| http.use_ssl = true}

request = Net::HTTP::Get.new(url.request_uri).tap {|request|
  request["content-type"] = 'application/json'
  request.basic_auth("email-address", "api-key")
}

response = http.request(request)
puts response.body`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="php">
                {`<?php
$curl = curl_init();
curl_setopt_array($curl, array(
    CURLOPT_URL => 'https://api.escrow.com/integration/2018-08-01/auction/reference/listing-xyz456/pages',
    CURLOPT_RETURNTRANSFER => 1,
    CURLOPT_USERPWD => 'email-address:your-password',
    CURLOPT_HTTPHEADER => array(
        'Content-Type: application/json'
    ),
));

$output = curl_exec($curl);
echo $output;
curl_close($curl);
?>`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="cs">
                {`using System;
using System.Text;
using System.Net.Http;
using System.Net.Http.Headers;
using Newtonsoft.Json;

namespace EscrowApiTest
{
    class Program
    {
        static void Main(string[] args)
        {
            HttpClient client = new HttpClient();
            var auth = Encoding.ASCII.GetBytes("email-address:api-key");
            client.DefaultRequestHeaders.Authorization = new AuthenticationHeaderValue(
                "Basic", Convert.ToBase64String(auth));
            client.DefaultRequestHeaders.Accept.Add(
                new MediaTypeWithQualityHeaderValue("application/json"));
            var request = new HttpRequestMessage()
            {
                RequestUri = new Uri("https://api.escrow.com/integration/2018-08-01/auction/reference/listing-xyz456/pages"),
                Method = HttpMethod.Get,
            };
            HttpResponseMessage response = client.SendAsync(request).Result;
            Console.WriteLine(response.Content.ReadAsStringAsync().Result);
        }
    }
}`}
              </Code>
            </TabPanel>
          </Tabs>
          <SectionSubTitle>Example Response</SectionSubTitle>
          <Code language="json">
            {`{
    "make_offer_page": "https://www.escrow.com/offer?token=2d2afb9f-364f-4f9f-82a5-803344d60432&source=api",
    "offer_management_page": "https://www.escrow.com/offer-management?token=2d2afb9f-364f-4f9f-82a5-803344d60432&source=api",
    "token": "2d2afb9f-364f-4f9f-82a5-803344d60432"
}`}
          </Code>
        </Section>
        <footer className="integrationsPortal-footer">
          <h3 className="integrationsPortal-footer-title">{'Relevant Resources'}</h3>
          <nav className="integrationsPortal-footer-steps">
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="API Basics"
                link={{
                  type: 'app',
                  route: urlFor('api_guide_basics'),
                }}
              />
            </div>
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Creating auctions"
                link={{
                  type: 'app',
                  route: urlFor('escrow_offer_docs'),
                }}
              />
            </div>
          </nav>
        </footer>
      </div>
    </div>
  </div>
);

export default FetchAuctionToken;
