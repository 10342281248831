import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Code } from 'spa/components/Code';
import { Section, SectionTitle, SectionSubTitle, SectionDesc } from 'spa/components/Section';
import AccentedText from 'spa/components/AccentedText';
import PrimaryLink from 'spa/components/PrimaryLink';

import { urlFor } from '../../../routeConfig';

const AgreeTransaction = () => (
  <div className="integrationsPortal-content-landing">
    <div className="grid">
      <div className="grid-col grid-col--desktopSmall-9">
        <h1 className="integrationsPortal-title">API Guide :: Agreeing to a transaction</h1>
        <Section>
          <SectionTitle>Agreeing to a transaction</SectionTitle>
          <SectionDesc>
            When the transaction is created, the terms of the transaction are automatically agreed
            to by the initiating party. However, it is required that the other parties in the
            transaction agree to the transaction before the transaction can move ahead.
          </SectionDesc>
          <SectionDesc>
            Agreeing to a transaction is done by calling the transaction endpoint with
            <AccentedText>action</AccentedText> set to <AccentedText>agree</AccentedText>.
          </SectionDesc>
          <SectionDesc>
            If the API call is successful, it will return the updated transaction object.
          </SectionDesc>
          <Tabs
            className="simpleTabs integrationsPortal-codeSample"
            selectedTabClassName="is-activeTab"
          >
            <TabList className="simpleTabs-nav">
              <Tab className="simpleTabs-nav-item">cURL</Tab>
              <Tab className="simpleTabs-nav-item">Python</Tab>
              <Tab className="simpleTabs-nav-item">Ruby</Tab>
              <Tab className="simpleTabs-nav-item">PHP</Tab>
              <Tab className="simpleTabs-nav-item">C#</Tab>
            </TabList>
            <TabPanel className="simpleTabs-content">
              <Code language="shell">
                {`curl "https://api.escrow.com/2017-09-01/transaction/29292" \\
    -X PATCH \\
    -u "email-address:your-api-key" \\
    -H "Content-Type: application/json" \\
    -d '
{
  "action": "agree"
}'
`}
              </Code>
            </TabPanel>
            <TabPanel>
              <Code language="python">
                {`import requests

requests.patch(
  'https://api.escrow.com/2017-09-01/transaction/29292',
  auth=('email address', 'api-key'),
  json={
    'action': 'agree',
  },
)
`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="ruby">
                {`require 'net/http'
require 'json'

url = URI("https://api.escrow.com/2017-09-01/transaction/29292")

http = Net::HTTP.new(url.host, url.port).tap {|http| http.use_ssl = true}

request = Net::HTTP::Patch.new(url.request_uri).tap {|request|
  request["content-type"] = 'application/json'
  request.basic_auth("email-address", "api-key")
  request.body = {
    'action': 'agree',
  }.to_json
}

response = http.request(request)
puts response.body`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="php">
                {`<?php
$curl = curl_init();
curl_setopt_array($curl, array(
    CURLOPT_URL => 'https://api.escrow.com/2017-09-01/transaction/29292',
    CURLOPT_RETURNTRANSFER => 1,
    CURLOPT_USERPWD => 'email-address:your-password',
    CURLOPT_HTTPHEADER => array(
        'Content-Type: application/json'
    ),
    CURLOPT_CUSTOMREQUEST => 'PATCH',
    CURLOPT_POSTFIELDS => json_encode(
        array(
            'action' => 'agree',
        )
    )
));

$output = curl_exec($curl);
echo $output;
curl_close($curl);
?>`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="cs">
                {`using System;
using System.Text;
using System.Net.Http;
using System.Net.Http.Headers;
using Newtonsoft.Json;

namespace EscrowApiTest
{
    class Program
    {
        static void Main(string[] args)
        {
            HttpClient client = new HttpClient();
            var auth = Encoding.ASCII.GetBytes("email-address:api-key");
            client.DefaultRequestHeaders.Authorization = new AuthenticationHeaderValue(
                "Basic", Convert.ToBase64String(auth));
            client.DefaultRequestHeaders.Accept.Add(
                new MediaTypeWithQualityHeaderValue("application/json"));
            var body = new
            {
                action = "agree",
            };
            var request = new HttpRequestMessage()
            {
                RequestUri = new Uri("https://api.escrow.com/2017-09-01/transaction/29292"),
                Method = new HttpMethod("PATCH"),
                Content = new StringContent(
                    JsonConvert.SerializeObject(body), Encoding.UTF8, "application/json")
            };
            HttpResponseMessage response = client.SendAsync(request).Result;
            Console.WriteLine(response.Content.ReadAsStringAsync().Result);
        }
    }
}`}
              </Code>
            </TabPanel>
          </Tabs>
          <SectionDesc>
            Once you have agreed to the transaction, you can select your payment method and fund the
            transaction.
          </SectionDesc>
        </Section>
        <Section>
          <SectionTitle>Generating an agreement link</SectionTitle>
          <SectionDesc>
            As an alternative to agreeing to the transaction directly, a link can be generated to
            reach an Escrow.com page on which the agree action can occur. This feature may be most
            useful when performed on behalf of another customer as per the the API Basics page.
          </SectionDesc>
          <Tabs
            className="simpleTabs integrationsPortal-codeSample"
            selectedTabClassName="is-activeTab"
          >
            <TabList className="simpleTabs-nav">
              <Tab className="simpleTabs-nav-item">cURL</Tab>
              <Tab className="simpleTabs-nav-item">Python</Tab>
              <Tab className="simpleTabs-nav-item">Ruby</Tab>
              <Tab className="simpleTabs-nav-item">PHP</Tab>
              <Tab className="simpleTabs-nav-item">C#</Tab>
            </TabList>
            <TabPanel className="simpleTabs-content">
              <Code language="shell">
                {`curl "https://api.escrow.com/2017-09-01/transaction/29292/web_link/agree" \\
    -X GET \\
    -u "email-address:your-api-key" \\
    -H "Content-Type: application/json"
`}
              </Code>
            </TabPanel>
            <TabPanel>
              <Code language="python">
                {`import requests

requests.get(
  'https://api.escrow.com/2017-09-01/transaction/29292/web_link/agree',
  auth=('email-address', 'api-key'),
)
`}
              </Code>
            </TabPanel>
            <TabPanel>
              <Code language="ruby">
                {`require 'net/http'
require 'json'

uri = URI("https://api.escrow.com/2017-09-01/transaction/29292/web_link/agree")

http = Net::HTTP.new(uri.host, uri.port).tap {|http| http.use_ssl = true}

request = Net::HTTP::Get.new(uri.request_uri).tap {|request|
  request["content-type"] = 'application/json'
  request.basic_auth("email-address", "api-key")
}

response = http.request(request)
puts response.body`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="php">
                {`<?php
$curl = curl_init();
curl_setopt_array($curl, array(
  CURLOPT_URL => 'https://api.escrow.com/2017-09-01/transaction/29292/web_link/agree',
  CURLOPT_RETURNTRANSFER => 1,
  CURLOPT_USERPWD => 'email-address:your-password',
  CURLOPT_HTTPHEADER => array(
      'Content-Type: application/json'
  ),
));

$output = curl_exec($curl);
echo $output;
curl_close($curl);
?>`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="cs">
                {`using System;
using System.Text;
using System.Net.Http;
using System.Net.Http.Headers;

namespace EscrowApiTest
{
    class Program
    {
        static void Main(string[] args)
        {
            HttpClient client = new HttpClient();
            var auth = Encoding.ASCII.GetBytes("email-address:api-key");
            client.DefaultRequestHeaders.Authorization = new AuthenticationHeaderValue(
                "Basic", Convert.ToBase64String(auth));
            client.DefaultRequestHeaders.Accept.Add(new MediaTypeWithQualityHeaderValue(
                "application/json"));
            var request = new HttpRequestMessage()
            {
                RequestUri = new Uri("https://api.escrow.com/2017-09-01/transaction/29292/web_link/agree"),
                Method = HttpMethod.Get,
            };
            HttpResponseMessage response = client.SendAsync(request).Result;
            Console.WriteLine(response.Content.ReadAsStringAsync().Result);
        }
    }
}`}
              </Code>
            </TabPanel>
          </Tabs>
          <SectionSubTitle>Example response</SectionSubTitle>
          <Code language="json">
            {`{
  "landing_page": "https://www.escrow.com/agree?tid=900518&token=4debae8d-6c52-43c9-ae67-e6bfc0737486"
}`}
          </Code>
        </Section>
        <footer className="integrationsPortal-footer">
          <h3 className="integrationsPortal-footer-title">{'Next steps'}</h3>
          <nav className="integrationsPortal-footer-steps">
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Fund a transaction"
                link={{
                  type: 'app',
                  route: urlFor('api_guide_fund_transaction'),
                }}
              />
            </div>
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Fetch transaction details"
                link={{
                  type: 'app',
                  route: urlFor('api_guide_fetch_transaction'),
                }}
              />
            </div>
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Create a transaction"
                link={{
                  type: 'app',
                  route: urlFor('api_guide_create_transaction'),
                }}
              />
            </div>
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Full API reference Document"
                link={{
                  type: 'external',
                  route: window.config.api_docs_page,
                }}
              />
            </div>
          </nav>
        </footer>
      </div>
    </div>
  </div>
);

export default AgreeTransaction;
