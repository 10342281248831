import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Code } from 'spa/components/Code';
import PrimaryLink from 'spa/components/PrimaryLink';
import { Section, SectionTitle, SectionDesc, SectionSubTitle } from 'spa/components/Section';
import HintBanner from 'spa/components/HintBanner';
import AccentedText from 'spa/components/AccentedText';

import { urlFor } from '../../../routeConfig';

const CreateCustomer = () => (
  <div className="integrationsPortal-content-landing">
    <div className="grid">
      <div className="grid-col grid-col--desktopSmall-9">
        <h1 className="integrationsPortal-title">Escrow API Guide :: Creating a new customer</h1>
        <Section>
          <SectionTitle>Creating a new customer on Escrow.com</SectionTitle>
          <SectionDesc>
            Creating a new customer on Escrow.com is quite straight forward. The minimal amount of
            information required is the customers email address. Specifying the customers name,
            address, phone number and disbursement methods are optional. However within the address
            and disbursement methods, there are required fields that must be supplied in order for
            the request to be considered valid.
          </SectionDesc>
          <SectionDesc>
            {`You are only able to create customers where their email address is not already in use.
            Creating an account for a user that already exists will result in an error, and an HTTP
            403 will be returned. Once a customer has been created, either through the API or if
            they've signed up themselves, their information can no longer be provided by API
            integrations.`}
          </SectionDesc>
          <SectionDesc>
            Once a customer has been created, they will then receive an email introducing them to
            Escrow.com and to log in and set their password.
          </SectionDesc>
          <HintBanner
            className="integrationsPortal-hintBanner"
            title="Note"
            desc="While you are welcome to create customers independent of transactions, this step
            is not strictly necessary. Customer accounts will be created for any person involved in
            a transaction who does not already have an account at the time of transaction creation.
            The customer creation feature exists to allow you to set your customers up in the
            Escrow.com database, which in turn allows them to configure their account and go through
            the identity verification process before a transaction is started (this helps avoid
            transaction delays related to customer account configuration by getting that work done
            ahead of time)."
            icon="ui-alert-circle"
          />
          <Tabs
            className="simpleTabs integrationsPortal-codeSample"
            selectedTabClassName="is-activeTab"
          >
            <TabList className="simpleTabs-nav">
              <Tab className="simpleTabs-nav-item">cURL</Tab>
              <Tab className="simpleTabs-nav-item">Python</Tab>
              <Tab className="simpleTabs-nav-item">Ruby</Tab>
              <Tab className="simpleTabs-nav-item">PHP</Tab>
              <Tab className="simpleTabs-nav-item">C#</Tab>
            </TabList>
            <TabPanel className="simpleTabs-content">
              <Code language="shell">
                {`curl "https://api.escrow.com/2017-09-01/customer" \\
    -X POST \\
    -u "email-address:your-api-key" \\
    -H "Content-Type: application/json" \\
    -d '
{
  "email": "john@test.escrow.com",
  "first_name": "John",
  "middle_name": "Kane",
  "last_name": "Smith",
  "address": {
    "line1": "1829 West Lane",
    "line2": "Apartment 301020",
    "city": "San Francisco",
    "state": "CA",
    "country": "US",
    "post_code": "10203"
  },
  "phone_number": "8885118600"
}'
`}
              </Code>
            </TabPanel>
            <TabPanel>
              <Code language="python">
                {`import requests

requests.post(
  'https://api.escrow.com/2017-09-01/customer',
  auth=('email-address', 'api-key'),
  json={
    "email": "john@test.escrow.com",
    "first_name": "John",
    "middle_name": "Kane",
    "last_name": "Smith",
    "address": {
      "line1": "1829 West Lane",
      "line2": "Apartment 301020",
      "city": "San Francisco",
      "state": "CA",
      "country": "US",
      "post_code": "10203"
    },
    "phone_number": "8885118600"
  }
)
`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="ruby">
                {`require 'net/http'
require 'json'

url = URI("https://api.escrow.com/2017-09-01/customer")

http = Net::HTTP.new(url.host, url.port).tap {|http| http.use_ssl = true}

request = Net::HTTP::Post.new(url.request_uri).tap {|request|
  request["content-type"] = 'application/json'
  request.basic_auth("email-address", "api-key")
  request.body = {
    "email": "john@test.escrow.com",
    "first_name": "John",
    "middle_name": "Kane",
    "last_name": "Smith",
    "address": {
      "line1": "1829 West Lane",
      "line2": "Apartment 301020",
      "city": "San Francisco",
      "state": "CA",
      "country": "US",
      "post_code": "10203"
    },
    "phone_number": "8885118600"
  }.to_json
}

response = http.request(request)
puts response.body`}
              </Code>
            </TabPanel>

            <TabPanel className="simpleTabs-content">
              <Code language="php">
                {`<?php
$curl = curl_init();
curl_setopt_array($curl, array(
    CURLOPT_URL => 'https://api.escrow.com/2017-09-01/customer',
    CURLOPT_RETURNTRANSFER => 1,
    CURLOPT_USERPWD => 'email-address:api-key',
    CURLOPT_HTTPHEADER => array(
        'Content-Type: application/json'
    ),
    CURLOPT_POSTFIELDS => json_encode(
        array(
            'phone_number' => '8885118600',
            'first_name' => 'John',
            'last_name' => 'Smith',
            'middle_name' => 'Kane',
            'address' => array(
                'city' => 'San Francisco',
                'post_code' => '10203',
                'country' => 'US',
                'line2' => 'Apartment 301020',
                'line1' => '1829 West Lane',
                'state' => 'CA',
            ),
            'email' => 'john@test.escrow.com',
        )
    )
));

$output = curl_exec($curl);
echo $output;
curl_close($curl);
?>`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="cs">
                {`using System;
using System.Text;
using System.Net.Http;
using System.Net.Http.Headers;
using Newtonsoft.Json;

namespace EscrowApiTest
{
    class Program
    {
        static void Main(string[] args)
        {
            HttpClient client = new HttpClient();
            var auth = Encoding.ASCII.GetBytes("email-address:your-password");
            client.DefaultRequestHeaders.Authorization = new AuthenticationHeaderValue(
                "Basic", Convert.ToBase64String(auth));
            client.DefaultRequestHeaders.Accept.Add(
                new MediaTypeWithQualityHeaderValue("application/json"));
            var body = new
            {
                phone_number = "8885118600",
                first_name = "John",
                last_name = "Smith",
                middle_name = "Kane",
                address = new
                {
                    city = "San Francisco",
                    post_code = "10203",
                    country = "US",
                    line2 = "Apartment 301020",
                    line1 = "1829 West Lane",
                    state = "CA",
                },
                email = "john@test.escrow.com",
            };
            var request = new HttpRequestMessage()
            {
                RequestUri = new Uri("https://api.escrow.com/2017-09-01/customer"),
                Method = HttpMethod.Post,
                Content = new StringContent(
                    JsonConvert.SerializeObject(body), Encoding.UTF8, "application/json")
            };
            HttpResponseMessage response = client.SendAsync(request).Result;
            Console.WriteLine(response.Content.ReadAsStringAsync().Result);
        }
    }
}`}
              </Code>
            </TabPanel>
          </Tabs>
        </Section>
        <Section>
          <SectionTitle>Getting customer details</SectionTitle>
          <SectionDesc>
            Retrieve your own customer details using the <AccentedText>me</AccentedText> resource.
          </SectionDesc>
          <Tabs
            className="simpleTabs integrationsPortal-codeSample"
            selectedTabClassName="is-activeTab"
          >
            <TabList className="simpleTabs-nav">
              <Tab className="simpleTabs-nav-item">cURL</Tab>
              <Tab className="simpleTabs-nav-item">Python</Tab>
              <Tab className="simpleTabs-nav-item">Ruby</Tab>
              <Tab className="simpleTabs-nav-item">PHP</Tab>
              <Tab className="simpleTabs-nav-item">C#</Tab>
            </TabList>
            <TabPanel className="simpleTabs-content">
              <Code language="bash">
                {`curl "https://api.escrow.com/2017-09-01/customer/me"  \\
  -u email-address:api-key`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="python">
                {`import requests

requests.get(
  'https://api.escrow.com/2017-09-01/customer/me',
  auth=('email-address', \
'api-key'),
)`}
              </Code>
            </TabPanel>
            <TabPanel>
              <Code language="ruby">
                {`require 'net/http'
require 'json'

uri = URI("https://api.escrow.com/2017-09-01/customer/me")

http = Net::HTTP.new(uri.host, uri.port).tap {|http| http.use_ssl = true}

request = Net::HTTP::Get.new(uri.request_uri).tap {|request|
  request["content-type"] = 'application/json'
  request.basic_auth("email-address",\
"api-key")
}

response = http.request(request)
puts response.body`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="php">
                {`<?php
$curl = curl_init();
curl_setopt_array($curl, array(
  CURLOPT_URL => 'https://api.escrow.com/2017-09-01/customer/me',
  CURLOPT_RETURNTRANSFER => 1,
  CURLOPT_USERPWD => 'email-address:api-key',
  CURLOPT_HTTPHEADER => array(
      'Content-Type: application/json'
  ),
));

$output = curl_exec($curl);
echo $output;
curl_close($curl);
?>`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="cs">
                {`using System;
using System.Text;
using System.Net.Http;
using System.Net.Http.Headers;

namespace EscrowApiTest
{
    class Program
    {
        static void Main(string[] args)
        {
            HttpClient client = new HttpClient();
            var auth = Encoding.ASCII.GetBytes("email-address:api-key");
            client.DefaultRequestHeaders.Authorization = new AuthenticationHeaderValue(
                "Basic", Convert.ToBase64String(auth));
            client.DefaultRequestHeaders.Accept.Add(new MediaTypeWithQualityHeaderValue(
                "application/json"));
            var request = new HttpRequestMessage()
            {
                RequestUri = new Uri("https://api.escrow.com/2017-09-01/customer/me"),
                Method = HttpMethod.Get,
            };
            HttpResponseMessage response = client.SendAsync(request).Result;
            Console.WriteLine(response.Content.ReadAsStringAsync().Result);
        }
    }
}`}
              </Code>
            </TabPanel>
          </Tabs>

          <SectionSubTitle>Example Response</SectionSubTitle>
          <Code language="json">
            {`{
    "address": {
        "city": "San Francisco",
        "country": "United States",
        "line1": "1234 Street Road",
        "post_code": "94104",
        "state": "CA"
    },
    "date_of_birth": "1900-01-01T00:00:00+00:00",
    "disbursement_methods": [
        {
            "account_name": "John Wick",
            "account_type": "savings",
            "bank_aba_routing_number": "123456789",
            "bank_account_number": "123456789",
            "bank_address": {
                "city": "San Francisco",
                "state": "CA"
            },
            "bank_name": "Bank of Coffee",
            "currency": "usd",
            "id": 35147,
            "type": "ach"
        }
    ],
    "email": "john.wick@test.escrow.com",
    "first_name": "John",
    "id": 900000,
    "last_name": "Wick",
    "middle_name": "Coffee",
    "phone_number": "+104433221111",
    "webhooks": [
        {
            "id": 7,
            "url": "https://www.escrow.com/webhook"
        }
    ]
}`}
          </Code>
        </Section>
        <footer className="integrationsPortal-footer">
          <h3 className="integrationsPortal-footer-title">{'Next steps'}</h3>
          <nav className="integrationsPortal-footer-steps">
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Create a transaction"
                link={{
                  type: 'app',
                  route: urlFor('api_guide_create_transaction'),
                }}
              />
            </div>
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Full API reference Document"
                link={{
                  type: 'external',
                  route: window.config.api_docs_page,
                }}
              />
            </div>
          </nav>
        </footer>
      </div>
    </div>
  </div>
);

export default CreateCustomer;
