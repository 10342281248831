import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Code } from 'spa/components/Code';
import HintBanner from 'spa/components/HintBanner';
import PrimaryLink from 'spa/components/PrimaryLink';
import { Section, SectionTitle, SectionDesc } from 'spa/components/Section';

import { urlFor } from '../../../routeConfig';

const GettingStarted = () => (
  <div className="integrationsPortal-content-landing">
    <div className="grid">
      <div className="grid-col grid-col--desktopSmall-9">
        <h1 className="integrationsPortal-title">Integration Helper Approving Payments</h1>
        <Section>
          <HintBanner
            className="integrationsPortal-hintBanner"
            title="Note"
            desc="This endpoint is not for use in production. It is available only in the sandbox environment.
            We have included it to help you test your integration.
            It allows you to perform the back office functionality described on this page."
            icon="ui-alert-circle"
          />
          <HintBanner
            className="integrationsPortal-hintBanner"
            title="Note"
            desc="The payment will be secured and approved twenty minutes after the payment has been marked as paid."
            icon="ui-alert-circle"
          />
          <SectionTitle>Approving wire payments</SectionTitle>
          <SectionDesc>
            Approving a wire payment is as simple as specifying a payment method and specifying an
            amount to be paid.
          </SectionDesc>

          <Tabs
            className="simpleTabs integrationsPortal-codeSample"
            selectedTabClassName="is-activeTab"
          >
            <TabList className="simpleTabs-nav">
              <Tab className="simpleTabs-nav-item">cURL</Tab>
              <Tab className="simpleTabs-nav-item">Python</Tab>
              <Tab className="simpleTabs-nav-item">Ruby</Tab>
              <Tab className="simpleTabs-nav-item">PHP</Tab>
              <Tab className="simpleTabs-nav-item">C#</Tab>
            </TabList>
            <TabPanel className="simpleTabs-content">
              <Code language="shell">
                {`% curl "https://integrationhelper.escrow-sandbox.com/v1/transaction/1029454/payments_in" \\
    -X POST \\
    -u "email-address:your-password" \\
    -H "Content-Type: application/json" \\
    -d '
{
    "method": "wire_transfer",
    "amount": "406.00"
}'`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="python">
                {`import requests

requests.post(
    'https://integrationhelper.escrow-sandbox.com/v1/transaction/1029454/payments_in',
    auth=('email-address', 'your-password'),
    json={
      "method": "wire_transfer",
      "amount": "406.00",
    },
)`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="ruby">
                {`require 'net/http'
require 'json'

url = URI("https://integrationhelper.escrow-sandbox.com/v1/transaction/1029454/payments_in")

http = Net::HTTP.new(url.host, url.port).tap {|http| http.use_ssl = true}

request = Net::HTTP::Post.new(url.request_uri).tap {|request|
  request["content-type"] = 'application/json'
  request.basic_auth("email-address", "your-password")
  request.body = {
    method: "wire_transfer",
    amount: "406.00"
  }.to_json
}

response = http.request(request)
puts response.body`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="php">
                {`<?php
$curl = curl_init();
curl_setopt_array($curl, array(
    CURLOPT_URL => 'https://integrationhelper.escrow-sandbox.com/v1/transaction/1029454/payments_in',
    CURLOPT_RETURNTRANSFER => 1,
    CURLOPT_USERPWD => 'email-address:your-password',
    CURLOPT_HTTPHEADER => array(
        'Content-Type: application/json'
    ),
    CURLOPT_POSTFIELDS => json_encode(
        array(
            'method' => 'wire_transfer',
            'amount' => 406.0,
        )
    )
));

$output = curl_exec($curl);
echo $output;
curl_close($curl);
?>`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="cs">
                {`using System;
using System.Text;
using System.Net.Http;
using System.Net.Http.Headers;
using Newtonsoft.Json;

namespace EscrowApiTest
{
    class Program
    {
        static void Main(string[] args)
        {
            HttpClient client = new HttpClient();
            var auth = Encoding.ASCII.GetBytes("email-address:your-password");
            client.DefaultRequestHeaders.Authorization = new AuthenticationHeaderValue(
                "Basic", Convert.ToBase64String(auth));
            client.DefaultRequestHeaders.Accept.Add(
                new MediaTypeWithQualityHeaderValue("application/json"));
            var body = new
            {
                method = "wire_transfer",
                amount = "406.0",
            };
            var request = new HttpRequestMessage()
            {
                RequestUri = new Uri("https://integrationhelper.escrow-sandbox.com/v1/transaction/1029454/payments_in"),
                Method = HttpMethod.Post,
                Content = new StringContent(
                    JsonConvert.SerializeObject(body), Encoding.UTF8, "application/json")
            };
            HttpResponseMessage response = client.SendAsync(request).Result;
            Console.WriteLine(response.Content.ReadAsStringAsync().Result);
        }
    }
}`}
              </Code>
            </TabPanel>
          </Tabs>

          <SectionDesc>
            If your request was successful, the API should return a 200 status code.
          </SectionDesc>
        </Section>

        <footer className="integrationsPortal-footer">
          <h3 className="integrationsPortal-footer-title">{'Next steps'}</h3>

          <nav className="integrationsPortal-footer-steps">
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="API Basics"
                link={{
                  type: 'app',
                  route: urlFor('api_guide_basics'),
                }}
              />
            </div>
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Create a transaction"
                link={{
                  type: 'app',
                  route: urlFor('api_guide_create_transaction'),
                }}
              />
            </div>
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="List all transactions"
                link={{
                  type: 'app',
                  route: urlFor('api_guide_list_transactions'),
                }}
              />
            </div>
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Agree to a transaction"
                link={{
                  type: 'app',
                  route: urlFor('api_guide_agree_transaction'),
                }}
              />
            </div>
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Full API reference document"
                link={{
                  type: 'external',
                  route: window.config.api_docs_page,
                }}
              />
            </div>
          </nav>
        </footer>
      </div>
    </div>
  </div>
);

export default GettingStarted;
