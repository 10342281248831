import { Turnstile as ReactTurnstile } from '@marsidev/react-turnstile';
import React from 'react';

export function Turnstile({ action, onSuccess }) {
  const innerRef = React.useRef(null);
  const onError = React.useCallback(() => {
    innerRef.current.reset();
  }, []);

  const onExpire = React.useCallback(() => {
    innerRef.current.reset();
  }, []);

  return (
    <ReactTurnstile
      ref={innerRef}
      siteKey={window.config.turnstile_site_key}
      onSuccess={onSuccess}
      onError={onError}
      onExpire={onExpire}
      options={{ action, theme: 'light' }}
    />
  );
}
