import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Code } from 'spa/components/Code';
import PrimaryLink from 'spa/components/PrimaryLink';
import { Section, SectionTitle, SectionDesc, SectionSubTitle } from 'spa/components/Section';
import AccentedText from 'spa/components/AccentedText';

import { urlFor } from '../../../routeConfig';

const ListPartnerTransactions = () => (
  <div className="integrationsPortal-content-landing">
    <div className="grid">
      <div className="grid-col grid-col--desktopSmall-9">
        <h1 className="integrationsPortal-title">API Guide :: Listing Partner Transactions</h1>
        <Section>
          <SectionTitle>Listing Partner Transactions</SectionTitle>
          <SectionDesc>
            {`As a partner, you can list all of the transactions that you are involved in. Simply call
              the `}
            <AccentedText>/2017-09-01/partner/transactions</AccentedText>
            {` endpoint. By default, this will return a list of all your transactions.`}
          </SectionDesc>
          <SectionDesc>
            However, if you want to be more specific with your query, you may add the following
            optional parameters for optimization:
          </SectionDesc>
          <SectionSubTitle>Parameters</SectionSubTitle>
          <table className="integrationsPortal-section-table apiTable">
            <thead className="apiTable-header">
              <tr className="apiTable-row">
                <th className="apiTable-cell">Name</th>
                <th className="apiTable-cell">Type</th>
                <th className="apiTable-cell">Description</th>
              </tr>
            </thead>
            <tbody>
              <tr className="apiTable-row">
                <td className="apiTable-cell">
                  <AccentedText>limit</AccentedText>
                </td>
                <td className="apiTable-cell">
                  <AccentedText>integer</AccentedText>
                </td>
                <td className="apiTable-cell">The amount of transactions you want to fetch.</td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell">
                  <AccentedText>next_cursor</AccentedText>
                </td>
                <td className="apiTable-cell">
                  <AccentedText>integer</AccentedText>
                </td>
                <td className="apiTable-cell">
                  The start point of the transactions to be fetched.
                </td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell">
                  <AccentedText>sort_direction</AccentedText>
                </td>
                <td className="apiTable-cell">
                  <AccentedText>string</AccentedText>
                </td>
                <td className="apiTable-cell">
                  Sorting direction by transaction IDs. <br />
                  {`Either `} <AccentedText>asc</AccentedText>
                  {`or `} <AccentedText>desc</AccentedText>
                  {`. Default:  `} <AccentedText>desc</AccentedText>
                </td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell">
                  <AccentedText>status</AccentedText>
                </td>
                <td className="apiTable-cell">
                  <AccentedText>string</AccentedText>
                </td>
                <td className="apiTable-cell">
                  Filters the transactions by status. <br />
                  {`Either `} <AccentedText>ongoing</AccentedText>
                  {`or `} <AccentedText>completed</AccentedText>.
                </td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell">
                  <AccentedText>ids</AccentedText>
                </td>
                <td className="apiTable-cell">
                  <AccentedText>array</AccentedText>
                  {` of`} <br />
                  <AccentedText>integers</AccentedText>
                </td>
                <td className="apiTable-cell">Filters transactions which has the given ids.</td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell">
                  <AccentedText>customer_ids</AccentedText>
                </td>
                <td className="apiTable-cell">
                  <AccentedText>array</AccentedText>
                  {` of`} <br />
                  <AccentedText>integers</AccentedText>
                </td>
                <td className="apiTable-cell">
                  Filters transactions that involves the given customer ids.
                </td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell">
                  <AccentedText>min_amount</AccentedText>
                </td>
                <td className="apiTable-cell">
                  <AccentedText>float</AccentedText>
                </td>
                <td className="apiTable-cell">
                  {`Filters transactions greater than or equal to the `}
                  <AccentedText>min_amount</AccentedText>.
                </td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell">
                  <AccentedText>max_amount</AccentedText>
                </td>
                <td className="apiTable-cell">
                  <AccentedText>float</AccentedText>
                </td>
                <td className="apiTable-cell">
                  {`Filters transactions less than or equal to the `}
                  <AccentedText>max_amount</AccentedText>.
                </td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell">
                  <AccentedText>initiation_start_date</AccentedText>
                </td>
                <td className="apiTable-cell">
                  <AccentedText>datetime</AccentedText>
                </td>
                <td className="apiTable-cell">
                  Filters transactions created since the given start_date (inclusive). <br />
                  {`Follows the ISO 8601 format: `}
                  <AccentedText>YYYY-MM-DDThh:mm:ss.sTZD</AccentedText>
                </td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell">
                  <AccentedText>initiation_end_date</AccentedText>
                </td>
                <td className="apiTable-cell">
                  <AccentedText>datetime</AccentedText>
                </td>
                <td className="apiTable-cell">
                  Filters transactions created before the given initiation_end_date (inclusive).
                  <br /> {`Follows the ISO 8601 format: `}
                  <AccentedText>YYYY-MM-DDThh:mm:ss.sTZD</AccentedText>
                </td>
              </tr>
            </tbody>
          </table>
          <Tabs
            className="simpleTabs integrationsPortal-codeSample"
            selectedTabClassName="is-activeTab"
          >
            <TabList className="simpleTabs-nav">
              <Tab className="simpleTabs-nav-item">cURL</Tab>
              <Tab className="simpleTabs-nav-item">Python</Tab>
              <Tab className="simpleTabs-nav-item">Ruby</Tab>
              <Tab className="simpleTabs-nav-item">PHP</Tab>
              <Tab className="simpleTabs-nav-item">C#</Tab>
            </TabList>
            <TabPanel className="simpleTabs-content">
              <Code language="shell">
                {`curl "https://api.escrow.com/2017-09-01/partner/transactions" \\
    -X GET \\
    -u "email-address:your-api-key"
`}
              </Code>
            </TabPanel>
            <TabPanel>
              <Code language="python">
                {`import requests

requests.get(
    'https://api.escrow.com/2017-09-01/partner/transactions',
    auth=('email address', 'api-key'),
)
`}
              </Code>
            </TabPanel>
            <TabPanel>
              <Code language="ruby">
                {`require 'net/http'
require 'json'

uri = URI("https://api.escrow.com/2017-09-01/partner/transactions")

http = Net::HTTP.new(uri.host, uri.port).tap {|http| http.use_ssl = true}

request = Net::HTTP::Get.new(uri.request_uri).tap {|request|
  request["content-type"] = 'application/json'
  request.basic_auth("email-address", "my-password")
}

response = http.request(request)
puts response.body`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="php">
                {`<?php
$curl = curl_init();
curl_setopt_array($curl, array(
  CURLOPT_URL => 'https://api.escrow.com/2017-09-01/partner/transactions',
  CURLOPT_RETURNTRANSFER => 1,
  CURLOPT_USERPWD => 'email-address:your-password',
  CURLOPT_HTTPHEADER => array(
      'Content-Type: application/json'
  ),
));

$output = curl_exec($curl);
echo $output;
curl_close($curl);
?>`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="cs">
                {`using System;
using System.Text;
using System.Net.Http;
using System.Net.Http.Headers;

namespace EscrowApiTest
{
    class Program
    {
        static void Main(string[] args)
        {
            HttpClient client = new HttpClient();
            var auth = Encoding.ASCII.GetBytes("email-address:api-key");
            client.DefaultRequestHeaders.Authorization = new AuthenticationHeaderValue(
                "Basic", Convert.ToBase64String(auth));
            client.DefaultRequestHeaders.Accept.Add(new MediaTypeWithQualityHeaderValue(
                "application/json"));
            var request = new HttpRequestMessage()
            {
                RequestUri = new Uri("https://api.escrow.com/2017-09-01/partner/transactions"),
                Method = HttpMethod.Get,
            };
            HttpResponseMessage response = client.SendAsync(request).Result;
            Console.WriteLine(response.Content.ReadAsStringAsync().Result);
        }
    }
}`}
              </Code>
            </TabPanel>
          </Tabs>
          <SectionSubTitle>Example Response</SectionSubTitle>
          <Code language="json">
            {`{
    "limit": null,
    "next_cursor": null,
    "sort_by": "id",
    "sort_direction": "desc",
    "transactions": [{
        "close_date": null,
        "creation_date": "2019-05-09T07:49:48.532617+00:00",
        "currency": "usd",
        "description": "The sale of johnwick.com",
        "id": 3300003,
        "items": [
            {
              "description": "johnwick.com",
              "extra_attributes": {
                  "concierge": false,
                  "with_content": false
              },
              "fees": [
                {
                  "amount": "16.25",
                  "amount_without_taxes": "16.25",
                  "payer_customer": "michael.nyqvist@test.escrow.com",
                  "split": "0.5",
                  "type": "escrow"
                },
                {
                  "amount": "16.25",
                  "amount_without_taxes": "16.25",
                  "payer_customer": "keanu.reaves@test.escrow.com",
                  "split": "0.5",
                  "type": "escrow"
                }
              ],
              "id": 3870000,
              "inspection_period": 259200,
              "quantity": 1,
              "schedule": [
                {
                    "amount": "1000.00",
                    "beneficiary_customer": "keanu.reaves@test.escrow.com",
                    "payer_customer": "michael.nyqvist@test.escrow.com",
                    "status": {
                        "disbursed_to_beneficiary": false,
                        "secured": false
                    }
                }
              ],
              "status": {
                  "accepted": false,
                  "accepted_returned": false,
                  "canceled": false,
                  "in_dispute": false,
                  "received": false,
                  "received_returned": false,
                  "rejected": false,
                  "rejected_returned": false,
                  "shipped": false,
                  "shipped_returned": false
              },
              "title": "johnwick.com",
              "type": "domain_name",
            }
        ],
        "parties": [
            {
                "agreed": true,
                "customer": "michael.nyqvist@test.escrow.com",
                "role": "buyer",
                "id": 900000,
                "initiator": false,
                "role": "buyer"
            },
            {
                "agreed": true,
                "customer": "keanu.reaves@test.escrow.com",
                "disbursement_method_selected": false,
                "id": 900001,
                "initiator": true,
                "role": "seller"
            },
            {
                "agreed": true,
                "customer": "halle.berry@test.escrow.com",
                "id": 900002,
                "role": "partner"
            }
        ]
    }]
}`}
          </Code>
        </Section>
        <footer className="integrationsPortal-footer">
          <h3 className="integrationsPortal-footer-title">{'Next steps'}</h3>
          <nav className="integrationsPortal-footer-steps">
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Agree to a transaction"
                link={{
                  type: 'app',
                  route: urlFor('api_guide_agree_transaction'),
                }}
              />
            </div>
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Fetch transaction details"
                link={{
                  type: 'app',
                  route: urlFor('api_guide_fetch_transaction'),
                }}
              />
            </div>
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Create a customer"
                link={{
                  type: 'app',
                  route: urlFor('api_guide_create_customer'),
                }}
              />
            </div>
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Full API reference Document"
                link={{
                  type: 'external',
                  route: window.config.api_docs_page,
                }}
              />
            </div>
          </nav>
        </footer>
      </div>
    </div>
  </div>
);

export default ListPartnerTransactions;
