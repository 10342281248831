import React, { Fragment } from 'react';
import { bindRoutineToReduxForm } from 'redux-saga-routines';
import { Alert, Typography } from '@mui/material';
import { Button } from 'escrow-common-js/dist/components';
import PaymentConstants from 'spa/constants/PaymentConstants';
import { FormSection, reduxForm, formValues } from 'redux-form';
import CheckoutConstants from 'spa/constants/CheckoutConstants';
import AdyenForm from 'spa/components/CreditCard/AdyenForm';
import { Spinner } from 'spa/components/Indicators';
import { FormErrorBox } from 'spa/components/form';
import { apiToCurrencyMapping } from 'escrow-common-js/dist/constants';
import { submitPaymentDetails as submitPaymentDetailsRoutine } from 'spa/actions/CheckoutActions';
import CreditCardLogo from 'spa/components/CreditCardLogo';
import CreditCardFieldset from 'spa/components/CheckoutDetails/V2/CreditCardFieldset';
import { gettext } from '../../../../utils/filters';
import ListOption from './ListOption';
import AdyenActionCard from '../../CreditCard/AdyenActionCard';
class PaymentDetailForm extends React.Component {
  constructor(props) {
    super(props);
    this.renderSavedAccounts = this.renderSavedAccounts.bind(this);
    this.renderCreditCardForm = this.renderCreditCardForm.bind(this);
    this._setPaymentDetails = this._setPaymentDetails.bind(this);
  }
  componentDidMount() {
    const {
      paymentType,
      paymentDetails,
      initPlaidClient,
      getSavedPlaidAccounts,
      getSavedCreditCards,
      savedCreditCardsCalled,
    } = this.props;
    if (paymentDetails && paymentDetails.id) {
      this.props.initialize({ accountId: paymentDetails.id.toString() });
    }
    switch (paymentType) {
      case PaymentConstants.PAYMENT_METHODS.WIRE_TRANSFER:
        break;
      case PaymentConstants.PAYMENT_METHODS.CREDIT_CARD:
        if (!savedCreditCardsCalled) getSavedCreditCards();
        break;
      case PaymentConstants.PAYMENT_METHODS.PAYPAL:
        break;
      case PaymentConstants.PAYMENT_METHODS.DIRECT_DEBIT:
        initPlaidClient({
          onSuccess: (selectedAccount) => {
            this.props.setPaymentDetails(selectedAccount);
          },
        });
        getSavedPlaidAccounts();
        break;
      default:
        break;
    }
  }
  _setPaymentDetails({ id }) {
    const {
      adyenCheckout,
      paymentType,
      setPaymentDetails,
      setAdyenPaymentToken,
      savedCreditCards,
      savedPlaidAccounts,
    } = this.props;
    const { storedPaymentMethods } = (adyenCheckout && adyenCheckout.paymentMethodsResponse) || {};
    const [isAdyenCC, adyenCCId] = id.match(/^adyen-(\w+)/) || [];
    setPaymentDetails({ id });
    if (id === 'new') {
      return;
    }
    switch (paymentType) {
      case PaymentConstants.PAYMENT_METHODS.WIRE_TRANSFER:
        break;
      case PaymentConstants.PAYMENT_METHODS.CREDIT_CARD:
        if (isAdyenCC) {
          setAdyenPaymentToken(adyenCCId);
          setPaymentDetails(
            storedPaymentMethods.find((card) => card.id.toString() === adyenCCId.toString())
          );
        } else {
          setPaymentDetails(savedCreditCards.find((card) => card.id.toString() === id.toString()));
        }
        break;
      case PaymentConstants.PAYMENT_METHODS.PAYPAL:
        break;
      case PaymentConstants.PAYMENT_METHODS.DIRECT_DEBIT:
        setPaymentDetails(
          savedPlaidAccounts.find((account) => account.id.toString() === id.toString())
        );
        break;
      default:
        break;
    }
  }
  renderOption({ id, title, subtitle, content, showContent, logo }) {
    return (
      <ListOption
        id={`${id}`}
        title={title}
        logo={logo}
        name="accountId"
        subtitle={subtitle}
        content={content}
        showContent={showContent}
        onChange={() => this._setPaymentDetails({ id })}
        small
        alternate
      />
    );
  }
  renderCreditCardForm() {
    const {
      customerDetails,
      transaction,
      adyenCCAvailable,
      adyenAction,
      adyenCheckout,
      transactionCCPaymentGateway,
    } = this.props;
    if (transactionCCPaymentGateway === PaymentConstants.CREDIT_CARD_GATEWAY.ADYEN) {
      return (
        <div className="checkout-page-form-body">
          <FormSection name="newCreditCardDetails">
            <AdyenForm
              transaction={transaction}
              adyenCCAvailable={adyenCCAvailable}
              adyenAction={adyenAction}
              checkout={adyenCheckout}
            />
          </FormSection>
        </div>
      );
    }
    return (
      <div className="checkout-page-form-body">
        <FormSection name="newCreditCardDetails">
          <CreditCardFieldset
            customerDetails={customerDetails}
            currency={apiToCurrencyMapping[transaction.currency]}
          />
        </FormSection>
      </div>
    );
  }
  renderSavedAccounts() {
    const { paymentType, paymentDetails, adyenAction, adyenCheckout, transactionCCPaymentGateway } =
      this.props;
    const { storedPaymentMethods: adyenSavedCreditCards = [] } =
      (adyenCheckout && adyenCheckout.paymentMethodsResponse) || {};
    const showSavedAdyenCreditCards =
      transactionCCPaymentGateway === PaymentConstants.CREDIT_CARD_GATEWAY.ADYEN &&
      adyenSavedCreditCards &&
      adyenSavedCreditCards.length > 0;
    let options = [];

    switch (paymentType) {
      case PaymentConstants.PAYMENT_METHODS.WIRE_TRANSFER:
        break;
      case PaymentConstants.PAYMENT_METHODS.CREDIT_CARD: {
        const { savedCreditCards, savedCreditCardsLoading, adyenCCAvailable } = this.props;
        const hasSavedPaypalCC = savedCreditCards && savedCreditCards.length > 0;
        const hasSavedAdyenCC = adyenSavedCreditCards && adyenSavedCreditCards.length > 0;
        const ccFormAvailable = !(
          transactionCCPaymentGateway === PaymentConstants.CREDIT_CARD_GATEWAY.ADYEN &&
          !adyenCCAvailable
        );

        if (hasSavedPaypalCC || hasSavedAdyenCC) {
          if (transactionCCPaymentGateway === PaymentConstants.CREDIT_CARD_GATEWAY.ADYEN) {
            options.push(
              adyenSavedCreditCards.map((card) =>
                this.renderOption({
                  id: `adyen-${card.id}`,
                  logo: (
                    <CreditCardLogo
                      type={card.brand === 'mc' ? 'mastercard' : card.brand.toLowerCase()}
                    />
                  ),
                  title: `${card.name} **** ${card.lastFour}`,
                  subtitle: `${card.holderName} ${card.expiryMonth}/${card.expiryYear}`,
                })
              )
            );
          } else {
            options.push(
              savedCreditCards.map((card) =>
                this.renderOption({
                  id: `${card.id}`,
                  logo: (
                    <CreditCardLogo
                      type={card.brand === 'American Express' ? 'amex' : card.brand.toLowerCase()}
                    />
                  ),
                  title: `${card.brand} **** ${card.last_four_digits}`,
                  subtitle: `${card.name_on_card} ${card.expiry_month}/${card.expiry_year}`,
                })
              )
            );
          }
        } else if (savedCreditCardsLoading) {
          return <Spinner />;
        }
        options.push(
          this.renderOption({
            id: 'new',
            logo: <CreditCardLogo />,
            title: 'Add a new debit or credit card',
          })
        );
        if (paymentDetails.id === 'new' && ccFormAvailable) {
          options.push(this.renderCreditCardForm());
        }
        break;
      }
      case PaymentConstants.PAYMENT_METHODS.PAYPAL:
        break;
      case PaymentConstants.PAYMENT_METHODS.DIRECT_DEBIT: {
        const { savedPlaidAccounts, savedPlaidAccountLoading } = this.props;
        if (!savedPlaidAccounts || savedPlaidAccounts.length === 0) {
          if (savedPlaidAccountLoading) {
            return <Spinner />;
          }
          this._setPaymentDetails({ id: 'new' });
          return (
            <div>
              <h4 className="checkout-page-subheader--bold"> Link Account </h4>
              <p>
                {' '}
                Escrow.com uses Plaid to verify your account ownership. Linking your account will
                allow Escrow.com to fund your escrow quickly and without additional bank fees.
              </p>
            </div>
          );
        }
        options = savedPlaidAccounts.map((account) =>
          this.renderOption({
            id: `${account.id}`,
            title: `${account.institution} ***${account.mask}`,
            subtitle: account.name,
          })
        );
        options.push(
          this.renderOption({
            id: 'new',
            title: 'Link new bank account',
          })
        );
        break;
      }
      default:
        break;
    }
    return (
      <Fragment>
        <div className="checkout-page-details-subheader">
          <span>PAY WITH</span>
          {paymentType === PaymentConstants.PAYMENT_METHODS.CREDIT_CARD && (
            <div>
              <CreditCardLogo type="visa" />
              <CreditCardLogo type="mastercard" />
              <CreditCardLogo type="amex" />
            </div>
          )}
        </div>
        {options}
        {showSavedAdyenCreditCards && (
          <AdyenActionCard adyenAction={adyenAction} adyenCheckout={adyenCheckout} />
        )}
      </Fragment>
    );
  }

  render() {
    const title = {};
    const {
      error,
      submitting,
      paymentDetails,
      paymentType,
      isAdyenValid,
      paymentPortalRedirectError,
      transactionCCPaymentGateway,
      transaction,
    } = this.props;
    const errorMessage = error || paymentPortalRedirectError;

    let disabled = submitting || paymentDetails.id === undefined;
    if (
      paymentDetails.id === 'new' &&
      paymentType === PaymentConstants.PAYMENT_METHODS.CREDIT_CARD &&
      transactionCCPaymentGateway === PaymentConstants.CREDIT_CARD_GATEWAY.ADYEN
    ) {
      disabled = !isAdyenValid || disabled;
    }
    title[PaymentConstants.PAYMENT_METHODS.CREDIT_CARD] = 'Paying through debit or credit card';
    title[PaymentConstants.PAYMENT_METHODS.DIRECT_DEBIT] = 'Paying through Bank Debit (ACH)';
    return (
      <form
        name={this.props.form}
        data-tracking-subsection="payment-details"
        onSubmit={this.props.handleSubmit(bindRoutineToReduxForm(submitPaymentDetailsRoutine))}
      >
        <h3 className="checkout-page-header">
          {title[this.props.paymentType] || 'Payment Details'}
        </h3>
        {this.props.paymentType === PaymentConstants.PAYMENT_METHODS.CREDIT_CARD ? (
          <Alert
            variant="outlined"
            severity="warning"
            sx={{ bgcolor: 'background.paper', marginTop: '14px', marginBottom: '14px' }}
          >
            <Typography
              sx={{ fontWeight: 500, fontSize: '16px', marginBottom: '4px', fontFamily: 'Roboto' }}
            >
              Paying with a Credit Card issued outside the United States?
            </Typography>
            <Typography sx={{ fontWeight: 400, fontSize: '16px', fontFamily: 'Roboto' }}>
              Contact support@escrow.com requesting card payment{' '}
              {transaction && transaction.id
                ? `for TID ${transaction.id}`
                : 'including your 8-digit transaction ID'}{' '}
              and we'll email you an online invoice to complete payment.
            </Typography>
          </Alert>
        ) : null}
        {errorMessage && <FormErrorBox error={errorMessage} />}
        <div>{this.renderSavedAccounts()}</div>
        <div className="checkout-page-form-actions">
          <a
            tabIndex={0}
            role="button"
            onClick={this.props.prevPage}
            className="checkout-page-form-action checkout-page-form-action--secondary"
            data-e2e-target="checkout-form-action"
            data-tracking-name="back"
          >
            {gettext('Back')}
          </a>
          <Button
            type="submit"
            className="checkout-page-form-action"
            data-e2e-target="checkout-form-action"
            data-tracking-name="continue"
            disabled={disabled}
          >
            {submitting ? <Spinner /> : gettext('Continue')}
          </Button>
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: CheckoutConstants.PAYMENT_DETAILS_FORM,
  enableReinitialize: false,
  destroyOnUnmount: false,
  onSubmitSuccess: (result, dispatch, props) => props.nextPage(),
})(formValues('accountId', 'newCreditCardDetails')(PaymentDetailForm));
