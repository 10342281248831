import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Code } from 'spa/components/Code';
import PrimaryLink from 'spa/components/PrimaryLink';
import { Section, SectionTitle, SectionDesc, SectionSubTitle } from 'spa/components/Section';
import AccentedText from 'spa/components/AccentedText';

import { urlFor } from '../../../routeConfig';

const APIBasics = () => (
  <div className="integrationsPortal-content-landing">
    <div className="grid">
      <div className="grid-col grid-col--desktopSmall-9">
        <h1 className="integrationsPortal-title">Escrow API Documentation</h1>
        <Section>
          <SectionTitle>Escrow API Basics</SectionTitle>
          <SectionDesc>
            The Escrow API allows you to create transactions between yourself and another party, or
            between two other parties. It also allows you to update details on your account, such as
            setting your disbursement method.
          </SectionDesc>
          <SectionSubTitle>Base URL</SectionSubTitle>
          <SectionDesc>
            {'All URLs referenced in the documentation have the following base url: '}
            <AccentedText>https://api.escrow.com/2017-09-01/</AccentedText>
            The Escrow API is served over HTTPS and unencrypted HTTP connections are not supported.
          </SectionDesc>
          <SectionDesc>
            {`Escrow.com also provides a sandbox environment that you can use for development and
            testing. This environment acts the same way as production. All user accounts, passwords
            and transactions are independent of the production environment. If you are using the
            Escrow API within the sandbox environment, your base URL will be `}
            <AccentedText>https://api.escrow-sandbox.com/2017-09-01/</AccentedText>
          </SectionDesc>
          <SectionSubTitle>Authentication</SectionSubTitle>
          <SectionDesc>
            {'The Escrow API supports basic authentication as defined in '}
            <a href="https://www.ietf.org/rfc/rfc2617.txt">RFC2617</a>. There are two supported
            methods for authentication, using your username and password and using API keys. While
            using a user name and password allows you to start using the API immediately, all of the
            examples in the documentation are using API keys. We recommend that users switch to
            using API keys in production before deploying their implementations.
          </SectionDesc>

          <SectionDesc>
            {` Most clients will implement basic authentication for you, however if you need to
            implement it yourself, you need to base64 encode your email address and password or API
            key seperated by a colon. For example, if your email address is `}
            <AccentedText>keanu.reeves@test.escrow.com</AccentedText>
            {' and your API key is '}
            <AccentedText>myhorseisamazing</AccentedText>
            {', then you would base64 encode '}
            <AccentedText>keanu.reeves@test.escrow.com:myhorseisamazing</AccentedText>
            {', which would result in '}
            <AccentedText>a2VhbnUucmVldmVzQGVzY3Jvdy5jb206bXlob3JzZWlzYW1hemluZw==</AccentedText>
            {`. Using this
            value, you would then pass the `}
            <AccentedText>Authorization</AccentedText>
            {'header with the value: '}
            <AccentedText>
              Basic a2VhbnUucmVldmVzQGVzY3Jvdy5jb206bXlob3JzZWlzYW1hemluZw==
            </AccentedText>
          </SectionDesc>

          <SectionSubTitle>Example: Authentication with email address and password</SectionSubTitle>
          <SectionDesc>
            {`This is the easiest method of authentication you can use with the Escrow API as you can start
            using it as soon as you have signed up for an Escrow.com account. However, this tightly
            couples any integration with us that you have to your user credentials. For this reason,
            it is recommended that you use API keys, which you can have multiple of. `}
          </SectionDesc>

          <SectionDesc>
            Consider this example where your Escrow.com email address is
            <AccentedText>john.wick@test.escrow.com</AccentedText>, and your Escrow.com password is
            <AccentedText>Escrow1234</AccentedText>. The code snippets below show how you can use
            your chosen client to perform authentication.
          </SectionDesc>

          <Tabs
            className="simpleTabs integrationsPortal-codeSample"
            selectedTabClassName="is-activeTab"
          >
            <TabList className="simpleTabs-nav">
              <Tab className="simpleTabs-nav-item">cURL</Tab>
              <Tab className="simpleTabs-nav-item">Python</Tab>
              <Tab className="simpleTabs-nav-item">Ruby</Tab>
              <Tab className="simpleTabs-nav-item">PHP</Tab>
              <Tab className="simpleTabs-nav-item">C#</Tab>
            </TabList>

            <TabPanel className="simpleTabs-content">
              <Code language="bash">
                {`curl "https://api.escrow.com/2017-09-01/transaction/100"  \\
  -u john.wick@test.escrow.com:Escrow1234`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="python">
                {`import requests

requests.get(
  'https://api.escrow.com/2017-09-01/transaction/100',
  auth=('john.wick@test.escrow.com', 'Escrow1234'),
)`}
              </Code>
            </TabPanel>
            <TabPanel>
              <Code language="ruby">
                {`require 'net/http'
require 'json'

uri = URI("https://api.escrow.com/2017-09-01/transaction/100")

http = Net::HTTP.new(uri.host, uri.port).tap {|http| http.use_ssl = true}

request = Net::HTTP::Get.new(uri.request_uri).tap {|request|
  request["content-type"] = 'application/json'
  request.basic_auth("john.wick@test.escrow.com", "Escrow1234")
}

response = http.request(request)
puts response.body`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="php">
                {`<?php
$curl = curl_init();
curl_setopt_array($curl, array(
    CURLOPT_URL => 'https://api.escrow.com/2017-09-01/transaction/100',
    CURLOPT_RETURNTRANSFER => 1,
    CURLOPT_USERPWD => 'john.wick@test.escrow.com:Escrow1234',
    CURLOPT_HTTPHEADER => array(
        'Content-Type: application/json'
    )
));

$output = curl_exec($curl);
echo $output;
curl_close($curl);
?>`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="cs">
                {`using System;
using System.Text;
using System.Net.Http;
using System.Net.Http.Headers;

namespace EscrowApiTest
{
    class Program
    {
        static void Main(string[] args)
        {
            HttpClient client = new HttpClient();
            var auth = Encoding.ASCII.GetBytes("john.wick@test.escrow.com:Escrow1234");
            client.DefaultRequestHeaders.Authorization = new AuthenticationHeaderValue(
                "Basic", Convert.ToBase64String(auth));
            client.DefaultRequestHeaders.Accept.Add(new MediaTypeWithQualityHeaderValue(
                "application/json"));
            var request = new HttpRequestMessage()
            {
                RequestUri = new Uri("https://api.escrow.com/2017-09-01/transaction/100"),
                Method = HttpMethod.Get,
            };
            HttpResponseMessage response = client.SendAsync(request).Result;
            Console.WriteLine(response.Content.ReadAsStringAsync().Result);
        }
    }
}`}
              </Code>
            </TabPanel>
          </Tabs>

          <SectionDesc>
            Note that the rest of the documentation and guides will simply include
            <AccentedText>email-address</AccentedText> and <AccentedText>api-key</AccentedText>
            for the examples. You must include your authentication header for all requests.
          </SectionDesc>

          <SectionSubTitle>Example: Authentication with API keys</SectionSubTitle>
          <SectionDesc>
            {`From within the account settings page, you can generate API keys associated with your
            account. This is preferable to using your user name and password, as it allows you to
            recycle your credentials without changing the password on your account.`}
            {`
              Using an API key is almost identical to using a username and password for
              authentication. Simply provide your API key as your password and we will handle the
              rest.
            `}
          </SectionDesc>

          <SectionDesc>
            Consider this example where your Escrow.com email address is
            <AccentedText>john.wick@test.escrow.com</AccentedText>, and you have created an API key
            with value
            <AccentedText>
              99_ABCDefGHIKFD93423489023eejkfjkdjkjkjkjkjkjkabcdefgyz4534343kdlPp
            </AccentedText>
            . The code snippets below show how you can use your chosen client to perform
            authentication.
          </SectionDesc>

          <Tabs
            className="simpleTabs integrationsPortal-codeSample"
            selectedTabClassName="is-activeTab"
          >
            <TabList className="simpleTabs-nav">
              <Tab className="simpleTabs-nav-item">cURL</Tab>
              <Tab className="simpleTabs-nav-item">Python</Tab>
              <Tab className="simpleTabs-nav-item">Ruby</Tab>
              <Tab className="simpleTabs-nav-item">PHP</Tab>
              <Tab className="simpleTabs-nav-item">C#</Tab>
            </TabList>

            <TabPanel className="simpleTabs-content">
              <Code language="bash">
                {`curl "https://api.escrow.com/2017-09-01/customer/me"  \\
  -u john.wick@test.escrow.com:99_ABCDefGHIKFD93423489023eejkfjkdjkjkjkjkjkjkabcdefgyz4534343kdlPp`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="python">
                {`import requests

requests.get(
  'https://api.escrow.com/2017-09-01/customer/me',
  auth=('john.wick@test.escrow.com', \
'99_ABCDefGHIKFD93423489023eejkfjkdjkjkjkjkjkjkabcdefgyz4534343kdlPp'),
)`}
              </Code>
            </TabPanel>
            <TabPanel>
              <Code language="ruby">
                {`require 'net/http'
require 'json'

uri = URI("https://api.escrow.com/2017-09-01/customer/me")

http = Net::HTTP.new(uri.host, uri.port).tap {|http| http.use_ssl = true}

request = Net::HTTP::Get.new(uri.request_uri).tap {|request|
  request["content-type"] = 'application/json'
  request.basic_auth("john.wick@test.escrow.com",\
"99_ABCDefGHIKFD93423489023eejkfjkdjkjkjkjkjkjkabcdefgyz4534343kdlPp")
}

response = http.request(request)
puts response.body`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="php">
                {`<?php
$curl = curl_init();
curl_setopt_array($curl, array(
  CURLOPT_URL => 'https://api.escrow.com/2017-09-01/customer/me',
  CURLOPT_RETURNTRANSFER => 1,
  CURLOPT_USERPWD => 'john.wick@test.escrow.com:\
99_ABCDefGHIKFD93423489023eejkfjkdjkjkjkjkjkjkabcdefgyz4534343kdlPp',
  CURLOPT_HTTPHEADER => array(
      'Content-Type: application/json'
  ),
));

$output = curl_exec($curl);
echo $output;
curl_close($curl);
?>`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="cs">
                {`using System;
using System.Text;
using System.Net.Http;
using System.Net.Http.Headers;

namespace EscrowApiTest
{
    class Program
    {
        static void Main(string[] args)
        {
            HttpClient client = new HttpClient();
            var auth = Encoding.ASCII.GetBytes("john.wick@test.escrow.com:\
99_ABCDefGHIKFD93423489023eejkfjkdjkjkjkjkjkjkabcdefgyz4534343kdlPp");
            client.DefaultRequestHeaders.Authorization = new AuthenticationHeaderValue(
                "Basic", Convert.ToBase64String(auth));
            client.DefaultRequestHeaders.Accept.Add(new MediaTypeWithQualityHeaderValue(
                "application/json"));
            var request = new HttpRequestMessage()
            {
                RequestUri = new Uri("https://api.escrow.com/2017-09-01/customer/me"),
                Method = HttpMethod.Get,
            };
            HttpResponseMessage response = client.SendAsync(request).Result;
            Console.WriteLine(response.Content.ReadAsStringAsync().Result);
        }
    }
}`}
              </Code>
            </TabPanel>
          </Tabs>

          <SectionDesc>
            Note that the rest of the documentation and guides will simply include
            <AccentedText>email-address</AccentedText> and <AccentedText>api-key</AccentedText>
            for the examples. You must include your authentication header for all requests.
          </SectionDesc>

          <SectionSubTitle>
            Performing actions on behalf of customers in a transaction
          </SectionSubTitle>
          <SectionDesc>
            {`
              It is possible for approved partners to perform certain actions on a transaction on
              behalf of customers. Once approved, you may perform the agree, ship, receive, reject,
              ship return, receive return and reject return actions on a transaction on behalf of
              another party. You may also check available payment methods and choose a payment
              method for that party.
            `}
          </SectionDesc>
          <SectionDesc>
            {'The way that this is accomplished is by setting the '}
            <AccentedText>As-Customer</AccentedText>
            {` header to the email address of the party you
            want to perform the action on behalf of. `}
          </SectionDesc>
          <Tabs
            className="simpleTabs integrationsPortal-codeSample"
            selectedTabClassName="is-activeTab"
          >
            <TabList className="simpleTabs-nav">
              <Tab className="simpleTabs-nav-item">cURL</Tab>
              <Tab className="simpleTabs-nav-item">Python</Tab>
              <Tab className="simpleTabs-nav-item">Ruby</Tab>
              <Tab className="simpleTabs-nav-item">PHP</Tab>
              <Tab className="simpleTabs-nav-item">C#</Tab>
            </TabList>

            <TabPanel className="simpleTabs-content">
              <Code language="bash">
                {`curl "https://api.escrow.com/2017-09-01/transaction/9292"  \\
  -X PATCH \\
  -u email-address:api-key \\
  -H 'As-Customer: keanu.reaves@test.escrow.com' \\
  -d '{"action": "receive"}'`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="python">
                {`import requests

requests.patch(
  'https://api.escrow.com/2017-09-01/transaction/9292',
  auth=('email address', 'api-key'),
  headers={
    'As-Customer': 'keanu.reaves@test.escrow.com',
  },
  json={
    'action': 'receive',
  }
)`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="ruby">
                {`require 'net/http'
require 'json'

url = URI("https://api.escrow.com/2017-09-01/transaction/2020")

http = Net::HTTP.new(url.host, url.port).tap {|http| http.use_ssl = true}

request = Net::HTTP::Patch.new(url.request_uri).tap {|request|
  request["content-type"] = 'application/json'
  request["As-Customer"] = 'keanu.reaves@test.escrow.com'
  request.basic_auth("email-address", "api-key")
  request.body = {
    'action': 'receive',
  }.to_json
}

response = http.request(request)
puts response.body`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="php">
                {`<?php
$curl = curl_init();
curl_setopt_array($curl, array(
  CURLOPT_URL => 'https://api.escrow.com/2017-09-01/transaction/2020',
  CURLOPT_RETURNTRANSFER => 1,
  CURLOPT_USERPWD => 'email-address:api-key',
  CURLOPT_HTTPHEADER => array(
      'Content-Type: application/json',
      'As-Customer: keanu.reaves@test.escrow.com',
  ),
  CURLOPT_CUSTOMREQUEST => 'PATCH',
  CURLOPT_POSTFIELDS => json_encode(
      array(
          'action' => 'receive',
      )
  )
));

$output = curl_exec($curl);
echo $output;
curl_close($curl);
?>`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="cs">
                {`using System;
using System.Text;
using System.Net.Http;
using System.Net.Http.Headers;
using Newtonsoft.Json;

namespace EscrowApiTest
{
    class Program
    {
        static void Main(string[] args)
        {
            HttpClient client = new HttpClient();
            var auth = Encoding.ASCII.GetBytes("email-address:api-key");
            client.DefaultRequestHeaders.Authorization = new AuthenticationHeaderValue(
                "Basic", Convert.ToBase64String(auth));
            client.DefaultRequestHeaders.Accept.Add(
                new MediaTypeWithQualityHeaderValue("application/json"));
            client.DefaultRequestHeaders.Add("As-Customer", "keanu.reaves@test.escrow.com");
            var body = new
            {
                action = "receive",
            };
            var request = new HttpRequestMessage()
            {
                RequestUri = new Uri("https://api.escrow.com/2017-09-01/transaction/2020"),
                Method = new HttpMethod("PATCH"),
                Content = new StringContent(
                    JsonConvert.SerializeObject(body), Encoding.UTF8, "application/json")
            };
            HttpResponseMessage response = client.SendAsync(request).Result;
            Console.WriteLine(response.Content.ReadAsStringAsync().Result);
        }
    }
}`}
              </Code>
            </TabPanel>
          </Tabs>
          <SectionSubTitle>Rate limiting</SectionSubTitle>
          <SectionDesc>
            {`
            In order to provide a stable platform to all users of the Escrow API, we enforce rate limiting
            on all Escrow API endpoints. The rate limiting is implemented per endpoint and the
            number of remaining requests you have is returned in the HTTP header
            `}{' '}
            <AccentedText>RateLimit-Remaining</AccentedText>{' '}
            {` on every API call you make. If
            you require higher rate limits, please contact us and we will consider your use case.`}
          </SectionDesc>
          <SectionSubTitle>Errors</SectionSubTitle>
          <SectionDesc>
            We do our best to ensure that the Escrow API is error free, however sometimes we will
            have to return an error. If an error occurs, we will always set the HTTP response code
            appropriately. See below for a list of HTTP codes we return.
          </SectionDesc>
          <table className="integrationsPortal-section-table apiTable">
            <thead className="apiTable-header">
              <tr className="apiTable-row">
                <th className="apiTable-cell">HTTP Code</th>
                <th className="apiTable-cell">Description</th>
              </tr>
            </thead>
            <tbody>
              <tr className="apiTable-row">
                <td className="apiTable-cell">
                  <AccentedText>200</AccentedText>
                </td>
                <td className="apiTable-cell">The API request was performed successfully</td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell">
                  <AccentedText>400</AccentedText>
                </td>
                <td className="apiTable-cell">The client submitted a bad request</td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell">
                  <AccentedText>401</AccentedText>
                </td>
                <td className="apiTable-cell">
                  You are trying to perform an action that requires you to be authenticated
                </td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell">
                  <AccentedText>403</AccentedText>
                </td>
                <td className="apiTable-cell">
                  You are trying to perform an action that you are not permitted to perform
                </td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell">
                  <AccentedText>404</AccentedText>
                </td>
                <td className="apiTable-cell">
                  {"You are trying to access a resource that doesn't exist"}
                </td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell">
                  <AccentedText>422</AccentedText>
                </td>
                <td className="apiTable-cell">Your request was malformed</td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell">
                  <AccentedText>429</AccentedText>
                </td>
                <td className="apiTable-cell">
                  You have performed too many requests and have been rate limited.
                </td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell">
                  <AccentedText>500</AccentedText>
                </td>
                <td className="apiTable-cell">There was an unexpected server error</td>
              </tr>
            </tbody>
          </table>
        </Section>
        <footer className="integrationsPortal-footer">
          <h3 className="integrationsPortal-footer-title">{'Next steps'}</h3>
          <nav className="integrationsPortal-footer-steps">
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Create a transaction"
                link={{
                  type: 'app',
                  route: urlFor('api_guide_create_transaction'),
                }}
              />
            </div>
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Create a customer"
                link={{
                  type: 'app',
                  route: urlFor('api_guide_create_customer'),
                }}
              />
            </div>
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Full API reference Document"
                link={{
                  type: 'external',
                  route: window.config.api_docs_page,
                }}
              />
            </div>
          </nav>
        </footer>
      </div>
    </div>
  </div>
);

export default APIBasics;
