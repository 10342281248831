import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Code } from 'spa/components/Code';
import PrimaryLink from 'spa/components/PrimaryLink';
import { Section, SectionTitle, SectionDesc } from 'spa/components/Section';
import AccentedText from 'spa/components/AccentedText';
import { CollapsableContentList } from 'spa/components/CollapsableContent';

import { urlFor } from '../../../routeConfig';

const GettingStarted = () => (
  <div className="integrationsPortal-content-landing">
    <div className="grid">
      <div className="grid-col grid-col--desktopSmall-9">
        <h1 className="integrationsPortal-title">Escrow API Quick Start Guide</h1>
        <Section>
          <SectionTitle>Creating a transaction</SectionTitle>
          <SectionDesc>Create your first transaction with the Escrow API in seconds.</SectionDesc>
          <SectionDesc>
            <CollapsableContentList>
              <li>1. Choose your favourite client from the samples below.</li>
              <li>
                2. Update the authentication field with your Escrow.com username and password.
              </li>
              <li>3. Run the code snippet to create your first transaction.</li>
            </CollapsableContentList>
          </SectionDesc>

          <Tabs
            className="simpleTabs integrationsPortal-codeSample"
            selectedTabClassName="is-activeTab"
          >
            <TabList className="simpleTabs-nav">
              <Tab className="simpleTabs-nav-item">cURL</Tab>
              <Tab className="simpleTabs-nav-item">Python</Tab>
              <Tab className="simpleTabs-nav-item">Ruby</Tab>
              <Tab className="simpleTabs-nav-item">PHP</Tab>
              <Tab className="simpleTabs-nav-item">C#</Tab>
            </TabList>
            <TabPanel className="simpleTabs-content">
              <Code language="shell">
                {`% curl "https://api.escrow.com/2017-09-01/transaction" \\
    -X POST \\
    -u "email-address:your-password" \\
    -H "Content-Type: application/json" \\
    -d '
{
    "parties": [
        {
            "role": "buyer",
            "customer": "me"
        },
        {
            "role": "seller",
            "customer": "keanu.reeves@test.escrow.com"
        }
    ],
    "currency": "usd",
    "description": "1962 Fender Stratocaster",
    "items": [
        {
            "title": "1962 Fender Stratocaster",
            "description": "Like new condition, includes original hard case.",
            "type": "general_merchandise",
            "inspection_period": 259200,
            "quantity": 1,
            "schedule": [
                {
                    "amount": 95000.0,
                    "payer_customer": "me",
                    "beneficiary_customer": "keanu.reeves@test.escrow.com"
                }
            ]
        }
    ]
}'`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="python">
                {`import requests

requests.post(
    'https://api.escrow.com/2017-09-01/transaction',
    auth=('email-address', 'your-password'),
    json={
      "parties": [
          {
              "role": "buyer",
              "customer": "me"
          },
          {
              "role": "seller",
              "customer": "keanu.reeves@test.escrow.com"
          }
      ],
      "currency": "usd",
      "description": "1962 Fender Stratocaster",
      "items": [
          {
              "title": "1962 Fender Stratocaster",
              "description": "Like new condition, includes original hard case.",
              "type": "general_merchandise",
              "inspection_period": 259200,
              "quantity": 1,
              "schedule": [
                  {
                      "amount": 95000.0,
                      "payer_customer": "me",
                      "beneficiary_customer": "keanu.reeves@test.escrow.com"
                  }
              ]
          }
      ]
    },
)`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="ruby">
                {`require 'net/http'
require 'json'

url = URI("https://api.escrow.com/2017-09-01/transaction")

http = Net::HTTP.new(url.host, url.port).tap {|http| http.use_ssl = true}

request = Net::HTTP::Post.new(url.request_uri).tap {|request|
  request["content-type"] = 'application/json'
  request.basic_auth("email-address", "your-password")
  request.body = {
    parties: [
      {
        role: "buyer",
        customer: "me"
      },
      {
        role: "seller",
        customer: "keanu.reeves@test.escrow.com"
      }
    ],
    currency: "usd",
    description: "1962 Fender Stratocaster",
    items: [
      {
        title: "1962 Fender Stratocaster",
        description: "Like new condition, includes original hard case.",
        type: "general_merchandise",
        inspection_period: 259200,
        quantity: 1,
        schedule: [
          {
            amount: 95000.0,
            payer_customer: "me",
            beneficiary_customer: "keanu.reeves@test.escrow.com"
          }
        ]
      }
    ]
  }.to_json
}

response = http.request(request)
puts response.body`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="php">
                {`<?php
$curl = curl_init();
curl_setopt_array($curl, array(
    CURLOPT_URL => 'https://api.escrow.com/2017-09-01/transaction',
    CURLOPT_RETURNTRANSFER => 1,
    CURLOPT_USERPWD => 'email-address:your-password',
    CURLOPT_HTTPHEADER => array(
        'Content-Type: application/json'
    ),
    CURLOPT_POSTFIELDS => json_encode(
        array(
            'parties' => array(
                array(
                  'role' => 'buyer',
                  'customer' => 'me',
                ),
                array(
                    'role' => 'seller',
                    'customer' => 'keanu.reeves@test.escrow.com',
                ),
            ),
            'currency' => 'usd',
            'description' => '1962 Fender Stratocaster',
            'items' => array(
                array(
                    'title' => '1962 Fender Stratocaster',
                    'description' => 'Like new condition, includes original hard case.',
                    'type' => 'general_merchandise',
                    'inspection_period' => 259200,
                    'quantity' => 1,
                    'schedule' => array(
                        array(
                            'amount' => 95000.0,
                            'payer_customer' => 'me',
                            'beneficiary_customer' => 'keanu.reeves@test.escrow.com',
                        )
                    )
                )
            )
        )
    )
));

$output = curl_exec($curl);
echo $output;
curl_close($curl);
?>`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="cs">
                {`using System;
using System.Text;
using System.Net.Http;
using System.Net.Http.Headers;
using Newtonsoft.Json;

namespace EscrowApiTest
{
    class Program
    {
        static void Main(string[] args)
        {
            HttpClient client = new HttpClient();
            var auth = Encoding.ASCII.GetBytes("email-address:your-password");
            client.DefaultRequestHeaders.Authorization = new AuthenticationHeaderValue(
                "Basic", Convert.ToBase64String(auth));
            client.DefaultRequestHeaders.Accept.Add(
                new MediaTypeWithQualityHeaderValue("application/json"));
            var body = new
            {
                currency = "usd",
                description = "1962 Fender Stratocaster",
                items = new[]
                {
                    new
                    {
                        title = "1962 Fender Stratocaster",
                        description = "Like new condition, includes original hard case.",
                        type = "general_merchandise",
                        quantity = "1",
                        schedule = new[]
                        {
                            new
                            {
                                payer_customer = "me",
                                amount = "95000.0",
                                beneficiary_customer = "keanu.reeves@test.escrow.com",
                            },
                        },
                        inspection_period = "259200",
                    },
                },
                parties = new[]
                {
                    new
                    {
                        customer = "me",
                        role = "buyer",
                    },
                    new
                    {
                        customer = "keanu.reeves@test.escrow.com",
                        role = "seller",
                    },
                },
            };
            var request = new HttpRequestMessage()
            {
                RequestUri = new Uri("https://api.escrow.com/2017-09-01/transaction"),
                Method = HttpMethod.Post,
                Content = new StringContent(
                    JsonConvert.SerializeObject(body), Encoding.UTF8, "application/json")
            };
            HttpResponseMessage response = client.SendAsync(request).Result;
            Console.WriteLine(response.Content.ReadAsStringAsync().Result);
        }
    }
}`}
              </Code>
            </TabPanel>
          </Tabs>

          <SectionDesc>
            If your request was successful, the Escrow API should return a 201 status code, and a
            response body like this:
          </SectionDesc>

          <Code language="json">
            {`{
    "id": 3300003,
    "description": "1962 Fender Stratocaster",
    "items": [
        {
            "status": {
                "received": false,
                "rejected_returned": false,
                "rejected": false,
                "received_returned": false,
                "shipped": false,
                "accepted": false,
                "shipped_returned": false,
                "accepted_returned": false
            },
            "description": "Like new condition, includes original hard case.",
            "schedule": [
                {
                    "amount": "95000.00",
                    "payer_customer": "michael.nyqvist@test.escrow.com",
                    "beneficiary_customer": "keanu.reeves@test.escrow.com",
                    "status": {
                        "secured": false
                    }
                }
            ],
            "title": "1962 Fender Stratocaster",
            "inspection_period": 259200,
            "fees": [
                {
                    "payer_customer": "michael.nyqvist@test.escrow.com",
                    "amount": "845.50",
                    "type": "escrow"
                }
            ],
            "type": "general_merchandise",
            "id": 3995049,
            "quantity": 1
        }
    ],
    "creation_date": "2017-10-09T04:08:42.023000+00:00",
    "currency": "usd",
    "parties": [
        {
            "customer": "michael.nyqvist@test.escrow.com",
            "agreed": true,
            "role": "buyer",
            "initiator": true
        },
        {
            "customer": "keanu.reeves@test.escrow.com",
            "agreed": false,
            "role": "seller",
            "initiator": false
        },
        {
            "customer": "michael.nyqvist@test.escrow.com",
            "agreed": true,
            "role": "partner"
        }
    ]
}
`}
          </Code>
        </Section>

        <Section>
          <SectionTitle>Get transaction</SectionTitle>
          <SectionDesc>
            Take note of the <AccentedText>id</AccentedText> field in the above response. This is
            the unique Transaction ID. You can now use this to get the current details and status of
            the transaction as it progresses. Modify and run a code snippet below to get the details
            of the transaction you have just created.
          </SectionDesc>

          <Tabs
            className="simpleTabs integrationsPortal-codeSample"
            selectedTabClassName="is-activeTab"
          >
            <TabList className="simpleTabs-nav">
              <Tab className="simpleTabs-nav-item">cURL</Tab>
              <Tab className="simpleTabs-nav-item">Python</Tab>
              <Tab className="simpleTabs-nav-item">Ruby</Tab>
              <Tab className="simpleTabs-nav-item">PHP</Tab>
              <Tab className="simpleTabs-nav-item">C#</Tab>
            </TabList>
            <TabPanel className="simpleTabs-content">
              <Code language="shell">
                {`curl "https://api.escrow.com/2017-09-01/transaction/3300003" \\
    -X GET \\
    -u "email-address:your-password" \\
    -H "Content-Type: application/json"`}
              </Code>
            </TabPanel>
            <TabPanel>
              <Code language="python">
                {`import requests

requests.get(
    'https://api.escrow.com/2017-09-01/transaction/3300003',
    auth=('email-address', 'your-password'),
)`}
              </Code>
            </TabPanel>
            <TabPanel>
              <Code language="ruby">
                {`require 'net/http'
require 'json'

uri = URI("https://api.escrow.com/2017-09-01/transaction/3300003'")

http = Net::HTTP.new(uri.host, uri.port).tap {|http| http.use_ssl = true}

request = Net::HTTP::Get.new(uri.request_uri).tap {|request|
  request["content-type"] = 'application/json'
  request.basic_auth("email-address", "my-password")
}

response = http.request(request)
puts response.body`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="php">
                {`<?php
$curl = curl_init();
curl_setopt_array($curl, array(
  CURLOPT_URL => 'https://api.escrow.com/2017-09-01/transaction/3300003',
  CURLOPT_RETURNTRANSFER => 1,
  CURLOPT_USERPWD => 'email-address:your-password',
  CURLOPT_HTTPHEADER => array(
      'Content-Type: application/json'
  ),
));

$output = curl_exec($curl);
echo $output;
curl_close($curl);
?>`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="cs">
                {`using System;
using System.Text;
using System.Net.Http;
using System.Net.Http.Headers;

namespace EscrowApiTest
{
    class Program
    {
        static void Main(string[] args)
        {
            HttpClient client = new HttpClient();
            var auth = Encoding.ASCII.GetBytes("email-address:your-password");
            client.DefaultRequestHeaders.Authorization = new AuthenticationHeaderValue(
                "Basic", Convert.ToBase64String(auth));
            client.DefaultRequestHeaders.Accept.Add(new MediaTypeWithQualityHeaderValue(
                "application/json"));
            var request = new HttpRequestMessage()
            {
                RequestUri = new Uri("https://api.escrow.com/2017-09-01/transaction"),
                Method = HttpMethod.Get,
            };
            HttpResponseMessage response = client.SendAsync(request).Result;
            Console.WriteLine(response.Content.ReadAsStringAsync().Result);
        }
    }
}`}
              </Code>
            </TabPanel>
          </Tabs>
        </Section>

        <footer className="integrationsPortal-footer">
          <h3 className="integrationsPortal-footer-title">{'Next steps'}</h3>

          <SectionDesc>
            Congratulations! You have created your first transaction using the Escrow API. To begin
            integrating with Escrow API, checkout the API Basics page where you can setup a sandbox
            environment, use API keys for authentication, setup webhooks and perform actions on
            transactions.
          </SectionDesc>

          <nav className="integrationsPortal-footer-steps">
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="API Basics"
                link={{
                  type: 'app',
                  route: urlFor('api_guide_basics'),
                }}
              />
            </div>
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Create a transaction"
                link={{
                  type: 'app',
                  route: urlFor('api_guide_create_transaction'),
                }}
              />
            </div>
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="List all transactions"
                link={{
                  type: 'app',
                  route: urlFor('api_guide_list_transactions'),
                }}
              />
            </div>
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Agree to a transaction"
                link={{
                  type: 'app',
                  route: urlFor('api_guide_agree_transaction'),
                }}
              />
            </div>
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Full API reference document"
                link={{
                  type: 'external',
                  route: window.config.api_docs_page,
                }}
              />
            </div>
          </nav>
        </footer>
      </div>
    </div>
  </div>
);

export default GettingStarted;
