import React from 'react';
import { connect } from 'react-redux';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { confirmStripePaymentRoutine } from 'spa/actions/CheckoutActions';
import {
  checkoutPaymentTypeSelector,
  checkoutPartnerSuccessSelector,
  checkoutPartnerDataSelector,
  checkoutConfirmStripePaymentSelector,
} from 'spa/selectors/CheckoutSelectors';
import {
  transactionOrDraftSelector,
  transactionDraftSelector,
} from 'spa/selectors/TransactionSelectors';
import { custEmailSelector } from 'spa/selectors/CustomerSelectors';
import CheckoutConstants from 'spa/constants/CheckoutConstants';
import PaymentConstants from 'spa/constants/PaymentConstants';
import { ConfirmationHeader } from 'spa/components/CheckoutDetails/V2';
import { PaymentConfirmation, PaymentConfirmationFooter, StripeConfirmation } from 'spa/components/CheckoutDetails/V2';
import PaymentReviewContainer from './PaymentReviewContainer';
import WirePaymentReviewContainer from './WirePaymentReviewContainer';
import { gettext } from '../../../../utils/filters';

const getCtaMessage = (companyName, returnUrl) => {
  if (companyName && returnUrl) return `Return to ${companyName}`;
  if (returnUrl) return `Continue`;
  return `Return to home`;
};

const getLandingUrl = (transactionId, returnUrl) =>
  returnUrl ||
  (transactionId
    ? `${window.config.www_base_url}/transaction/${transactionId}`
    : `${window.config.www_base_url}/transactions`);

const WireConfirmation = ({ ctaMessage, landingUrl }) => (
  <div>
    <ConfirmationHeader
      showCheck
      headingText={
        'We will send you a confirmation email once we have identified your wire transfer payment.'
      }
    >
      <p className="checkout-confirmation-header-subheading">
        Please wait between 3-5 business days for your confirmation email or call us at <br />
        <a href="tel:+1-415-801-2270">+1-415-801-2270</a> if you have not heard anything from us
        within the next few days.
      </p>
      <span className="checkout-confirmation-header-subheading">{`Thank you for choosing Escrow.com!`}</span>
    </ConfirmationHeader>

    <div className="checkout-page-divider" />
    <PaymentConfirmationFooter />

    <button
      className="checkout-card-btn checkout-card-btn--contained btn btn--secondary"
      onClick={() => window.location.assign(landingUrl)}
    >
      {gettext(ctaMessage)}
    </button>
  </div>
);

class ConfirmationStepContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      pageName: 'payment_review',
    };
    this.nextPage = this.nextPage.bind(this);
    this.prevPage = this.prevPage.bind(this);
    this._renderPage = this._renderPage.bind(this);
  }

  nextPage(paymentType, forceNextStep = false, skipNextPage = false) {
    let flow;
    if (paymentType === PaymentConstants.PAYMENT_METHODS.WIRE_TRANSFER) {
      flow = CheckoutConstants.CONFIRMATION_WIRE;
    } else if (paymentType === PaymentConstants.PAYMENT_METHODS.CREDIT_CARD) {
      flow = CheckoutConstants.CONFIRMATION_STRIPE;
    } else {
      flow = CheckoutConstants.CONFIRMATION_PAYMENT;
    }

    const pageCount = flow.length;
    const newPageNum = this.state.page + 1;
    const nextPage = flow[newPageNum];
    const percentProgress = (newPageNum + 1) / pageCount;
    if (percentProgress === 1 || forceNextStep) {
      this.props.nextStep();
    }
    if (!skipNextPage) this.setState({ page: newPageNum, pageName: nextPage });
  }

  prevPage() {
    switch (this.state.pageName) {
      case 'payment_review':
        this.props.prevStep();
        break;
      default:
        break;
    }
  }

  _renderPage() {
    switch (this.state.pageName) {
      default:
      case 'wire_payment_review':
        return (
          <WirePaymentReviewContainer
            secondaryCtaMessage={getCtaMessage(this.props.companyName, this.props.returnUrl)}
            landingUrl={getLandingUrl(this.props.transaction.id, this.props.returnUrl)}
            nextPage={this.nextPage}
          />
        );
      case 'wire_payment_confirmation':
        return (
          <WireConfirmation
            ctaMessage={getCtaMessage(this.props.companyName, this.props.returnUrl)}
            landingUrl={getLandingUrl(this.props.transaction.id, this.props.returnUrl)}
          />
        );
      case 'stripe_confirmation':
        return (
          <StripeConfirmation
            transaction={this.props.transaction}
            confirmStripePayment={this.props.confirmStripePayment}
            confirmStripePaymentStatus={this.props.confirmStripePaymentStatus}
          />
        );
      case 'payment_review':
        return <PaymentReviewContainer prevPage={this.prevPage} nextPage={this.nextPage} />;
      case 'payment_confirmation':
        return (
          <PaymentConfirmation
            transaction={this.props.transaction}
            custEmail={this.props.custEmail}
            ctaMessage={getCtaMessage(this.props.companyName, this.props.returnUrl)}
            landingUrl={getLandingUrl(this.props.transaction.id, this.props.returnUrl)}
          />
        );
    }
  }

  render() {
    return (
      <SwitchTransition>
        <CSSTransition classNames="checkout-step" key={this.state.pageName}>
          <div
            className="checkout-page"
            data-tracking-section={`${this.props.dataTrackingPrefix}-step-confirm`}
          >
            {this._renderPage()}
          </div>
        </CSSTransition>
      </SwitchTransition>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const transaction = transactionOrDraftSelector(state);
  const draft = transactionDraftSelector(state);
  const returnUrl = draft.return_url;

  const partnerSuccess = checkoutPartnerSuccessSelector(state);
  const partnerData = checkoutPartnerDataSelector(state);
  let companyName = null;
  if (partnerSuccess && partnerData && partnerData.name) {
    companyName = partnerData.name;
  }
  const confirmStripePaymentStatus = checkoutConfirmStripePaymentSelector(state);

  return {
    ...ownProps,
    transaction,
    returnUrl,
    companyName,
    confirmStripePaymentStatus,
    paymentType: checkoutPaymentTypeSelector(state),
    custEmail: custEmailSelector(state),
    
  };
};

const mapDispatchToProps = (dispatch) => ({
  confirmStripePayment: () => {
    dispatch(
      confirmStripePaymentRoutine({
        paymentMethod: PaymentConstants.PAYMENT_METHODS.CREDIT_CARD,
      })
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationStepContainer);
