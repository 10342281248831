import React, { useEffect } from 'react';
import { Divider, Alert } from '@mui/material';
import { FieldArray } from 'react-final-form-arrays';
import { useForm } from 'react-final-form';
import moment from 'moment';
import _ from 'lodash';
import { formatPrice } from 'spa/components/form/format';
import { priceValidate } from 'spa/components/form/validate';
import { useWindowDimensions } from 'spa/hooks';
import { InputField } from 'spa/components/StartTransaction/Fields';
import { formatCurrency } from 'spa/components/StartTransaction/util';
import TransactionConstants from 'spa/constants/TransactionConstants';

const paymentFrequencyMap = {
  Monthly: 1,
  Quarterly: 3,
  Annual: 12,
};
/**
 *
 * @param year, number, 1-5
 * @param month, number, 0-11
 * @param paymentFrequency str, 'Monthly', 'Quarterly' or 'Annual'
 * @param totalPrice number
 * @param firstDate moment object
 *
 * Calculate payment amounts in cents to avoid float precision issues and to use Math.round
 */

const calculateSchedule = (
  year,
  month,
  paymentFrequency,
  totalPrice,
  firstDate,
  totalBuyerBrokerCommission
) => {
  if (
    !firstDate ||
    (!year && !month) ||
    !totalPrice ||
    !paymentFrequency ||
    Number(totalPrice) <= 0
  ) {
    return [];
  }
  const numOfMonth = (year || 0) * 12 + (month || 0);
  const periodReminder = numOfMonth % paymentFrequencyMap[paymentFrequency];
  const fullPaymentCount = (numOfMonth / paymentFrequencyMap[paymentFrequency]) | 0;

  const schedules = [];

  if (periodReminder > 0) {
    const firstPaymentAmount = Math.round(
      (totalPrice * 100 * paymentFrequencyMap[paymentFrequency]) / numOfMonth
    );
    const lastPaymentAmount = totalPrice * 100 - firstPaymentAmount * fullPaymentCount;
    _.range(fullPaymentCount).forEach((i) => {
      schedules.push({
        date: moment(firstDate)
          .utcOffset(0, true)
          .add(paymentFrequencyMap[paymentFrequency] * i, 'months')
          .startOf('day'),
        amount: (firstPaymentAmount / 100).toFixed(2),
      });
    });
    schedules.push({
      date: moment(firstDate)
        .utcOffset(0, true)
        .add(paymentFrequencyMap[paymentFrequency] * fullPaymentCount, 'months')
        .startOf('day'),
      amount: (lastPaymentAmount / 100).toFixed(2),
    });
  } else {
    const firstPaymentAmount = Math.round((totalPrice * 100) / fullPaymentCount);
    const lastPaymentAmount = totalPrice * 100 - firstPaymentAmount * (fullPaymentCount - 1);
    _.range(fullPaymentCount).forEach((i) => {
      schedules.push({
        date: moment(firstDate)
          .utcOffset(0, true)
          .add(paymentFrequencyMap[paymentFrequency] * i, 'months')
          .startOf('day'),
        amount:
          i === fullPaymentCount - 1
            ? (lastPaymentAmount / 100).toFixed(2)
            : (firstPaymentAmount / 100).toFixed(2),
      });
    });
  }
  return schedules.map((sched, i) => ({
    ...sched,
    commission: totalBuyerBrokerCommission && i === 0 ? totalBuyerBrokerCommission : '0.00',
  }));
};

const ErrorBox = ({ message }) => {
  if (typeof message === 'string') {
    return (
      <div className="createTransaction-check-container">
        <Alert severity="error">{message}</Alert>
      </div>
    );
  }
  return null;
};

function validateAmount(values, allValues) {
  const { brokerCommissionPayer } = allValues;
  const { totalPrice, brokerCommission } = allValues.items.reduce(
    (accumulator, object) => {
      accumulator.totalPrice += parseFloat(object.price);
      accumulator.brokerCommission += parseFloat(object.brokerCommission);
      return accumulator;
    },
    { totalPrice: 0, brokerCommission: 0 }
  );
  if (!values) {
    return undefined;
  }
  const showBrokerFields =
    brokerCommissionPayer &&
    brokerCommissionPayer !== TransactionConstants.TRANSACTION_ROLES.SELLER;
  if (showBrokerFields) {
    const totalBuyerBrokerCommission = (
      brokerCommission / brokerCommissionPayer.split(',').length
    ).toFixed(2);
    const commissionTotal = values
      .reduce((acc, cur) => acc + parseFloat(cur.commission), 0)
      .toFixed(2);
    if (commissionTotal !== totalBuyerBrokerCommission) {
      return 'Please ensure the sum of all schedule broker commissions equates to the total broker commission to be paid by the buyer';
    }
  }
  const scheduleTotal = values.reduce((acc, cur) => acc + parseFloat(cur.amount), 0).toFixed(2);
  if (scheduleTotal !== parseFloat(totalPrice).toFixed(2)) {
    return 'Please ensure the sum of all schedule amounts equates to transaction item price.';
  }
  return undefined;
}

const PaymentSchedule = ({
  year,
  month,
  paymentFrequency,
  firstDate,
  totalPrice,
  brokerCommission,
  paymentSchedule,
}) => {
  const { width } = useWindowDimensions();
  const isDesktopView = width >= TransactionConstants.DESKTOP_VIEW_WIDTH;
  const form = useForm();
  const formState = form.getState();
  const currency = form.getFieldState('currency').value;
  const brokerCommissionPayer = formState.values.brokerCommissionPayer;
  const showBrokerFields =
    brokerCommissionPayer &&
    brokerCommissionPayer !== TransactionConstants.TRANSACTION_ROLES.SELLER;
  let totalBuyerBrokerCommission = 0;
  if (showBrokerFields) {
    totalBuyerBrokerCommission = (
      brokerCommission / brokerCommissionPayer.split(',').length
    ).toFixed(2);
  }

  /**
   * The lint rule on this effects depedencies has been disabled as one of the recommended
   * dependencies causes this effect to trigger an infinite render + effect loop. This
   * arises from the mutation of the form inside this effect. A possible solution to this
   * is to move this logic to inside the calculateSchedule() function.
   */
  useEffect(
    () => {
      if (paymentSchedule && paymentSchedule.length > 0) {
        const newSchedule = paymentSchedule.map((sched, i) => ({
          ...sched,
          commission: i === 0 ? totalBuyerBrokerCommission : '0.00',
        }));
        form.change('paymentSchedule', newSchedule);
      }
    },
    [brokerCommission, brokerCommissionPayer] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    const newSchedule = calculateSchedule(
      year,
      month,
      paymentFrequency,
      totalPrice,
      firstDate,
      totalBuyerBrokerCommission
    );
    if (newSchedule) {
      form.change('paymentSchedule', newSchedule);
    }
  }, [year, month, paymentFrequency, firstDate, totalPrice, form, totalBuyerBrokerCommission]);

  if (
    !firstDate ||
    (!year && !month) ||
    !totalPrice ||
    Number(totalPrice) <= 0 ||
    !paymentFrequency ||
    !form.getFieldState('firstPaymentDate') ||
    !form.getFieldState('firstPaymentDate').valid
  ) {
    return null;
  }

  return (
    <div>
      <div className="createTransaction-subform--header"> Payment schedule </div>
      <Divider variant="fullWidth" />
      <FieldArray
        name="paymentSchedule"
        validate={(values, allValues) => validateAmount(values, allValues)}
        validateFields={[
          'items[0].price',
          'years',
          'months',
          'firstPaymentDate',
          'paymentFrequency',
        ]}
      >
        {({ fields, meta }) => (
          <div>
            {fields.map((name, i) => (
              <div
                className={showBrokerFields ? null : 'createTransaction-inline-fields-container'}
                key={name}
              >
                {(isDesktopView || showBrokerFields) && (
                  <div className="createTransaction-inline-field--half createTransaction-label-container">
                    Payment {i + 1} on {fields.value[i].date.format('MM/DD/YYYY')}
                  </div>
                )}
                <div
                  className={
                    showBrokerFields
                      ? 'createTransaction-inline-fields-container'
                      : 'createTransaction-inline-field--half'
                  }
                >
                  <div
                    className={
                      showBrokerFields
                        ? 'createTransaction-inline-field--half'
                        : 'createTransaction-inline-field--wide'
                    }
                  >
                    <InputField
                      name={`${name}.amount`}
                      label={
                        isDesktopView || showBrokerFields
                          ? `Amount (${currency.toUpperCase()})`
                          : `Payment ${i + 1} on ${fields.value[i].date.format('MM/DD/YYYY')}`
                      }
                      isNumeric
                      currency={currency}
                      validate={priceValidate}
                      format={formatPrice}
                      formatOnBlur
                      initialValue={fields.value[i].amount}
                    />
                  </div>
                  {showBrokerFields && (
                    <div className="createTransaction-inline-field--half">
                      <InputField
                        name={`${name}.commission`}
                        label={`Broker's Commission (${currency.toUpperCase()})`}
                        isNumeric
                        currency={currency}
                        format={formatPrice}
                        formatOnBlur
                        initialValue={fields.value[i].commission}
                      />
                    </div>
                  )}
                </div>
                {showBrokerFields && (
                  <div className="createTransaction-label-container--right">
                    Total:{' '}
                    {formatCurrency(
                      Number(fields.value[i].amount) + Number(fields.value[i].commission),
                      currency
                    )}
                  </div>
                )}
              </div>
            ))}
            {meta.error && <ErrorBox message={meta.error} />}
          </div>
        )}
      </FieldArray>
    </div>
  );
};

export default PaymentSchedule;
