import React, { Fragment } from 'react';
import { Alert, Typography } from '@mui/material';
import window from 'window-shim';
import classnames from 'classnames';
import { FormSection, Field, reduxForm, formValues } from 'redux-form';
import { bindRoutineToReduxForm } from 'redux-saga-routines';
import ReactSVG from 'react-svg';
import { apiToCurrencyMapping } from 'escrow-common-js/dist/constants';
import A from 'spa/components/A';
import Currency from 'spa/components/Currency';
import { Spinner } from 'spa/components/Indicators';
import { Checkbox, FormError } from 'spa/components/form';
import Icon from 'spa/components/Icon';
import PaymentConstants from 'spa/constants/PaymentConstants';
import TransactionConstants from 'spa/constants/TransactionConstants';
import TransactionSummaryContainer from 'spa/containers/TransactionSummary/TransactionSummaryContainer';
import CreditCardLogo from 'spa/components/CreditCardLogo';
import CreditCardFieldset from 'spa/components/CreditCard/CreditCardFieldset';
import AdyenForm from 'spa/components/CreditCard/AdyenForm';
import { getCurrencyTagFromApiCurrency } from 'escrow-common-js/dist/utils';
import { Modal } from 'escrow-common-js/dist/components';
import { selectPaymentMethod as selectPaymentMethodRoutine } from 'spa/actions/PaymentsActions';
import WireTransferDetail from 'spa/components/WireTransfer/WireTransferDetail';
import WireTransferContainer from 'spa/containers/Checkout/WireTransferContainer';

import { gettext } from '../../../utils/filters';
import ListOption from './ListOption';
import AdyenActionCard from '../CreditCard/AdyenActionCard';

export class PaymentForm extends React.Component {
  constructor(props) {
    super(props);
    this._setPaymentType = this._setPaymentType.bind(this);
    this.renderWireDetails = this.renderWireDetails.bind(this);
    this.renderCreditCardForm = this.renderCreditCardForm.bind(this);
    this.adyenState = {};
    this.state = {
      showModal: false,
      showWireForm: !props.wireDetails,
    };
    this.validators = {
      acceptTerms: (value) =>
        value !== true
          ? gettext('You need to agree to the General Escrow Instructions.')
          : undefined,
    };
  }

  componentDidMount() {
    const {
      getTransactionAndPaymentMethods,
      getWireDetails,
      getAbTest,
      getCheckDetails,
      getLastBuyerBankEntry,
      resetPaymentPageState,
      customerId,
      initPlaidClient,
      getSavedPlaidAccounts,
    } = this.props;

    resetPaymentPageState();
    getTransactionAndPaymentMethods();
    getWireDetails();
    getCheckDetails();
    getAbTest({
      action: 'get_or_create_enrollment',
      ab_test_name: 'buyer_bank_modal',
      reference_type: 'customer',
      reference_id: customerId,
    });

    getLastBuyerBankEntry();
    initPlaidClient();
    getSavedPlaidAccounts();
  }

  componentDidUpdate() {
    const { getSavedCreditCards, transaction, customerSavedCreditCardsCalledOnce } = this.props;

    if (!customerSavedCreditCardsCalledOnce && transaction) {
      getSavedCreditCards(transaction.currency);
    }
  }

  _setPaymentType(event, newValue) {
    const { setPaymentType, setAdyenPaymentToken, transactionCCPaymentGateway } = this.props;
    const [isSaved, savedMethod, id] = newValue.match(/^saved-(\w+)-(\w+)/) || [];
    if (
      isSaved &&
      savedMethod === PaymentConstants.PAYMENT_METHODS.CREDIT_CARD &&
      transactionCCPaymentGateway === PaymentConstants.CREDIT_CARD_GATEWAY.ADYEN
    ) {
      setAdyenPaymentToken(id);
    }
    setPaymentType(newValue);
  }
  _getPaymentOptions(transaction) {
    const result = [];

    if (!transaction || !transaction.paymentMethods) {
      return result;
    }
    const paymentMethods = transaction.paymentMethods;
    if (paymentMethods.available_payment_methods) {
      paymentMethods.available_payment_methods.map((paymentMethod) => {
        result.push({
          value: paymentMethod.type,
          label: TransactionConstants.PAYMENT_OPTIONS[paymentMethod.type],
          disabled: false,
        });
      });
    }

    return result;
  }

  _getProcessingFee(paymentType) {
    const { transaction, isOutstandingBalance } = this.props;
    let paymentMethods;
    if (transaction) {
      paymentMethods = transaction.paymentMethods;
    }
    if (paymentMethods) {
      let totalCost = 0;
      for (const i in paymentMethods.available_payment_methods) {
        if (paymentMethods.available_payment_methods[i].type === paymentType) {
          if (
            isOutstandingBalance &&
            paymentMethods.available_payment_methods[i].fee_for_outstanding_balance
          )
            return paymentMethods.available_payment_methods[i].fee_for_outstanding_balance;
          totalCost = parseFloat(paymentMethods.available_payment_methods[i].total);
        }
      }
      return totalCost - parseFloat(paymentMethods.total_without_payment_fee);
    }
    return 0;
  }
  renderSavedCreditCards() {
    const optionKey = PaymentConstants.PAYMENT_METHODS.CREDIT_CARD;
    const { customerSavedCreditCards } = this.props;
    const creditCardOptions = customerSavedCreditCards.map((savedCard) => {
      let logoCode;
      let display;
      if (savedCard.brand === 'American Express') {
        logoCode = 'amex';
        display = 'AMEX';
      } else {
        logoCode = savedCard.brand.toLowerCase();
        display = savedCard.brand;
      }
      const logo = <CreditCardLogo type={logoCode} />;
      const subtitle = (
        <Fragment>
          <ReactSVG
            src="../../../../build/images/global/truncate.svg"
            wrapper="span"
            className="defaultRadio-title-truncate"
          />
          {savedCard.last_four_digits}
        </Fragment>
      );
      return (
        <ListOption
          key={`saved-${optionKey}-${savedCard.id}`}
          value={`saved-${optionKey}-${savedCard.id}`}
          active=""
          onChange={this._setPaymentType}
          title={display}
          subtitle={subtitle}
          logo={logo}
          contentHidden=""
        />
      );
    });
    return creditCardOptions;
  }

  renderSavedAdyenCreditCards() {
    const { adyenCheckout } = this.props;
    const optionKey = PaymentConstants.PAYMENT_METHODS.CREDIT_CARD;

    const { storedPaymentMethods } = adyenCheckout.paymentMethodsResponse;
    const creditCardOptions = storedPaymentMethods.map((savedCard) => {
      const display = savedCard.name;
      let logoCode = savedCard.brand.toLowerCase();
      if (savedCard.brand === 'mc') {
        logoCode = 'mastercard';
      }
      const logo = <CreditCardLogo type={logoCode} />;
      const subtitle = (
        <Fragment>
          <ReactSVG
            src="../../../../build/images/global/truncate.svg"
            wrapper="span"
            className="defaultRadio-title-truncate"
          />
          {savedCard.lastFour}
        </Fragment>
      );
      return (
        <ListOption
          key={`saved-${optionKey}-${savedCard.id}`}
          value={`saved-${optionKey}-${savedCard.id}`}
          active=""
          onChange={this._setPaymentType}
          title={display}
          subtitle={subtitle}
          logo={logo}
          contentHidden=""
        />
      );
    });

    return creditCardOptions;
  }

  renderSavedPlaidAccounts() {
    const optionKey = PaymentConstants.PAYMENT_METHODS.DIRECT_DEBIT;
    const { customerSavedPlaidAccounts } = this.props;
    const logo = <ReactSVG src="../../../../build/images/payment-methods/wire.svg" />;
    const plaidListOptions = customerSavedPlaidAccounts.map((account) => {
      const subtitle = (
        <Fragment>
          <ReactSVG
            src="../../../../build/images/global/truncate.svg"
            wrapper="span"
            className="defaultRadio-title-truncate"
          />
          {account.mask}
        </Fragment>
      );
      return (
        <ListOption
          key={`${optionKey}-${account.id}`}
          value={`saved-${optionKey}-${account.id}`}
          active=""
          onChange={this._setPaymentType}
          title={account.institution}
          subtitle={subtitle}
          logo={logo}
          contentHidden=""
        />
      );
    });
    return plaidListOptions;
  }

  renderSavedPaymentMethods() {
    const {
      customerSavedPlaidAccounts,
      customerSavedCreditCards,
      customerSavedPaymentLoading,
      transaction,
      adyenCheckout,
      adyenAction,
      transactionCCPaymentGateway,
    } = this.props;
    const paymentOptions = this._getPaymentOptions(transaction);
    const hideSavedACH = true;
    const { storedPaymentMethods: adyenSavedCreditCards = [] } =
      (adyenCheckout && adyenCheckout.paymentMethodsResponse) || {};
    const showAdyenSavedCreditCards =
      transactionCCPaymentGateway === PaymentConstants.CREDIT_CARD_GATEWAY.ADYEN &&
      adyenSavedCreditCards &&
      adyenSavedCreditCards.length > 0;
    if (customerSavedPaymentLoading) {
      return <Spinner />;
    }
    const optionsArray = paymentOptions.reduce((array, paymentOption) => {
      if (paymentOption.value === PaymentConstants.PAYMENT_METHODS.CREDIT_CARD) {
        if (showAdyenSavedCreditCards) {
          array.push(this.renderSavedAdyenCreditCards());
        } else if (
          transactionCCPaymentGateway === PaymentConstants.CREDIT_CARD_GATEWAY.PAYPAL &&
          customerSavedCreditCards &&
          customerSavedCreditCards.length > 0
        ) {
          array.push(this.renderSavedCreditCards());
        }
      } else if (
        !hideSavedACH &&
        paymentOption.value === PaymentConstants.PAYMENT_METHODS.DIRECT_DEBIT &&
        customerSavedPlaidAccounts &&
        customerSavedPlaidAccounts.length > 0
      ) {
        array.push(this.renderSavedPlaidAccounts());
      }
      return array;
    }, []);
    if (optionsArray.length > 0) {
      return (
        <Fragment>
          <h3 className="checkout-subheading">Saved payment method</h3>
          <div className="listOptions listOptions-saved" data-tracking-subsection="payment-method">
            {optionsArray}
          </div>
          {showAdyenSavedCreditCards && (
            <AdyenActionCard adyenAction={adyenAction} adyenCheckout={adyenCheckout} />
          )}
        </Fragment>
      );
    }
  }

  renderWireDetails() {
    const {
      wireDetails,
      handleSubmit,
      transaction,
      setShowWireForm,
      form,
      'buyerBankDetails.bankCountry': bankCountry,
      'buyerBankDetails.bankState': bankState,
      'buyerBankDetails.bankName': bankName,
      'buyerBankDetails.accountName': accountName,
      'buyerBankDetails.thirdPartyCheckbox': thirdPartyCheckbox,
      abTests,
    } = this.props;
    let { showWireForm } = this.props;
    if (abTests && abTests.buyer_bank_modal) {
      showWireForm = !wireDetails || showWireForm;
    } else {
      showWireForm = !wireDetails;
    }
    const StepProgress = () => (
      <div className="stepProgress grid-col">
        <div
          className={classnames('stepProgress-item', {
            'is-active': !!showWireForm,
          })}
          onClick={() => setShowWireForm()}
          role="presentation"
          data-tracking-name="bankDetails"
        >
          <span>Bank Details</span>
          <span className="stepProgress-iconHolder">
            {!showWireForm ? <Icon className="stepProgress-icon" name="ui-tick" /> : null}
          </span>
        </div>
        <div
          className={classnames('stepProgress-item', {
            'is-active': !showWireForm,
          })}
          role="presentation"
          data-tracking-name="wireTransferDetails"
        >
          <span>Wire Transfer Details</span>
        </div>
      </div>
    );
    if (showWireForm) {
      if (!(abTests && abTests.buyer_bank_modal)) {
        return (
          <FormSection name="buyerBankDetails">
            <div className={`listOptions-content`}>
              <WireTransferContainer
                handleSubmit={handleSubmit}
                transId={transaction.id}
                formName={form}
                bankCountry={bankCountry}
                bankState={bankState}
                bankName={bankName}
                accountName={accountName}
                thirdPartyCheckbox={thirdPartyCheckbox}
              />
            </div>
          </FormSection>
        );
      }
      return (
        <FormSection name="buyerBankDetails">
          <StepProgress />
          <div className={`listOptions-content`}>
            <div className="checkout-card-content">
              <WireTransferContainer
                onExit={() => this.setState({ showModal: false })}
                handleSubmit={handleSubmit}
                transId={transaction.id}
                formName={form}
                bankCountry={bankCountry}
                bankState={bankState}
                bankName={bankName}
                accountName={accountName}
                thirdPartyCheckbox={thirdPartyCheckbox}
              />
            </div>
          </div>
        </FormSection>
      );
    }

    // Format for CAD account
    if (wireDetails && wireDetails.credit_account_number === '000021416197') {
      wireDetails.credit_account_number = wireDetails.credit_account_number.replace(
        /(\d{5})(\d{7})/,
        '$1-$2'
      );
    }
    if (!(abTests && abTests.buyer_bank_modal)) {
      return (
        <div className={`listOptions-content`}>
          <WireTransferDetail
            wireDetails={wireDetails}
            handleSubmit={handleSubmit}
            transaction={transaction}
            form={form}
          />
        </div>
      );
    }

    return (
      <Fragment>
        <StepProgress />
        <div className={`listOptions-content`}>
          <div className="checkout-card-content">
            <WireTransferDetail
              wireDetails={wireDetails}
              handleSubmit={handleSubmit}
              transaction={transaction}
              form={form}
            />
          </div>
          <div className="checkout-card-wire-btnWrapper">
            <a
              type="button"
              target="_blank"
              className="btn btn--secondary btn--hollow btn--clear"
              href={`${window.config.www_base_url}/transaction/${transaction.id}/wire-instructions`}
            >
              Print
            </a>
            <a
              type="button"
              role="presentation"
              onClick={() => setShowWireForm()}
              className="checkout-card-wire-btn checkout-card-wire-btn--rightHeavy btn btn--secondary btn--hollow"
            >
              Back
            </a>
            <a
              type="button"
              role="presentation"
              onClick={() => this.setState({ showModal: false })}
              className="checkout-card-wire-btn btn btn--secondary"
            >
              Submit
            </a>
          </div>
        </div>
      </Fragment>
    );
  }

  renderCheckDetails() {
    const { checkDetails, transaction } = this.props;

    return (
      <div className="grid">
        <div className="listOptions-content-item grid-col">
          <h4 className="listOptions-content-title">Check Beneficiary Address</h4>
          <span>{checkDetails.check_beneficiary_address}</span>
        </div>
        <div className="listOptions-content-item grid-col">
          <h4 className="listOptions-content-title">Additional Notes</h4>
          <span>
            Write <strong>Transaction #{transaction.id}</strong> on the back of your check. Returned
            checks are charged a $25.00 Fee
          </span>
        </div>
      </div>
    );
  }

  renderCreditCardForm() {
    const {
      customerDetails,
      transaction,
      adyenCCAvailable,
      adyenAction,
      adyenCheckout,
      transactionCCPaymentGateway,
    } = this.props;
    if (transactionCCPaymentGateway === PaymentConstants.CREDIT_CARD_GATEWAY.ADYEN) {
      return (
        <React.Fragment>
          <AdyenForm
            transaction={transaction}
            adyenCCAvailable={adyenCCAvailable}
            adyenAction={adyenAction}
            checkout={adyenCheckout}
          />
        </React.Fragment>
      );
    }
    return (
      <CreditCardFieldset
        customerDetails={customerDetails}
        currency={apiToCurrencyMapping[transaction.currency]}
      />
    );
  }

  renderWire(wireDetailsHidden, wireActive) {
    const {
      transaction,
      wireDetails,
      setShowWireForm,
      handleSubmit,
      form,
      launchPlaidClient,
      plaidClient,
      abTests,
    } = this.props;
    const paymentOptions = this._getPaymentOptions(transaction);
    const directDebitOption =
      paymentOptions.filter((p) => p.value === PaymentConstants.PAYMENT_METHODS.DIRECT_DEBIT)
        .length > 0;
    const processingFee = this._getProcessingFee(PaymentConstants.PAYMENT_METHODS.WIRE_TRANSFER);
    const title = gettext('Wire Transfer');
    const subtitle =
      processingFee !== 0 ? (
        <span>
          {gettext('Adds ')}
          <Currency
            code={getCurrencyTagFromApiCurrency(transaction.currency)}
            amount={this._getProcessingFee(PaymentConstants.PAYMENT_METHODS.WIRE_TRANSFER)}
            withTag
          />
          {gettext(' Fee')}
        </span>
      ) : (
        gettext('FREE')
      );
    const logo = <ReactSVG src="../../../../build/images/payment-methods/wire.svg" />;
    let content;
    if (!(abTests && abTests.buyer_bank_modal)) {
      return (
        <ListOption
          key="wire"
          value={PaymentConstants.PAYMENT_METHODS.WIRE_TRANSFER}
          active={wireActive}
          onChange={this._setPaymentType}
          title={title}
          subtitle={subtitle}
          logo={logo}
          content={this.renderWireDetails()}
          contentHidden={wireDetailsHidden}
        />
      );
    }
    if (!wireDetails) {
      content = (
        <div className="listOptions-content">
          <h4 className="listOptions-content-title">
            {gettext(
              'Escrow.com uses Plaid to verify your bank account details so that we can process your ACH debit for this transaction.'
            )}
          </h4>
          <p>
            {gettext(
              'Link the account you want to pay with and we will debit the payment from this account at no additional fee.'
            )}
          </p>
          <p>
            {gettext(
              'Alternatively, we can provide the wire transfer instructions for you to manually process the payment with your bank (bank fees may apply).'
            )}
          </p>
          <div className="listOptions-btnWrapper">
            <button
              onClick={launchPlaidClient}
              type="button"
              className={classnames('btn', 'btn--secondary', {
                'is-hidden': !directDebitOption && plaidClient,
              })}
              data-e2e-target="get-wire-details-submit-btn"
              data-tracking-name={'wire-details-submit'}
            >
              {gettext('Instant Transfer')}
            </button>
            <button
              onClick={() => this.setState({ showModal: true })}
              type="button"
              className="btn btn--secondary btn--hollow"
              data-e2e-target="get-wire-details-submit-btn"
              data-tracking-name={'wire-details-submit'}
            >
              {gettext('Get Transfer Details')}
            </button>
          </div>
        </div>
      );
    } else {
      content = (
        <div className="listOptions-content">
          <WireTransferDetail
            wireDetails={wireDetails}
            handleSubmit={handleSubmit}
            transaction={transaction}
            form={form}
          />
          <div className="listOptions-btnWrapper">
            <button
              onClick={launchPlaidClient}
              className={classnames('btn', 'btn--secondary', {
                'is-hidden': !directDebitOption && plaidClient,
              })}
              type="button"
              data-e2e-target="get-wire-details-submit-btn"
              data-tracking-name={'wire-details-submit'}
            >
              {gettext('Do Instant Transfer Instead')}
            </button>
            <button
              onClick={() => {
                this.setState({ showModal: true });
                setShowWireForm();
              }}
              type="button"
              className="btn btn--secondary btn--hollow"
              data-e2e-target="get-wire-details-submit-btn"
              data-tracking-name={'wire-details-submit'}
            >
              {gettext('Change Details')}
            </button>
          </div>
        </div>
      );
    }
    return (
      <Fragment>
        <Modal
          isOpen={this.state.showModal}
          paddingSize="large"
          onBackdropClick={() => this.setState({ showModal: !this.state.showModal })}
          targetElementId="spa"
          modalSize="medium"
          enableBackdropClick
        >
          <h3 className="checkout-subheading">Get Bank Transfer Details</h3>
          {wireActive && this.renderWireDetails()}
        </Modal>
        <ListOption
          key="wire"
          value={PaymentConstants.PAYMENT_METHODS.WIRE_TRANSFER}
          active={wireActive}
          onChange={this._setPaymentType}
          title={title}
          subtitle={subtitle}
          logo={logo}
          content={content}
          contentHidden={wireDetailsHidden}
        />
      </Fragment>
    );
  }

  renderCreditCard(ccDetailsHidden, ccActive) {
    const { transaction } = this.props;
    const processingFee = this._getProcessingFee(PaymentConstants.PAYMENT_METHODS.CREDIT_CARD);

    const title = gettext('Credit or Debit Card');
    const subtitle =
      processingFee !== 0 ? (
        <span>
          {gettext('Adds ')}
          <Currency
            code={getCurrencyTagFromApiCurrency(transaction.currency)}
            amount={this._getProcessingFee(PaymentConstants.PAYMENT_METHODS.CREDIT_CARD)}
            withTag
          />
          {gettext(' Fee. ')}
          {gettext('Accepts Visa, MasterCard and AMEX')}
        </span>
      ) : (
        <span>
          {gettext('FREE. ')}
          {gettext('Accepts Visa, MasterCard and AMEX')}
        </span>
      );

    const logo = <CreditCardLogo type="general" />;
    return (
      <ListOption
        key="cc"
        value={PaymentConstants.PAYMENT_METHODS.CREDIT_CARD}
        active={ccActive}
        onChange={this._setPaymentType}
        title={title}
        subtitle={subtitle}
        logo={logo}
        content={this.renderCreditCardForm()}
        contentHidden={ccDetailsHidden}
      />
    );
  }

  renderPaypal(ppActive) {
    const { transaction } = this.props;
    const processingFee = this._getProcessingFee(PaymentConstants.PAYMENT_METHODS.PAYPAL);
    const logo = <ReactSVG src="../../../../build/images/payment-methods/paypal.svg" />;
    const title = gettext('Paypal');
    const subtitle =
      processingFee !== 0 ? (
        <span>
          {gettext('Adds ')}
          <Currency
            code={getCurrencyTagFromApiCurrency(transaction.currency)}
            amount={processingFee}
            withTag
          />
          {gettext(' Fee')}
        </span>
      ) : (
        gettext('FREE')
      );
    return (
      <ListOption
        key="pp"
        value={PaymentConstants.PAYMENT_METHODS.PAYPAL}
        active={ppActive}
        onChange={this._setPaymentType}
        title={title}
        subtitle={subtitle}
        logo={logo}
        contentHidden
      />
    );
  }

  renderPoli(poliActive) {
    const { transaction } = this.props;
    const processingFee = this._getProcessingFee(PaymentConstants.PAYMENT_METHODS.POLI);
    const logo = (
      <img alt="Pay with POLi" src="../../../../build/images/payment-methods/poli.png" />
    );
    const title = gettext(PaymentConstants.PAYMENT_METHODS.POLI);
    const subtitle =
      processingFee !== 0 ? (
        <span>
          {gettext('Adds ')}
          <Currency
            code={getCurrencyTagFromApiCurrency(transaction.currency)}
            amount={this._getProcessingFee(PaymentConstants.PAYMENT_METHODS.POLI)}
            withTag
          />
          {gettext(' Fee')}
        </span>
      ) : (
        <span className="listOptions-radio-note">({gettext('FREE')})</span>
      );

    return (
      <ListOption
        key="poli"
        value={PaymentConstants.PAYMENT_METHODS.POLI}
        active={poliActive}
        onChange={this._setPaymentType}
        title={title}
        subtitle={subtitle}
        logo={logo}
        contentHidden
      />
    );
  }

  renderCheck(checkDetailsHidden, checkActive) {
    const { checkDetails } = this.props;
    if (!checkDetails) {
      return <Spinner />;
    }
    const title = gettext('Check');
    const subtitle = gettext('FREE');
    const logo = <ReactSVG src="../../../../build/images/payment-methods/check.svg" />;
    return (
      <ListOption
        key="check"
        value={PaymentConstants.PAYMENT_METHODS.CHECK}
        active={checkActive}
        onChange={this._setPaymentType}
        title={title}
        subtitle={subtitle}
        logo={logo}
        contentHidden={checkDetailsHidden}
        content={this.renderCheckDetails()}
      />
    );
  }

  renderBanner() {
    const { transaction } = this.props;

    return transaction.currency === 'usd' &&
      transaction.paymentMethods.total_without_payment_fee <= 5000 ? (
      <Alert
        variant="outlined"
        severity="warning"
        sx={{ bgcolor: 'background.paper', marginTop: '14px', marginBottom: '14px' }}
      >
        <Typography
          sx={{ fontWeight: 500, fontSize: '16px', marginBottom: '4px', fontFamily: 'Roboto' }}
        >
          Paying via Credit Card?
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '16px', fontFamily: 'Roboto' }}>
          Contact support@escrow.com requesting card payment{' '}
          {transaction && transaction.id
            ? `for TID ${transaction.id}`
            : 'including your 8-digit transaction ID'}{' '}
          and we'll email you an online invoice to complete payment.
        </Typography>
      </Alert>
    ) : null;
  }

  renderPaymentOptions(paymentOptions) {
    const { paymentType, adyenCCAvailable, transactionCCPaymentGateway } = this.props;
    let paymentOptionHeader = gettext('Payment Summary');
    if (paymentOptions.length > 1) {
      paymentOptionHeader = gettext('Select Payment Method');
    }

    let wireDetailsHidden = 'is-hidden';
    let wireActive = '';
    let checkDetailsHidden = 'is-hidden';
    let checkActive = '';
    let ccActive = '';
    let ccDetailsHidden = 'is-hidden';
    let ppActive = '';
    let poliActive = '';
    if (paymentType === PaymentConstants.PAYMENT_METHODS.WIRE_TRANSFER) {
      wireDetailsHidden = '';
      wireActive = 'is-active';
    } else if (paymentType === PaymentConstants.PAYMENT_METHODS.CHECK) {
      checkDetailsHidden = '';
      checkActive = 'is-active';
    } else if (paymentType === PaymentConstants.PAYMENT_METHODS.CREDIT_CARD) {
      ccActive = 'is-active';
      ccDetailsHidden = '';
    } else if (paymentType === PaymentConstants.PAYMENT_METHODS.PAYPAL) {
      ppActive = 'is-active';
    } else if (paymentType === PaymentConstants.PAYMENT_METHODS.POLI) {
      poliActive = 'is-active';
    }

    const optionsArray = [];
    paymentOptions.map((paymentOption) => {
      if (paymentOption.value === PaymentConstants.PAYMENT_METHODS.WIRE_TRANSFER) {
        optionsArray.push(this.renderWire(wireDetailsHidden, wireActive));
      } else if (paymentOption.value === PaymentConstants.PAYMENT_METHODS.CHECK) {
        optionsArray.push(this.renderCheck(checkDetailsHidden, checkActive));
      } else if (paymentOption.value === PaymentConstants.PAYMENT_METHODS.CREDIT_CARD) {
        if (
          !(
            transactionCCPaymentGateway === PaymentConstants.CREDIT_CARD_GATEWAY.ADYEN &&
            !adyenCCAvailable
          )
        ) {
          optionsArray.push(this.renderCreditCard(ccDetailsHidden, ccActive));
        }
      } else if (paymentOption.value === PaymentConstants.PAYMENT_METHODS.PAYPAL) {
        optionsArray.push(this.renderPaypal(ppActive));
      } else if (paymentOption.value === PaymentConstants.PAYMENT_METHODS.POLI) {
        optionsArray.push(this.renderPoli(poliActive));
      }
    });

    return (
      <div>
        <h3 className="checkout-heading">{paymentOptionHeader}</h3>
        {this.renderBanner()}
        {this.renderSavedPaymentMethods()}
        <h3 className="checkout-subheading">Add a New Payment Method</h3>
        <div className="listOptions" data-tracking-subsection="payment-method">
          {optionsArray}
        </div>
        <div className="checkout-paymentMethod field-label is-hidden">
          <span>
            {gettext('Pay via wire transfer')}{' '}
            <a href="/support/faqs/what-is-a-wire-transfer" target="_blank">
              (What is a wire?)
            </a>
          </span>
        </div>
      </div>
    );
  }

  renderTermsCheckbox() {
    const { submitting, submissionError } = this.props;
    return (
      <div className="checkout-terms">
        {submissionError && (
          <div className="checkout-submit-error">
            <FormError error={submissionError} />
          </div>
        )}
        <span className="checkout-terms-inner">
          <Field
            name="acceptTerms"
            label={
              <div>
                {gettext('I agree to terms of this transaction and ')}
                <A
                  link={{
                    type: 'internal',
                    route: '/legal',
                    newTab: true,
                  }}
                  className="checkout-terms-link"
                >
                  {gettext('General Escrow Instructions')}
                </A>
              </div>
            }
            component={Checkbox}
            disabled={submitting}
            validate={this.validators.acceptTerms}
          />
        </span>
      </div>
    );
  }
  render() {
    const {
      customerDetails,
      customerSavedCreditCardsLoading,
      fetchErrors,
      form,
      handleSubmit,
      paymentType,
      submitting,
      transaction,
      wireDetails,
      abTests,
      isAdyenValid,
      transactionCCPaymentGateway,
    } = this.props;
    const { showModal } = this.state;

    if (customerSavedCreditCardsLoading) {
      return <Spinner />;
    } else if (!transaction || !transaction.paymentMethods || !customerDetails) {
      return fetchErrors && wireDetails ? <FormError error={fetchErrors} /> : <Spinner />;
    }

    if (
      transaction &&
      transaction.paymentMethods &&
      transaction.paymentMethods.selected_payment_method
    ) {
      window.location.assign(`${window.config.www_base_url}/transaction/${transaction.id}`);
    }

    const paymentOptions = this._getPaymentOptions(transaction);
    const processingFee = this._getProcessingFee(paymentType);
    const transactionTotal =
      parseFloat(transaction.paymentMethods.total_without_payment_fee) + processingFee;
    let submitLabelAction = gettext('Continue');

    if (
      paymentType === PaymentConstants.PAYMENT_METHODS.CREDIT_CARD ||
      paymentType === PaymentConstants.PAYMENT_METHODS.PAYPAL
    ) {
      submitLabelAction = (
        <span>
          {'Pay '}
          <Currency
            code={getCurrencyTagFromApiCurrency(transaction.currency)}
            amount={transactionTotal}
            withTag
          />
        </span>
      );
    } else if (
      paymentType === PaymentConstants.PAYMENT_METHODS.WIRE_TRANSFER ||
      paymentType === PaymentConstants.PAYMENT_METHODS.CHECK
    ) {
      submitLabelAction = gettext('Mark Payment as Sent');
    }

    let disableSubmitButton;
    if (abTests && abTests.buyer_bank_modal) {
      disableSubmitButton =
        showModal ||
        (!wireDetails && paymentType === PaymentConstants.PAYMENT_METHODS.WIRE_TRANSFER);
    } else {
      disableSubmitButton =
        !wireDetails && paymentType === PaymentConstants.PAYMENT_METHODS.WIRE_TRANSFER;
    }
    const disableAgreeTerms =
      !wireDetails && paymentType === PaymentConstants.PAYMENT_METHODS.WIRE_TRANSFER;
    disableSubmitButton =
      disableAgreeTerms ||
      (paymentType === PaymentConstants.PAYMENT_METHODS.CREDIT_CARD &&
        transactionCCPaymentGateway === PaymentConstants.CREDIT_CARD_GATEWAY.ADYEN &&
        !isAdyenValid) ||
      disableSubmitButton;

    return (
      <form name={form} onSubmit={handleSubmit(bindRoutineToReduxForm(selectPaymentMethodRoutine))}>
        <div
          className={classnames('checkout-card', 'card', {
            'is-disabled': submitting,
          })}
          ref={(section) => {
            this.Card = section;
          }}
        >
          <div className="checkout-card-content checkout-card-content--light">
            <h3 className="checkout-heading">{gettext('Order Summary')}</h3>
            <TransactionSummaryContainer
              transaction={transaction}
              payBy={paymentType}
              viewerPartyEmail={customerDetails.email}
              viewerPartyRole={TransactionConstants.TRANSACTION_ROLES.BUYER}
              hideTotal={false}
            />
          </div>
        </div>
        <div
          className={classnames('checkout-card checkout-card-secondary', 'card', {
            'is-disabled': submitting,
          })}
          ref={(section) => {
            this.Card = section;
          }}
        >
          <div className="checkout-card-content checkout-card-content--light">
            {this.renderPaymentOptions(paymentOptions)}
            {!disableAgreeTerms && this.renderTermsCheckbox()}
            <div className="checkout-actions">
              <div className="checkout-actions-item checkout-actions-item--secondary" />
              <div className="checkout-actions-item checkout-actions-item--primary">
                <button
                  type="submit"
                  className="checkout-card-btn btn btn--secondary"
                  data-e2e-target="checkout-payment-agree-btn"
                  disabled={disableSubmitButton || submitting}
                  data-tracking-name="agree"
                >
                  {!disableSubmitButton && submitting ? <Spinner /> : submitLabelAction}
                </button>
              </div>
              <div className="checkout-actions-item" />
            </div>
          </div>
        </div>
      </form>
    );
  }
}

const PaymentsReduxForm = reduxForm({
  form: PaymentConstants.PAYMENT_FORM,
  enableReinitialize: true,
})(
  formValues(
    'paymentType',
    'buyerBankDetails.bankCountry',
    'buyerBankDetails.bankState',
    'buyerBankDetails.bankName',
    'buyerBankDetails.accountName',
    'buyerBankDetails.thirdPartyCheckbox'
  )(PaymentForm)
);

export default PaymentsReduxForm;
