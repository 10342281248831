import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Code } from 'spa/components/Code';
import HintBanner from 'spa/components/HintBanner';
import PrimaryLink from 'spa/components/PrimaryLink';
import AccentedText from 'spa/components/AccentedText';
import { Section, SectionTitle, SectionSubTitle, SectionDesc } from 'spa/components/Section';
import A from 'spa/components/A';

const Webhooks = () => (
  <div className="integrationsPortal-content-landing">
    <div className="grid">
      <div className="grid-col grid-col--desktopSmall-9">
        <h1 className="integrationsPortal-title">API Guide :: Webhooks</h1>
        <Section>
          <SectionTitle>Using webhooks</SectionTitle>
          <SectionDesc>
            By providing a HTTP endpoint that accepts POST requests, Escrow.com will send you
            updates via webhook at key points during a transaction.
          </SectionDesc>
          <SectionDesc>
            Please note that Escrow.com strongly recommends verifying any data received via webhook
            by fetching related data such as by using the
            <A link={{ type: 'internal', route: '/api/docs/fetch-transaction' }}>
              {' '}
              fetch transaction
            </A>{' '}
            endpoint before taking actions based on webhook updates to ensure that the update was
            sent from Escrow.com.
          </SectionDesc>
        </Section>
        <Section>
          <SectionSubTitle>Adding a webhook</SectionSubTitle>
          <SectionDesc>
            You can add a webhook by making a POST request with your own URL you would like to be
            called, as such.
          </SectionDesc>
          <Tabs
            className="simpleTabs integrationsPortal-codeSample"
            selectedTabClassName="is-activeTab"
          >
            <TabList className="simpleTabs-nav">
              <Tab className="simpleTabs-nav-item">cURL</Tab>
              <Tab className="simpleTabs-nav-item">Python</Tab>
              <Tab className="simpleTabs-nav-item">Ruby</Tab>
              <Tab className="simpleTabs-nav-item">PHP</Tab>
              <Tab className="simpleTabs-nav-item">C#</Tab>
            </TabList>
            <TabPanel className="simpleTabs-content">
              <Code language="shell">
                {`curl "https://api.escrow.com/2017-09-01/customer/me/webhook" \\
    -X POST \\
    -u "email-address:your-api-key" \\
    -H "Content-Type: application/json" \\
    -d '
{
    "url": "https://chrispwill.com/my-escrow-webhook"
}'
`}
              </Code>
            </TabPanel>
            <TabPanel>
              <Code language="python">
                {`import requests

requests.post(
    'https://api.escrow.com/2017-09-01/customer/me/webhook',
    auth=('email address', 'api-key'),
    json={
      "url": "https://chrispwill.com/my-escrow-webhook"
    },
)
`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="ruby">
                {`require 'net/http'
require 'json'

url = URI("https://api.escrow.com/2017-09-01/customer/me/webook")

http = Net::HTTP.new(url.host, url.port).tap {|http| http.use_ssl = true}

request = Net::HTTP::Post.new(url.request_uri).tap {|request|
  request["content-type"] = 'application/json'
  request.basic_auth("email-address", "api-key")
  request.body = {
    url: "https://chrispwill.com/my-escrow-webhook"
  }.to_json
}

response = http.request(request)
puts response.body`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="php">
                {`<?php
$curl = curl_init();
curl_setopt_array($curl, array(
    CURLOPT_URL => 'https://api.escrow.com/2017-09-01/customer/me/webhook',
    CURLOPT_RETURNTRANSFER => 1,
    CURLOPT_USERPWD => 'email-address:your-password',
    CURLOPT_HTTPHEADER => array(
        'Content-Type: application/json'
    ),
    CURLOPT_POSTFIELDS => json_encode(
        array(
            'url' => 'https://chrispwill.com/my-escrow-webhook',
        )
    )
));

$output = curl_exec($curl);
echo $output;
curl_close($curl);
?>`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="cs">
                {`using System;
using System.Text;
using System.Net.Http;
using System.Net.Http.Headers;
using Newtonsoft.Json;

namespace EscrowApiTest
{
    class Program
    {
        static void Main(string[] args)
        {
            HttpClient client = new HttpClient();
            var auth = Encoding.ASCII.GetBytes("email-address:api-key");
            client.DefaultRequestHeaders.Authorization = new AuthenticationHeaderValue(
                "Basic", Convert.ToBase64String(auth));
            client.DefaultRequestHeaders.Accept.Add(
                new MediaTypeWithQualityHeaderValue("application/json"));
            var body = new
            {
                url = "https://examples.com/webhook-page",
            };
            var request = new HttpRequestMessage()
            {
                RequestUri = new Uri("https://api.escrow.com/2017-09-01/customer/me/webhook"),
                Method = HttpMethod.Post,
                Content = new StringContent(
                    JsonConvert.SerializeObject(body), Encoding.UTF8, "application/json")
            };
            HttpResponseMessage response = client.SendAsync(request).Result;
            Console.WriteLine(response.Content.ReadAsStringAsync().Result);
        }
    }
}`}
              </Code>
            </TabPanel>
          </Tabs>

          <SectionDesc>
            The response body will include a unique ID for the created webhook. You can use this ID
            to delete the webhook in future.
          </SectionDesc>

          <SectionSubTitle>Example Response</SectionSubTitle>
          <Code language="json">
            {`{
    "id": 1234,
    "url": "https://chrispwill.com/my-escrow-webhook"
}`}
          </Code>
        </Section>

        <Section>
          <SectionSubTitle>Getting current webhooks</SectionSubTitle>
          <SectionDesc>
            To see all of the webhooks you have created, can make a GET request to the same resource
            at <AccentedText>https://api.escrow.com/2017-09-01/customer/me/webhook</AccentedText>
          </SectionDesc>

          <SectionSubTitle>Example Response</SectionSubTitle>
          <Code language="json">
            {`{
    "webhooks": [
      {
        "id": 1234,
        "url": "https://chrispwill.com/my-escrow-webhook"
      },
      {
        "id": 1235,
        "url": "https://escrow-webhook.com/my-escrow-webhook"
      },
      {
        "id": 1236,
        "url": "https://sample.escrow.com/sample.escrow-webhook"
      }
    ]
}`}
          </Code>
        </Section>

        <Section>
          <SectionSubTitle>Deleting a webhook</SectionSubTitle>
          <SectionDesc>
            To delete a webhook, make a DELETE request to the webhook resource, using the unique
            webhook ID found by using the above GET endpoints.{' '}
            <AccentedText>https://api.escrow.com/2017-09-01/customer/me/webhook</AccentedText>
          </SectionDesc>

          <Tabs
            className="simpleTabs integrationsPortal-codeSample"
            selectedTabClassName="is-activeTab"
          >
            <TabList className="simpleTabs-nav">
              <Tab className="simpleTabs-nav-item">cURL</Tab>
              <Tab className="simpleTabs-nav-item">Python</Tab>
              <Tab className="simpleTabs-nav-item">Ruby</Tab>
              <Tab className="simpleTabs-nav-item">PHP</Tab>
              <Tab className="simpleTabs-nav-item">C#</Tab>
            </TabList>
            <TabPanel className="simpleTabs-content">
              <Code language="shell">
                {`curl "https://api.escrow.com/2017-09-01/customer/me/webhook/1234" \\
    -X DELETE \\
    -u "email-address:your-api-key" \\
    -H "Content-Type: application/json" \\
`}
              </Code>
            </TabPanel>
            <TabPanel>
              <Code language="python">
                {`import requests

requests.delete(
    'https://api.escrow.com/2017-09-01/customer/me/webhook/1234',
    auth=('email address', 'api-key'),
)
`}
              </Code>
            </TabPanel>
            <TabPanel>
              <Code language="ruby">
                {`require 'net/http'
require 'json'

uri = URI("https://api.escrow.com/2017-09-01/customer/me/webhook/1234")

http = Net::HTTP.new(uri.host, uri.port).tap {|http| http.use_ssl = true}

request = Net::HTTP::Delete.new(uri.request_uri).tap {|request|
  request["content-type"] = 'application/json'
  request.basic_auth("email-address", "api-key")
}

response = http.request(request)
puts response.body`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="php">
                {`<?php
$curl = curl_init();
curl_setopt_array($curl, array(
    CURLOPT_URL => 'https://api.escrow.com/2017-09-01/customer/me/webhook/1234',
    CURLOPT_RETURNTRANSFER => 1,
    CURLOPT_USERPWD => 'email-address:your-password',
    CURLOPT_HTTPHEADER => array(
        'Content-Type: application/json'
    ),
    CURLOPT_CUSTOMREQUEST => 'DELETE',
));

$output = curl_exec($curl);
echo $output;
curl_close($curl);
?>`}
              </Code>
            </TabPanel>
            <TabPanel className="simpleTabs-content">
              <Code language="cs">
                {`using System;
using System.Text;
using System.Net.Http;
using System.Net.Http.Headers;

namespace EscrowApiTest
{
    class Program
    {
        static void Main(string[] args)
        {
            HttpClient client = new HttpClient();
            var auth = Encoding.ASCII.GetBytes("email-address:api-key");
            client.DefaultRequestHeaders.Authorization = new AuthenticationHeaderValue(
                "Basic", Convert.ToBase64String(auth));
            client.DefaultRequestHeaders.Accept.Add(
                new MediaTypeWithQualityHeaderValue("application/json"));
            var request = new HttpRequestMessage()
            {
                RequestUri = new Uri(
                    "https://api.escrow.com/2017-09-01/customer/me/webhook/1234"),
                Method = new HttpMethod("DELETE"),
            };
            HttpResponseMessage response = client.SendAsync(request).Result;
            Console.WriteLine(response.Content.ReadAsStringAsync().Result);
        }
    }
}`}
              </Code>
            </TabPanel>
          </Tabs>

          <SectionDesc>
            This will return a <AccentedText>204</AccentedText> status code on successful deletion.
            Deleted webhooks will no longer be called.
          </SectionDesc>
        </Section>

        <Section>
          <SectionSubTitle>Webhook behaviour</SectionSubTitle>
          <SectionDesc>
            When transactions change state, Escrow.com will make POST requests to all of your active
            webhooks. The body of the POST request that we will send is as shown:
          </SectionDesc>

          <SectionSubTitle>Example Webhook Call</SectionSubTitle>
          <Code language="json">
            {`{
    "event": "payment_approved",
    "event_type": "transaction",
    "transaction_id": 12345
}`}
          </Code>

          <HintBanner
            className="integrationsPortal-hintBanner"
            title="Note"
            desc="Escrow.com has a 10 second timeout on your webhook requests. Webhooks
              will not be retried if the request fails."
            icon="ui-alert-circle"
          />
        </Section>

        <Section>
          <SectionSubTitle>Webhook events</SectionSubTitle>
          <SectionDesc>This table shows the possible events that trigger webhooks.</SectionDesc>

          <table className="integrationsPortal-section-table apiTable">
            <thead className="apiTable-header">
              <tr className="apiTable-row">
                <th className="apiTable-cell">event_type</th>
                <th className="apiTable-cell">event</th>
                <th className="apiTable-cell">description</th>
              </tr>
            </thead>
            <tbody>
              <tr className="apiTable-row">
                <td className="apiTable-cell">
                  <AccentedText>transaction</AccentedText>
                </td>
                <td className="apiTable-cell">
                  <AccentedText>create</AccentedText>
                </td>
                <td className="apiTable-cell">A new transaction has been created.</td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell">
                  <AccentedText>transaction</AccentedText>
                </td>
                <td className="apiTable-cell">
                  <AccentedText>agree</AccentedText>
                </td>
                <td className="apiTable-cell">All parties have agreed to the transaction.</td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell">
                  <AccentedText>transaction</AccentedText>
                </td>
                <td className="apiTable-cell">
                  <AccentedText>party_verification_submitted</AccentedText>
                </td>
                <td className="apiTable-cell">
                  A party has submitted their verification for review.
                </td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell">
                  <AccentedText>transaction</AccentedText>
                </td>
                <td className="apiTable-cell">
                  <AccentedText>party_verification_approved</AccentedText>
                </td>
                <td className="apiTable-cell">
                  A party has had their verification reviewed and approved.
                </td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell">
                  <AccentedText>transaction</AccentedText>
                </td>
                <td className="apiTable-cell">
                  <AccentedText>party_verification_rejected</AccentedText>
                </td>
                <td className="apiTable-cell">
                  A party has had their verification reviewed and rejected.
                </td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell">
                  <AccentedText>transaction</AccentedText>
                </td>
                <td className="apiTable-cell">
                  <AccentedText>payment_approved</AccentedText>
                </td>
                <td className="apiTable-cell">
                  Escrow.com has approved the payment for the transaction and the goods may now be
                  shipped by the seller.
                </td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell">
                  <AccentedText>transaction</AccentedText>
                </td>
                <td className="apiTable-cell">
                  <AccentedText>payment_rejected</AccentedText>
                </td>
                <td className="apiTable-cell">
                  Escrow.com has rejected the payment for the transaction.
                </td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell">
                  <AccentedText>transaction</AccentedText>
                </td>
                <td className="apiTable-cell">
                  <AccentedText>payment_sent</AccentedText>
                </td>
                <td className="apiTable-cell">The buyer has sent payment to Escrow.com.</td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell">
                  <AccentedText>transaction</AccentedText>
                </td>
                <td className="apiTable-cell">
                  <AccentedText>payment_received</AccentedText>
                </td>
                <td className="apiTable-cell">Escrow.com has received payment from the buyer.</td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell">
                  <AccentedText>transaction</AccentedText>
                </td>
                <td className="apiTable-cell">
                  <AccentedText>payment_refunded</AccentedText>
                </td>
                <td className="apiTable-cell">Escrow.com has refunded a buyer&apos;s payment.</td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell">
                  <AccentedText>transaction</AccentedText>
                </td>
                <td className="apiTable-cell">
                  <AccentedText>payment_disbursed</AccentedText>
                </td>
                <td className="apiTable-cell">Escrow.com has disbursed payment to the seller.</td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell">
                  <AccentedText>transaction</AccentedText>
                </td>
                <td className="apiTable-cell">
                  <AccentedText>payment_rejected</AccentedText>
                </td>
                <td className="apiTable-cell">
                  Escrow.com has rejected the payment for the transaction.
                </td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell">
                  <AccentedText>transaction</AccentedText>
                </td>
                <td className="apiTable-cell">
                  <AccentedText>ship</AccentedText>
                </td>
                <td className="apiTable-cell">
                  The seller has indicated that the goods have been shipped.
                </td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell">
                  <AccentedText>transaction</AccentedText>
                </td>
                <td className="apiTable-cell">
                  <AccentedText>receive</AccentedText>
                </td>
                <td className="apiTable-cell">
                  The buyer has indicated that the goods have been received.
                </td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell">
                  <AccentedText>transaction</AccentedText>
                </td>
                <td className="apiTable-cell">
                  <AccentedText>accept</AccentedText>
                </td>
                <td className="apiTable-cell">
                  The buyer has indicated that the goods have been accepted.
                </td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell">
                  <AccentedText>transaction</AccentedText>
                </td>
                <td className="apiTable-cell">
                  <AccentedText>reject</AccentedText>
                </td>
                <td className="apiTable-cell">
                  The buyer has indicated that the goods have been rejected.
                </td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell">
                  <AccentedText>transaction</AccentedText>
                </td>
                <td className="apiTable-cell">
                  <AccentedText>ship_return</AccentedText>
                </td>
                <td className="apiTable-cell">
                  The buyer has indicated that the goods to be returned following rejection have
                  been shipped.
                </td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell">
                  <AccentedText>transaction</AccentedText>
                </td>
                <td className="apiTable-cell">
                  <AccentedText>receive_return</AccentedText>
                </td>
                <td className="apiTable-cell">
                  The seller has indicated that the goods to be returned following rejection have
                  been received.
                </td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell">
                  <AccentedText>transaction</AccentedText>
                </td>
                <td className="apiTable-cell">
                  <AccentedText>accept_return</AccentedText>
                </td>
                <td className="apiTable-cell">
                  The seller has indicated that the goods to be returned following rejection have
                  been accepted.
                </td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell">
                  <AccentedText>transaction</AccentedText>
                </td>
                <td className="apiTable-cell">
                  <AccentedText>reject_return</AccentedText>
                </td>
                <td className="apiTable-cell">
                  The seller has indicated that the goods to be returned following rejection have
                  been rejected.
                </td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell">
                  <AccentedText>transaction</AccentedText>
                </td>
                <td className="apiTable-cell">
                  <AccentedText>complete</AccentedText>
                </td>
                <td className="apiTable-cell">
                  All disbursements have been made to the seller, closing statements have been sent.
                  Escrow.com marked the transaction as complete.
                </td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell">
                  <AccentedText>transaction</AccentedText>
                </td>
                <td className="apiTable-cell">
                  <AccentedText>cancel</AccentedText>
                </td>
                <td className="apiTable-cell">Escrow.com has marked the payment as cancelled.</td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell">
                  <AccentedText>transaction</AccentedText>
                </td>
                <td className="apiTable-cell">
                  <AccentedText>offer_accepted</AccentedText>
                </td>
                <td className="apiTable-cell">
                  This is sent for Escrow Offer transactions, when an offer has been accepted.
                </td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell">
                  <AccentedText>transaction</AccentedText>
                </td>
                <td className="apiTable-cell">
                  <AccentedText>refund_resolved</AccentedText>
                </td>
                <td className="apiTable-cell">
                  This is sent when Escrow.com has approved to process a refund for a transaction.
                </td>
              </tr>
              <tr className="apiTable-row">
                <td className="apiTable-cell">
                  <AccentedText>transaction</AccentedText>
                </td>
                <td className="apiTable-cell">
                  <AccentedText>refund_rejected</AccentedText>
                </td>
                <td className="apiTable-cell">
                  This is sent when Escrow.com has rejected to process a refund for the transaction.
                </td>
              </tr>
            </tbody>
          </table>
        </Section>

        <footer className="integrationsPortal-footer">
          <h3 className="integrationsPortal-footer-title">{'Next steps'}</h3>
          <nav className="integrationsPortal-footer-steps">
            <div className="integrationsPortal-footer-step">
              <PrimaryLink
                reversed
                text="Full API reference Document"
                link={{
                  type: 'external',
                  route: window.config.api_docs_page,
                }}
              />
            </div>
          </nav>
        </footer>
      </div>
    </div>
  </div>
);

export default Webhooks;
