import deepcopy from 'clone';

import AuthRequired from 'spa/containers/AuthRequired';
import EmailVerificationRequired from 'spa/containers/EmailVerificationRequired';
import IntegrationsPortal from 'spa/containers/IntegrationsPortal';
import UserPage from 'spa/containers/UserPage';
import PartnerDashboard from 'spa/containers/PartnerDashboard';

import {
  OverviewPage,
  ReportsListPage,
  TransactionDetailsPage,
  TransactionListPage,
  UserDetailsPage,
  UserListPage,
} from 'spa/pages/PartnerDashboard';
import StartTransaction from 'spa/pages/StartTransaction';
import TransactionsPage from 'spa/pages/Transactions';
import NotificationSettings from 'spa/pages/NotificationSettings';
import SavedPaymentMethodsPage from 'spa/pages/SavedPaymentMethodsPage';
import TaxDetails from 'spa/pages/TaxDetails';
import APIIntegrationPage from 'spa/pages/APIIntegrationPage';
import AdditionalDocumentsPage from 'spa/pages/AdditionalDocumentsPage';
import PaymentVerificationInstructionsPage from 'spa/pages/PaymentVerification/Instructions';
import PaymentVerificationPage from 'spa/pages/PaymentVerification/Verification';
import PaymentVerificationSuccessPage from 'spa/pages/PaymentVerification/Success';
import WebhooksPage from 'spa/pages/WebhooksPage';
import ButtonsGetStartedPage from 'spa/pages/ButtonsGetStartedPage';
import LogosGetStartedPage from 'spa/pages/LogosGetStartedPage';
import ReferralLinksGetStartedPage from 'spa/pages/ReferralLinksGetStartedPage';
import ReferralLinksBannerPage from 'spa/pages/ReferralLinksBannerPage';
import GettingStartedPage from 'spa/pages/GettingStartedPage';
import APIGetStartedPage from 'spa/pages/APIGetStartedPage';
import WebhooksGetStartedPage from 'spa/pages/WebhooksGetStartedPage';
import * as EscrowOfferDocs from 'spa/pages/EscrowOfferDocs';
import EscrowPayDocs from 'spa/pages/EscrowPayDocs';
import EscrowIdVerifyDocs from 'spa/pages/EscrowIdVerifyDocs';
import FAQPage from 'spa/pages/FAQPage';
import { KYCPage, PartnerKYCPage } from 'spa/pages/KYCPage';
import LegalLandingPage from 'spa/pages/LegalLandingPage';
import GuitarOffers from 'spa/pages/GuitarOffers';
import CheckoutPage from 'spa/pages/CheckoutPage';
import CheckoutPlaidOAuthPage from 'spa/pages/CheckoutPage/CheckoutPlaidOAuthPage';
import { MakeAnOfferPage, OfferManagementPage } from 'spa/pages/OfferPages';
import CreditCardPaymentPage from 'spa/pages/CreditCardPaymentPage';
import * as APIGuides from 'spa/pages/APIGuides';
import * as DisbursementDetails from 'spa/pages/DisbursementDetails';
import * as IntegrationHelperGuides from 'spa/pages/IntegrationHelperGuides';
import * as Plugins from 'spa/pages/Plugins';
import { PaymentsPage, PaymentShortagesPage } from 'spa/pages/PaymentsPage';
import AdyenResultPage from 'spa/pages/PaymentsPage/AdyenResult';
import PaypalSuccessPage from 'spa/pages/PaymentsPage/PaypalSuccessPage';
import TransactionStatusPage from 'spa/pages/TransactionStatusPage';
import TwoFactorAuthenticationPage from 'spa/pages/TwoFactorAuthenticationPage';
import AuthenticationPage from 'spa/pages/AuthenticationPage';
import IntegrationsAuthPage from 'spa/pages/AuthenticationPage/IntegrationsAuthPage';
import EmailVerificationResultPage from 'spa/pages/EmailVerificationPage/EmailVerificationResultPage';
import EmailVerificationPage from 'spa/pages/EmailVerificationPage';
import TestAuthPage from 'spa/pages/TestAuthPage';
import TransactionProgressPage from 'spa/pages/TransactionProgressPage';
import ForgotPasswordPage from 'spa/features/reset-password/pages/ForgotPasswordPage';
import ResetPasswordPage from 'spa/features/reset-password/pages/ResetPasswordPage';
import UserTransactionDetailsPage from 'spa/features/transaction/pages/transaction-details/TransactionDetailsRoute';

import { routeFor } from './routeConfig';

function addPrefixToRoutes(routes, prefix) {
  const prefixedRoutes = deepcopy(routes);
  for (const route of prefixedRoutes) {
    if ('path' in route) {
      route.path = prefix + route.path;
    }
    if ('routes' in route) {
      route.routes = addPrefixToRoutes(route.routes, prefix);
    }
  }
  return prefixedRoutes;
}

const createRoutes = () => {
  const userPageRoutes = [
    {
      component: UserPage,
      path: '/account-info',
      routes: [
        {
          component: UserPage,
          path: '/account-info/disbursement-options',
          routes: [
            {
              path: routeFor('user_page_disbursement_details_ach'),
              exact: true,
              component: DisbursementDetails.ACHDisbursementDetails,
            },
            {
              path: routeFor('user_page_disbursement_details_international_wire'),
              exact: true,
              component: DisbursementDetails.InternationalWireDisbursementDetails,
            },
          ],
        },
        {
          path: routeFor('user_page_notification_settings'),
          exact: true,
          component: NotificationSettings,
        },
        {
          path: '/account-info/2fa',
          component: EmailVerificationRequired,
          routes: [
            {
              path: routeFor('user_page_2fa_configuration'),
              exact: true,
              component: TwoFactorAuthenticationPage,
            },
          ],
        },
        {
          path: routeFor('user_page_tax_details'),
          exact: true,
          component: TaxDetails,
        },
        {
          path: routeFor('user_page_additional_documents'),
          exact: true,
          component: AdditionalDocumentsPage,
        },
        {
          path: routeFor('user_page_payment_methods'),
          exact: true,
          component: SavedPaymentMethodsPage,
        },
      ],
    },
    {
      component: UserPage,
      path: '/transaction',
      routes: [
        {
          path: routeFor('user_page_start_transaction'),
          exact: true,
          component: StartTransaction,
        },
        {
          component: UserTransactionDetailsPage,
          exact: true,
          path: routeFor('transaction_details_page'),
        },
      ],
    },
    {
      component: EmailVerificationPage,
      exact: true,
      path: routeFor('email_verification'),
    },
    {
      path: routeFor('email_verification_result'),
      exact: true,
      component: EmailVerificationResultPage,
    },
  ];
  const integrationsPortalRoutes = [
    {
      component: IntegrationsPortal,
      path: '/integrations/portal',
      routes: [
        // logged out routes have to go first because react-router-config uses
        // a switch router and the AuthRequired node matches any of
        // /integration/portal
        {
          path: routeFor('integrations_faqs'),
          exact: true,
          component: FAQPage,
        },
        {
          component: AuthRequired,
          path: '/integrations/portal',
          routes: [
            {
              path: routeFor('integrations_getstarted'),
              exact: true,
              component: GettingStartedPage,
            },
            {
              path: routeFor('integrations_api'),
              exact: true,
              component: APIIntegrationPage,
            },
            {
              path: routeFor('integrations_api_getstarted'),
              exact: true,
              component: APIGetStartedPage,
            },
            {
              path: routeFor('integrations_webhooks'),
              exact: true,
              component: WebhooksPage,
            },
            {
              path: routeFor('integrations_webhooks_getstarted'),
              exact: true,
              component: WebhooksGetStartedPage,
            },
            {
              path: routeFor('integrations_buttons_getstarted'),
              exact: true,
              component: ButtonsGetStartedPage,
            },
            {
              path: routeFor('integrations_logos_getstarted'),
              exact: true,
              component: LogosGetStartedPage,
            },
            {
              path: routeFor('integrations_referrals_getstarted'),
              exact: true,
              component: ReferralLinksGetStartedPage,
            },
            {
              path: routeFor('integrations_referrals_banners'),
              exact: true,
              component: ReferralLinksBannerPage,
            },
          ],
        },
      ],
    },
    {
      component: IntegrationsPortal,
      path: '/api',
      routes: [
        {
          path: routeFor('api_guide_getting_started'),
          exact: true,
          component: APIGuides.GettingStarted,
        },
        {
          path: routeFor('api_guide_basics'),
          exact: true,
          component: APIGuides.APIBasics,
        },
        {
          path: routeFor('api_guide_create_transaction'),
          exact: true,
          component: APIGuides.CreateTransaction,
        },
        {
          path: routeFor('api_guide_list_transactions'),
          exact: true,
          component: APIGuides.ListTransactions,
        },
        {
          path: routeFor('api_guide_list_partner_transactions'),
          exact: true,
          component: APIGuides.ListPartnerTransactions,
        },
        {
          path: routeFor('api_guide_agree_transaction'),
          exact: true,
          component: APIGuides.AgreeTransaction,
        },
        {
          path: routeFor('api_guide_fund_transaction'),
          exact: true,
          component: APIGuides.FundTransaction,
        },
        {
          path: routeFor('api_guide_ship_transaction'),
          exact: true,
          component: APIGuides.ShipTransaction,
        },
        {
          path: routeFor('api_guide_cancel_transaction'),
          exact: true,
          component: APIGuides.CancelTransaction,
        },
        {
          path: routeFor('api_guide_disburse_transaction'),
          exact: true,
          component: APIGuides.DisburseTransaction,
        },
        {
          path: routeFor('api_guide_receive_items'),
          exact: true,
          component: APIGuides.ReceiveTransaction,
        },
        {
          path: routeFor('api_guide_accept_items'),
          exact: true,
          component: APIGuides.AcceptTransaction,
        },
        {
          path: routeFor('api_guide_reject_items'),
          exact: true,
          component: APIGuides.RejectTransaction,
        },
        {
          path: routeFor('api_guide_return_items'),
          exact: true,
          component: APIGuides.ShipReturnTransaction,
        },
        {
          path: routeFor('api_guide_receive_returned_items'),
          exact: true,
          component: APIGuides.ReceiveReturnTransaction,
        },
        {
          path: routeFor('api_guide_accept_returned_items'),
          exact: true,
          component: APIGuides.AcceptReturnTransaction,
        },
        {
          path: routeFor('api_guide_reject_returned_items'),
          exact: true,
          component: APIGuides.RejectReturnTransaction,
        },
        {
          path: routeFor('api_guide_create_customer'),
          exact: true,
          component: APIGuides.CreateCustomer,
        },
        {
          path: routeFor('api_guide_update_customer'),
          exact: true,
          component: APIGuides.UpdateCustomer,
        },
        {
          path: routeFor('api_guide_fetch_transaction'),
          exact: true,
          component: APIGuides.FetchTransaction,
        },
        {
          path: routeFor('api_guide_webhooks'),
          exact: true,
          component: APIGuides.Webhooks,
        },
      ],
    },
    {
      component: IntegrationsPortal,
      path: '/offer/docs',
      routes: [
        {
          path: routeFor('escrow_offer_docs'),
          exact: true,
          component: EscrowOfferDocs.CreateAuction,
        },
        {
          path: routeFor('escrow_offer_docs_fetch_auction'),
          exact: true,
          component: EscrowOfferDocs.FetchAuction,
        },
        {
          path: routeFor('escrow_offer_docs_fetch_auction_token'),
          exact: true,
          component: EscrowOfferDocs.FetchAuctionToken,
        },
        {
          path: routeFor('escrow_offer_docs_cancel_auction'),
          exact: true,
          component: EscrowOfferDocs.CancelAuction,
        },
        {
          path: routeFor('escrow_offer_docs_create_offer'),
          exact: true,
          component: EscrowOfferDocs.CreateOffer,
        },
        {
          path: routeFor('escrow_offer_docs_list_offer'),
          exact: true,
          component: EscrowOfferDocs.ListOffers,
        },
        {
          path: routeFor('escrow_offer_docs_cancel_offer'),
          exact: true,
          component: EscrowOfferDocs.CancelOffer,
        },
        {
          path: routeFor('escrow_offer_docs_offer_action'),
          exact: true,
          component: EscrowOfferDocs.OfferAction,
        },
        {
          path: routeFor('escrow_offer_docs_list_event'),
          exact: true,
          component: EscrowOfferDocs.ListEvents,
        },
      ],
    },
    {
      component: IntegrationsPortal,
      path: '/pay/docs',
      routes: [
        {
          path: routeFor('escrow_pay_docs'),
          exact: true,
          component: EscrowPayDocs,
        },
      ],
    },
    {
      component: IntegrationsPortal,
      path: '/id-verify/docs',
      routes: [
        {
          path: routeFor('escrow_id_verify_docs'),
          exact: true,
          component: EscrowIdVerifyDocs,
        },
      ],
    },
    {
      component: IntegrationsPortal,
      path: '/integration-helper',
      routes: [
        {
          path: routeFor('integration_helper_getting_started'),
          exact: true,
          component: IntegrationHelperGuides.GettingStarted,
        },
        {
          path: routeFor('integration_helper_approve_payments'),
          exact: true,
          component: IntegrationHelperGuides.ApprovePayments,
        },
        {
          path: routeFor('integration_helper_verification'),
          exact: true,
          component: IntegrationHelperGuides.Verification,
        },
      ],
    },
    {
      component: IntegrationsPortal,
      path: '/plugins',
      routes: [
        {
          path: routeFor('plugins_getstarted'),
          exact: true,
          component: Plugins.Plugins,
        },
        {
          path: routeFor('plugins_woocommerce'),
          exact: true,
          component: Plugins.WooCommerce,
        },
      ],
    },
  ];
  const newFlowRoutes = [
    {
      component: CheckoutPage,
      path: routeFor('checkout_page_default'),
      exact: true,
    },
    {
      component: CheckoutPlaidOAuthPage,
      path: routeFor('checkout_plaid_oauth'),
      exact: true,
    },
    {
      component: CheckoutPage,
      path: routeFor('escrow_pay'),
      exact: true,
    },
    {
      component: MakeAnOfferPage,
      path: routeFor('offer'),
      exact: true,
    },
    {
      component: OfferManagementPage,
      path: routeFor('offer_management'),
      exact: true,
    },
    {
      component: KYCPage,
      path: routeFor('verify'),
      exact: false,
    },
    {
      component: PartnerKYCPage,
      path: routeFor('verify_landing'),
      exact: false,
    },
    {
      component: LegalLandingPage,
      path: routeFor('legal_landing'),
      exact: false,
    },
    {
      component: GuitarOffers,
      path: routeFor('guitarOffers'),
      exact: false,
    },
    {
      component: TestAuthPage,
      path: routeFor('auth_page'),
      exact: false,
    },
    {
      component: AuthenticationPage,
      path: routeFor('login_page'),
      exact: false,
    },
    {
      component: AuthenticationPage,
      path: routeFor('signup_page'),
      exact: false,
    },
    {
      component: IntegrationsAuthPage,
      path: routeFor('integrations_login'),
      exact: false,
    },
    {
      component: IntegrationsAuthPage,
      path: routeFor('integrations_signup'),
      exact: false,
    },
  ];
  const creditCardPaymentRoutes = [
    {
      component: CreditCardPaymentPage,
      path: routeFor('credit_card_payment'),
      exact: true,
    },
  ];
  const paymentRoutes = [
    {
      component: PaymentVerificationInstructionsPage,
      path: routeFor('payment_verification_page'),
      exact: true,
    },
    {
      component: PaymentVerificationPage,
      path: routeFor('payment_verification_form_page'),
      exact: true,
    },
    {
      component: PaymentVerificationSuccessPage,
      path: routeFor('payment_verification_success_page'),
      exact: true,
    },
  ];

  const partnerDashboardRoutes = [
    {
      component: PartnerDashboard,
      path: routeFor('partner_dashboard'),
      routes: [
        {
          component: AuthRequired,
          path: routeFor('partner_dashboard'),
          routes: [
            {
              path: routeFor('partner_dashboard'),
              exact: true,
              component: OverviewPage,
            },
            {
              path: routeFor('partner_dashboard_transactions_details'),
              exact: true,
              component: TransactionDetailsPage,
            },
            {
              path: routeFor('partner_dashboard_transactions'),
              exact: true,
              component: TransactionListPage,
            },
            {
              path: routeFor('partner_dashboard_users_details'),
              exact: true,
              component: UserDetailsPage,
            },
            {
              path: routeFor('partner_dashboard_users'),
              exact: true,
              component: UserListPage,
            },
            {
              path: routeFor('partner_dashboard_reports'),
              exact: true,
              component: ReportsListPage,
            },
          ],
        },
      ],
    },
  ];

  const transactionPageRoutes = [
    {
      component: TransactionsPage,
      path: routeFor('transactions_page'),
      exact: true,
    },
    {
      component: TransactionStatusPage,
      exact: true,
      path: routeFor('transaction_status_page'),
    },
    {
      component: PaymentsPage,
      exact: true,
      path: routeFor('transaction_payments_page'),
    },
    {
      component: PaypalSuccessPage,
      exact: true,
      path: routeFor('transaction_payments_paypal_success_page'),
    },
    {
      component: PaymentShortagesPage,
      exact: true,
      path: routeFor('transaction_payment_shortages_page'),
    },
    {
      component: AdyenResultPage,
      exact: true,
      path: routeFor('transaction_payment_adyen_result'),
    },
  ];

  const transactionProgressPageRoutes = [
    {
      component: TransactionProgressPage,
      path: routeFor('transaction_progress_page'),
      exact: true,
    },
  ];

  const passwordResetPageRoutes = [
    {
      component: ForgotPasswordPage,
      path: routeFor('forgot_password_page'),
      exact: true,
    },
    {
      component: ResetPasswordPage,
      path: routeFor('reset_password_page'),
      exact: true,
    },
  ];

  const routes = [
    ...integrationsPortalRoutes,
    ...userPageRoutes,
    ...newFlowRoutes,
    ...creditCardPaymentRoutes,
    ...paymentRoutes,
    ...partnerDashboardRoutes,
    ...transactionPageRoutes,
    ...transactionProgressPageRoutes,
    ...passwordResetPageRoutes,
  ];

  let result = routes;
  for (const prefix of window.config.url_prefixes) {
    if (prefix) {
      const prefixRoutes = addPrefixToRoutes(routes, prefix);
      result = [...result, ...prefixRoutes];
    }
  }

  return result;
};

export default createRoutes;
